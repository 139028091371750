@use '../../sass/abstract/breakpoints';
@use './../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';

.content-gallery--without-filters,
.dynamic-content-gallery--without-filters {
  .content-gallery {
    &__header-container {
      justify-content: center;
    }

    &__title {
      @include gradient.titleGradient;
      text-align: center;
      margin-bottom: variables.$gutter-40 - variables.$gutter-10;
    }

    &__body {
      text-align: center;
      width: 100%;
      margin: 0 auto variables.$gutter-24 auto;
      flex-shrink: 1;
    }

    &__cards-container {
      display: flex;
      column-gap: variables.$gutter-24;
      overflow-x: auto;
      @include mixins.scrollable-container;
      margin-inline: -(variables.$gutter-24);
      padding: 0 variables.$gutter-24;

      @include breakpoints.screen(desktop-and-up) {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: variables.$gutter-40;
        padding: 0;
        margin-inline: 0;

        &--equal {
          padding: 0 variables.$gutter-4;
        }
      }
    }

    &__card-container {
      width: 264px;
      display: inline-block;
    }

    &__link {
      margin-top: variables.$gutter-40;
      display: flex;
      justify-content: flex-start;

      @include breakpoints.screen(tablet-and-up) {
        justify-content: center;
      }
    }
  }
}
