@use '../abstract/breakpoints';
@use '../abstract/z-index';

$spriteWaves: '../../assets/images/spriteLogoLines.svg';

@mixin logoline {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
}

@mixin logoline-position($img-height, $img-width) {
  padding-top: calc($img-height / $img-width * 100%);
}

@mixin front-xs {
  background-image: url($spriteWaves);
  @include logoline-position(109px, 375px);
}
@mixin front-sm {
  background-image: url($spriteWaves);
  @include logoline-position(448px, 1728px);
}
@mixin front-md {
  background-image: url($spriteWaves);
  background-position: 6.4% 30.42%;
  background-size: 558%;
  @include logoline-position(815px, 5120px);
}
@mixin back-xs {
  background-image: url($spriteWaves);
  @include logoline-position(109px, 375px);
}
@mixin back-sm {
  background-image: url($spriteWaves);
  @include logoline-position(448px, 1728px);
}
@mixin back-md {
  background-image: url($spriteWaves);
  background-position: 92% 5%;
  background-size: 569%;
  @include logoline-position(751px, 5120px);
}
@mixin default-2-combined-lines {
  @include logoline-position(448px, 1728px);
  @include breakpoints.screen(tablet-and-up) {
    @include logoline-position(448px, 1728px);
  }
  @include breakpoints.screen(only-large-desktop) {
    @include logoline-position(815px, 5120px);
  }
}
@mixin default-front-xs {
  background-image: url($spriteWaves);
  background-size: 2600%;
  background-position: 39% 29.9%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(only-mobile) {
      background-size: 1347vmax 1472vmax;
      background-position: 39% 29.7%;
      @media (orientation: landscape){
        background-size: 2636vmax 2905vmax;
      }
    }
  }
  @include logoline-position(274px, 375px);
}
@mixin default-front-sm {
  background-image: url($spriteWaves);
  background-size: 2575%;
  background-position: 29.525% 29.426%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 1840vmax 2013vmax;
    @include breakpoints.screen(only-mobile) {
      background-size: 2173vmax 1472vmax;
      background-position: 37.52% 29.426%;
      @media (orientation: landscape){
        background-size: 4170vmax 2826vmax;
      }
    }
    @include breakpoints.screen(desktop-and-up) {
      background-position: 6.4% 30.42%;
    }
  }
  @include logoline-position(109px, 375px);
}
@mixin default-front-md {
  background-image: url($spriteWaves);
  background-position: 6.4% 30.42%;
  background-size: 558%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 435vmax 439vmax;
    background-position: 10.40% 30.42%;
    @include breakpoints.screen(desktop-and-up) {
      background-size: 558vmax 610vmax;
      background-position: 6.4% 30.42%;
    }
    @include breakpoints.screen(only-mobile) {
      background-size: 2173vmax 1472vmax;
      background-position: 37.52% 29.426%;
    }
  }
  @include logoline-position(448px, 1728px);
}
@mixin default-front-lg {
  background-image: url($spriteWaves);
  background-position: 50% 18.04%;
  background-size: 189%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(only-large-desktop) {
      background-size: 222vmax 206vmax;
    }
  }
  @include logoline-position(815px, 5120px);
}
@mixin default-back-xs {
  background-image: url($spriteWaves);
  @include logoline-position(439px, 375px);
}
@mixin default-back-sm {
  background-image: url($spriteWaves);
  background-size: 2630%;
  background-position: 5.5% 16.81%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 1900vmax 2079vmax; // this one for tablet
    background-position: 5.44% 16.8%;
    @include breakpoints.screen(only-mobile) {
      background-size: 1360vmax 1470vmax; // for mobile
      background-position: 6% 16.8%;
      @media (orientation: landscape){
        background-size: 2613vmax 2825vmax;
      }
    }
  }
  @include logoline-position(109px, 375px);
}
@mixin default-back-md {
  background-image: url($spriteWaves);
  background-position: 92% 5%;
  background-size: 569%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 404vmax 441vmax;
    background-position: 91.99% 5%;
    @include breakpoints.screen(desktop-and-up) {
      background-size: 563vmax 615vmax;
    }
    @include breakpoints.screen(only-mobile) {
      background-size: 1338vmax 1486vmax;
      background-position: 5.41% 17.3%;
    }
  }
  @include logoline-position(448px, 1728px);
}
@mixin default-back-lg {
  background-image: url($spriteWaves);
  background-position: 37% 5.5%;
  background-size: 191%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(only-large-desktop) {
      background-size: 191vmax 210vmax;
    }
  }
  @include logoline-position(751px, 5120px);
}

@mixin evp-logoline-2-combined-lines {
  @include logoline-position(109px, 375px);
  @include breakpoints.screen(tablet-and-up) {
    @include logoline-position(160px, 768px);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include logoline-position(448px, 1728px);
  }
  @include breakpoints.screen(only-large-desktop) {
    @include logoline-position(815px, 5120px);
  }
}
@mixin evp-logoline-front-sm {
  background-image: url($spriteWaves);
  background-size: 2570%;
  background-position: 52.7% 50.55%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(only-mobile) {
      background-size: 1344vmax 1464vmax;
      background-position: 52.7% 50.55%;
      @media (orientation: landscape){
        background-size: 2568vmax 2825vmax;
      }
    }
  }
  @include logoline-position(82px, 375px);
}
@mixin evp-logoline-front-md {
  background-image: url($spriteWaves);
  background-size: 1255%;
  background-position: 40.72% 50.63%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 903vmax 994vmax;
    background-position: 40.72% 50.63%;
  }
  @include logoline-position(99px, 768px);
}
@mixin evp-logoline-front-lg {
  background-image: url($spriteWaves);
  background-size: 557%;
  background-position: 17.44% 51.5%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
      background-size: 558vmax 614vmax;
  }
  @include logoline-position(815px, 5120px);
}
@mixin evp-logoline-back-sm {
  background-image: url($spriteWaves);
  background-size: 2550%;
  background-position: 5.4% 50.65%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(only-mobile) {
      background-size: 1323vmax 1451vmax;
      background-position: 5.4% 50.65%;
      @media (orientation: landscape){
        background-size: 2579vmax 2825vmax;
      }
    }
  }
  @include logoline-position(102px, 375px);
}
@mixin evp-logoline-back-md {
  background-image: url($spriteWaves);
  background-size: 1245%;
  background-position: 94.35% 38.65%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 913vmax 1035vmax;
    background-position: 94.3% 39.1%;
  }
  @include logoline-position(139px, 768px);
}
@mixin evp-logoline-back-lg {
  background-image: url($spriteWaves);
  background-size: 560%;
  background-position: 77.5% 39.46%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
      background-size: 561vmax 613vmax;
  }
  @include logoline-position(815px, 5120px);
}
@mixin unplug {
  background-image: url($spriteWaves);
  background-position: 6.37% 94.97%;
  background-size: 558%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 401vmax 439vmax;
    @include breakpoints.screen(desktop-and-up) {
      background-size: 558vmax 614vmax;
    }
    @include breakpoints.screen(only-large-desktop) {
      background-size: 558vmax 614vmax;
    }
    @include breakpoints.screen(only-mobile) {
      background-size: 558%;
      @media (orientation: landscape){
        background-size: 575vmax 630vmax;
      }
    }
  }
  @include logoline-position(556px, 1728px);
}
@mixin heart {
  background-image: url($spriteWaves);
  background-size: 558%;
  background-position: 72.82% 71.99%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 401vmax 439vmax;
    @include breakpoints.screen(desktop-and-up) {
      background-size: 558vmax 614vmax;
    }
    @include breakpoints.screen(only-large-desktop) {
      background-size: 558vmax 614vmax;
    }
    @include breakpoints.screen(only-mobile) {
      background-size: 558%;
      @media (orientation: landscape){
        background-size: 575vmax 630vmax;
      }
    }
  }
  @include logoline-position(556px, 1728px);
}
@mixin divider-sm {
  background-image: url($spriteWaves);
  background-size: 2564%;
  background-position: 86.3% 29.5%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 1382vmax 1474vmax;
    background-position: 85.3% 29.5%;
    @include breakpoints.screen(only-mobile){
      @media (orientation: landscape){
        background-size: 2651vmax 2826vmax;
      }
    }
  }
  @include logoline-position(131px, 375px);
}
@mixin divider-md {
  background-image: url($spriteWaves);
  background-size: 1250%;
  background-position: 75.8% 29.65%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 899vmax 984vmax;
  }
  @include logoline-position(193px, 768px);
}
@mixin divider-lg {
  background-image: url($spriteWaves);
  background-size: 555%;
  background-position: 56.8% 29.9%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(desktop-and-up) {
      background-size: 562vmax 614vmax;
    }
  }
  @include logoline-position(273px, 1728px);
}
@mixin divider-xl {
  background-image: url($spriteWaves);
  background-size: 187.3%;
  background-position: 11% 41.1%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(only-large-desktop) {
      background-size: 190vmax 208vmax;
    }
  }
  @include logoline-position(765px, 5121px);
}
@mixin fat-wave-color-xs {
  background-image: url($spriteWaves);
  background-size: 2566%;
  background-position: 78.11% 58.7%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(only-mobile) {
      background-size: 1395vmax 1500vmax;
      background-position: 77.6% 58.7%;
      @media (orientation: landscape){
        background-size: 2625vmax 2825vmax;
      }
    }
  }
  @include logoline-position(301px, 375px);
}
@mixin fat-wave-color-sm {
  background-image: url($spriteWaves);
  background-size: 558%;
  background-position: 63.25% 60.9%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 401vmax 440vmax;
    background-position: 63.27% 60.9%;
    @include breakpoints.screen(desktop-and-up) {
      background-size: 558vmax 611vmax;
    }
  }
  @include logoline-position(676px, 1728px);
}
@mixin fat-wave-color-md {
  background-image: url($spriteWaves);
  background-size: 242%;
  background-position: 9% 60.55%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    @include breakpoints.screen(only-large-desktop) {
      background-size: 242vmax 265vmax;
    }
  }
  @include logoline-position(613px, 3991px);
}
@mixin fat-wave-white-xs {
  background-image: url($spriteWaves);
  @include logoline-position(531px, 375px);
}
@mixin fat-wave-white-sm {
  background-image: url($spriteWaves);
  background-size: 1250%;
  background-position: 30.83% 72.83%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 895vmax 1020vmax;
    background-position: 30.82% 71.88%;
  }
  @include logoline-position(671px, 768px);
}
@mixin fat-wave-white-md {
  background-image: url($spriteWaves);
  background-size: 558%;
  background-position: 6.38% 72.87%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 558vmax 670vmax;
    background-position: 6.37% 70.7%;
    @include breakpoints.screen(tablet-and-up) {
      @media (orientation: landscape){
        background-size: 555vmax 632vmax;
        background-position: 6.35% 71.92%;

      }
    }
    @include breakpoints.screen(only-large-desktop) {
      background-size: 506vmax 608vmax;
    }
  }
  @include logoline-position(676px, 1728px);
}
@mixin fat-wave-white-top-xs {
  background-image: url($spriteWaves);
  background-size: 2570%;
  background-position: 43.24% 71.8%;
  @supports(-webkit-touch-callout: none) or (-webkit-backdrop-filter: none){
    background-size: 1345vmax 1457vmax;
    background-position: 43.32% 71.86%;
    @include breakpoints.screen(only-mobile) {
      @media (orientation: landscape){
        background-size: 2744vmax 2804vmax;
        background-position: 43.7% 71.3%;

      }
    }
  }
  @include logoline-position(531px, 375px);
}
