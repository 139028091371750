@use '../../sass/abstract/variables';

.text-block {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__title {
    margin-bottom: variables.$gutter-24;
  }

  &__tags-container {
    margin-top: variables.$gutter-24;
    display: flex;
    flex-wrap: wrap;
    column-gap: variables.$gutter-16;
  }

  &__tag {
    &:not(:last-child) {
      margin-bottom: variables.$gutter-8;
    }
  }

  &__link {
    margin-top: variables.$gutter-24;
  }
}
