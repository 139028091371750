@use './colors';

@mixin header-drop-shadow {
  box-shadow: 0 10px 10px -5px rgba(colors.$black, 0.05);
}

@mixin drop-shadow-5 {
  box-shadow: 0 10px 20px 5px rgba(colors.$black, 0.05);
}

@mixin drop-shadow-20 {
  box-shadow: 0 10px 20px 5px rgba(colors.$black, 0.2);
}
