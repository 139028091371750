@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';

.evidence {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
  overflow: visible;

  &__carousel {
    width: 100%;
    max-width: variables.$L-width;
    padding: 0;
    box-shadow: none;
  }
}
