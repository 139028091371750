@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';

.cards--stack-with-scroll {
  .cards--stack{
  &__tiles-container {
    grid-auto-flow: column;
    @include mixins.scrollable-container;
    scrollbar-width: none;
    grid-gap: variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      grid-auto-flow: row;
    }
  }
}
}
