@use '../../../../sass/abstract/border-radius';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/border';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/base/typography';

$spriteImageIcons: '../../../../assets/icons/spriteIcon.svg';

.custom-breadcrumbs {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: variables.$gutter-32;
  }

  &__pill {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: variables.$gutter-8;
    border-radius: border-radius.$border-radius-5;
    color: colors.$gray-80;
    background-color: colors.$gray-light;
    border: border.$border-1-solid-gray-10;
    font-size: typography.$font-size-14;
    font-weight: typography.$font-weight-600;
    line-height: typography.$line-height-16;

    .icon--dismiss {
      &::before {
        background-image: url($spriteImageIcons);
        background-position: -54px -54px;
        background-size: 1600%;
      }
    }
  }

  &__pill-text {
    padding-right: variables.$gutter-8;
    white-space: nowrap;
  }
}
