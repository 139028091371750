@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/z-index';

.container-gray-background {
  .card {
    background-color: colors.$white;
  }
}

.cards--without-title {
  &__container {
    display: flex;
    flex-direction: column;
    gap: variables.$gutter-24;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
  }

  &__cta-container {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }

  &__cards-container {
    display: flex;
    gap: variables.$gutter-24;
    z-index: z-index.$z-index-50;
    margin-inline: -(variables.$gutter-24);
    padding: 0 variables.$gutter-24;


    @include mixins.scrollable-container;
  
    @include breakpoints.screen(desktop-and-up) {
      flex-wrap: wrap;
      justify-content: center;
      margin-inline: 0;
      padding: 0;
    }

  }

  &__single-card-container {
    &:last-child {
      @include breakpoints.screen(desktop-and-up) {
        align-items: center;
      }
    }

    &:empty {
      display: none;
    }
  }
}