@use './../abstract/breakpoints';
@use './../abstract/colors';
@use './../abstract/mixins';
@use '../abstract/variables';

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
@function to-rem($pxValue) {
  @return #{strip-unit($pxValue) / strip-unit(16px)}rem;
}

$ff-primary: 'Segoe UI', 'OpenSans', 'Roboto', 'Arial', 'Tahoma', 'Helvetica', sans-serif;
$ff-secondary: 'Georgia', 'OpenSans', 'Roboto', 'Arial', 'Tahoma', 'Helvetica', sans-serif;

$font-size-12: to-rem(12px);
$font-size-14: to-rem(14px);
$font-size-16: to-rem(16px);
$font-size-18: to-rem(18px);
$font-size-20: to-rem(20px);
$font-size-24: to-rem(24px);
$font-size-28: to-rem(28px);
$font-size-32: to-rem(32px);
$font-size-30: to-rem(30px);
$font-size-36: to-rem(36px);
$font-size-40: to-rem(40px);
$font-size-48: to-rem(48px);
$font-size-52: to-rem(52px);
$font-size-60: to-rem(60px);
$font-size-168: to-rem(168px);
$font-size-264: to-rem(264px);

$line-height-base: 1.5;
$line-height-16: 16px;
$line-height-24: 24px;
$line-height-32: 32px;
$line-height-34: 34px;
$line-height-36: 36px;
$line-height-39: 39px;
$line-height-40: 40px;
$line-height-46: 46px;
$line-height-52: 52px;
$line-height-62: 62px;
$line-height-67: 67px;
$line-height-80: 80px;
$line-height-141: 141px;
$line-height-142: 142px;
$line-height-222: 222px;

$font-weight-400: 400;
$font-weight-600: 600;
$font-weight-700: 700;

html {
  font-family: $ff-primary;
  font-weight: normal;
  color: colors.$gray-80;
  line-height: $line-height-base;
  font-size: $font-size-16;
}

/*** H1 ***/
@mixin h1 {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-36, $line-height-46, $font-weight-700, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-48, $line-height-62, $font-weight-700, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-60, $line-height-80, $font-weight-700, none);
  }
}

@mixin reduced-h1 {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-30, $line-height-39, $font-weight-700, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-40, $line-height-52, $font-weight-700, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-52, $line-height-67, $font-weight-700, none);
  }
}

h1,
.h1 {
  @include h1;

  &.reducedTypo {
    @include reduced-h1;
  }
}

/*** H2 ***/
@mixin h2 {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-28, $line-height-34, $font-weight-700, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-36, $line-height-46, $font-weight-700, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-48, $line-height-62, $font-weight-700, none);
  }
}

h2,
.h2 {
  @include h2;
  padding-bottom: variables.$gutter-10;
  margin-bottom: -(variables.$gutter-10);
}

/*** H3 ***/
@mixin h3 {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-24, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-28, $line-height-36, $font-weight-700, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-36, $line-height-46, $font-weight-700, none);
  }
}

h3,
.h3 {
  @include h3;
}

/*** H4 ***/
@mixin h4 {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-20, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-24, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-28, $line-height-36, $font-weight-700, none);
  }
}

h4,
.h4 {
  @include h4;
}

/*** H4 ***/
@mixin h4-link {
  @include mixins.setFontStyles($font-size-32, normal, $font-weight-400, underline);

  &:hover {
    cursor: pointer;
  }
}

.h4-link,
h4.h4-link {
  @include h4-link;
}

/*** H5 ***/
@mixin h5 {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-18, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-20, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-24, normal, $font-weight-700, none);
  }
}

h5,
.h5 {
  @include h5;
}

/*** H6 ***/
@mixin h6 {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-16, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-18, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-20, normal, $font-weight-700, none);
  }
}

h6,
.h6 {
  @include h6;
}

/*** H6-LINK ***/
@mixin h6-link {
  @include mixins.setFontStyles($font-size-20, normal, $font-weight-400, underline);

  &:hover {
    cursor: pointer;
  }
}

.h6-link,
h6.h6-link {
  @include h6-link;
}

/*** SUBHEADING 1 ***/
@mixin subheading1 {
  @include mixins.setFontStyles($font-size-18, normal, $font-weight-700, none);
}

.subheading1 {
  @include subheading1;
}

/*** SUBHEADING 2 ***/
@mixin subheading2 {
  @include mixins.setFontStyles($font-size-18, normal, $font-weight-400, none);
}

.subheading2 {
  @include subheading2;
}

/***part(placeholder)***/

@mixin partPlaceholder {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-20, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-24, normal, $font-weight-700, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-28, normal, $font-weight-700, none);
  }
}

.partPlaceholder {
  @include partPlaceholder;
}

/*** BODY 1 ***/
@mixin body1 {
  @include mixins.setFontStyles($font-size-16, $line-height-24, $font-weight-700, none);
}

.body1 {
  @include body1;
}

/*** BODY 2 ***/
@mixin body2 {
  @include mixins.setFontStyles($font-size-16, $line-height-24, $font-weight-400, none);
}

.body2 {
  @include body2;
}

/*** BUTTON TAGS ***/
@mixin button-tags {
  @include mixins.setFontStyles($font-size-14, $line-height-16, $font-weight-600, none);
}

.button-tags {
  @include button-tags;
}

/*** BODY 2 LINK ***/
@mixin body2-link {
  @include mixins.setFontStyles($font-size-16, $line-height-24, $font-weight-400, underline);

  &:hover {
    cursor: pointer;
  }
}

.body2-link {
  @include body2-link;
}

/*** BODY 2 ITALIC ***/
@mixin body2-italic {
  @include mixins.setFontStyles($font-size-16, $line-height-24, $font-weight-400, none);
  font-style: italic;
}

.body2-italic {
  @include body2-italic;
}

/*** CAPTION ***/
@mixin caption {
  @include mixins.setFontStyles($font-size-12, normal, $font-weight-400, none);
}

caption,
.caption {
  @include caption;
}

/*** NUMERIC ***/
@mixin numeric {
  @include mixins.setFontStyles($font-size-60, $line-height-40, $font-weight-400, none);
}

.numeric {
  @include numeric;
}

/*** GIANT NUMERIC ***/
@mixin giant-numeric {
  @include breakpoints.screen(only-mobile) {
    @include mixins.setFontStyles($font-size-168, $line-height-141, $font-weight-600, none);
  }
  @include breakpoints.screen(tablet-and-up) {
    @include mixins.setFontStyles($font-size-168, $line-height-142, $font-weight-600, none);
  }
  @include breakpoints.screen(desktop-and-up) {
    @include mixins.setFontStyles($font-size-264, $line-height-222, $font-weight-600, none);
  }
}

.giant-numeric {
  @include giant-numeric;
}

/*** DEFAULT LINK ***/
@mixin default-link {
  color: colors.$gray-80;
  text-decoration: underline;
}

a {
  @include default-link;
}
