@use '../../../sass/abstract/variables';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/z-index';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/abstract/border';
@use '../../../sass/abstract/gradient';

$spriteImageIcons: '../../../assets/icons/spriteIcon.svg';

.search-results {
  margin-top: variables.$gutter-40;
  margin-bottom: variables.$gutter-40;

  &__search-title {
    font-size: typography.$font-size-32;
    font-style: normal;
    font-weight: typography.$font-weight-700;
    margin-bottom: variables.$gutter-40;
  }

  &__search-term {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: border-box;
    text-decoration: none;
  }
}

.custom-results-list {
  margin-top: variables.$gutter-40;
  margin-left: 0 -(variables.$gutter-12);
  width: calc(100% + variables.$gutter-24);

  &__no-result {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: variables.$gutter-40 auto;
  }

  &__no-result-description {
    margin-top: variables.$gutter-24;
    margin-bottom: variables.$gutter-24;
  }

  // &__no-result-btn {
  //   margin-bottom: variables.$gutter-24;
  // }
}

.newsroom-results-list {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: variables.$gutter-40;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: variables.$gutter-40;
  width: calc(264px);

  @include breakpoints.screen(tablet-and-up) {
    grid-template-columns: repeat(2, 1fr);
    width: calc((2 * 264px) + variables.$gutter-32);
    gap: variables.$gutter-32;
  }
  @include breakpoints.screen(desktop-and-up) {
    grid-template-columns: repeat(4, 1fr);
    width: calc((4 * 264px) + variables.$gutter-24);
    gap: variables.$gutter-24;
  }

  .loader-main-container {
    grid-column: 1 / -1;
  }
}

.no_saved_jobs {
  padding: variables.$gutter-40 0;
}

.jobCardContainer {
  display: flex;
  flex-direction: column;
  margin: variables.$gutter-40 0;
  gap: variables.$gutter-40;
  @include breakpoints.screen(tablet-and-up) {
    width: 560px;
    margin: variables.$gutter-40 auto;
    display: grid;
    grid-template-columns: 3fr 3fr;
    align-items: center;
    align-content: center;
    gap: variables.$gutter-32 var(--8-Rule-4, variables.$gutter-32);
  }

  @include breakpoints.screen(desktop-and-up) {
    display: grid;
    overflow-x: hidden;
    overflow-y: hidden;
    grid-template-columns: 3fr 3fr 3fr 3fr;
    scrollbar-width: none;
    width: 1128px;
    align-items: normal;
    align-content: normal;
    gap: variables.$gutter-24;
  }

  .loader-main-container {
    grid-column: 1 / -1;
  }

  .jobCard {
    width: 264px;
    flex: none;
    height: 447px;
    margin-right: auto;
    margin-left: auto;
    float: left;
    max-width: 264px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: variables.$gutter-24 variables.$gutter-32 variables.$gutter-40 variables.$gutter-32;
    position: relative;
    box-sizing: border-box;
    background-clip: padding-box;
    background-color: colors.$gray-light;
    border-radius: border-radius.$border-radius-20 + border-radius.$border-radius-2 +
      border-radius.$border-radius-2;
    color: colors.$gray-80;
    border: 3px solid transparent;
    cursor: pointer;

    @include breakpoints.screen(tablet-and-up) {
      margin-right: 0;
      margin-bottom: 0;
    }

    .jobCardTitle {
      width: 100%;
      max-height: 98px;
      overflow-wrap: break-word;
      margin-bottom: variables.$gutter-24;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      font-weight: typography.$font-weight-700;
      text-overflow: ellipsis;
      white-space: normal;
    }

    .jobCardTag {
      margin-bottom: variables.$gutter-24;
      font-size: 1rem;
      line-height: typography.$line-height-24;
      font-weight: typography.$font-weight-400;
      text-decoration: none;
      width: 100%;
      text-transform: capitalize;
      float: left;

      .jobCardSubTitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: variables.$gutter-24;
        float: left;
        width: calc(100% - 48px);
        font-size: typography.$font-size-16;
        font-weight: typography.$font-weight-400;
        line-height: typography.$line-height-24;
        overflow-wrap: break-word;
      }

      .bookmarkIconSelected {
        background-image: url($spriteImageIcons);
        // background-size: 383px 321px;
        background-position: -372px -21px;
        height: variables.$gutter-24;
        width: variables.$gutter-24;
        margin-left: 90%;
      }

      .bookmarkIconNotSelected {
        background-image: url($spriteImageIcons);
        // background-size: 383px 321px;
        background-position: -328px -21px;
        height: variables.$gutter-24;
        width: variables.$gutter-24;
        margin-left: 90%;
      }
    }

    .jobCardCountry {
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: typography.$font-weight-600;
      line-height: normal;
      text-transform: capitalize;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: variables.$gutter-24;
      overflow-wrap: break-word;
      text-decoration: none;
      white-space: nowrap;
    }

    .jobCardBody {
      float: left;
      text-transform: capitalize;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: variables.$gutter-24;
      max-height: 120px;
      width: 100%;
      -webkit-line-clamp: 5;
    }

    &:hover {
      border: border.$border-3-solid-transparent;

      text-decoration: none;
      @include gradient.border-gradient;

      .jobCardTitle {
        @include gradient.titleGradient;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        white-space: normal;
      }

      .link {
        @include gradient.titleGradient;

        .link__button-tertiary-arrow {
          display: none;
        }

        .link__button-tertiary-arrow-active {
          display: flex;
        }
      }
    }
  }

  .link__button-tertiary {
    float: left;
    white-space: inherit;
    align-items: center;
    max-width: 168px;
    line-height: variables.$gutter-24;
    max-height: unset;
    width: max-content;
  }

  .link__button-tertiary-text {
    line-height: typography.$line-height-24;
    max-height: unset;
    width: max-content;
  }

  .shareIconClass {
    height: variables.$gutter-24;
    width: variables.$gutter-24;
    float: right;
    background-image: url($spriteImageIcons);
    // background-size: 383px 321px;
    // background-position: -153px -125px;
    background-position: -196px -150px;
  }
}

.loadMoreButton {
  padding: variables.$gutter-8 + 4 variables.$gutter-16;
  background: colors.$white;
  border: 1px solid colors.$gray-80;
  color: colors.$gray-80;
  font-size: 0.875rem;
  line-height: typography.$line-height-16;
  height: variables.$gutter-40;
  border-radius: border-radius.$border-radius-28;
  text-decoration: none;
  font-weight: typography.$font-weight-600;
  margin-top: 0;
  margin-bottom: variables.$gutter-40;
}
