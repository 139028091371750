@use '../../../sass/base/typography';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/mixins';
@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/z-index';

.success {
  display: flex;
  flex-direction: column;

  &--modal {
    @include mixins.modal;
    overflow: auto;

    .success__container {
      text-align: center;
      padding: variables.$gutter-40 variables.$gutter-24 variables.$gutter-72;
      margin: 0 auto;
      width: 100%;
      @include breakpoints.screen(tablet-and-up) {
        width: 720px;
        padding-bottom: 100px;
        padding: variables.$gutter-40 variables.$gutter-24 100px;
      }
      @include breakpoints.screen(desktop-and-up) {
        padding: variables.$gutter-80 variables.$gutter-24 variables.$gutter-120;
        width: 930px;
      }
    }
  }

  &--inline {
    margin: 0 auto;
  }

  a {
    text-decoration: underline;
  }

  &__main-container {
    width: 100%;
  }

  &__close-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: variables.$gutter-8 variables.$gutter-48;
    height: 80px;
    gap: variables.$gutter-16;
    font-size: typography.$font-size-16;
    line-height: typography.$line-height-24;
    font-weight: typography.$font-weight-700;
  }

  &__title {
    padding-bottom: variables.$gutter-24;
    @include gradient.titleGradient;
  }

  &__container {
    text-align: center;
    padding: variables.$gutter-80 variables.$gutter-24 70px;
    margin: 0 auto;
    width: 100%;
    @include breakpoints.screen(tablet-and-up) {
      width: 720px;
      padding-bottom: 100px;
      padding-top: variables.$gutter-120;
    }
    @include breakpoints.screen(desktop-and-up) {
      padding: variables.$gutter-160 variables.$gutter-24 variables.$gutter-120;
      width: 930px;
    }
  }

  &__link {
    margin-top: variables.$gutter-24;
    display: flex;
    justify-content: center;
  }

  &__background-container {
    margin-bottom: 150px;
    @include breakpoints.screen(tablet-and-up) {
      margin-bottom: 182px;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: 291px;
    }
  }
}
