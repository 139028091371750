@use '../../sass/abstract/colors';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/shadows';
@use '../../sass/abstract/mixins';

.navigation {
  width: 100%;
  background-color: colors.$white;

  @include breakpoints.screen(desktop-and-up) {
    width: auto;
    margin-right: variables.$gutter-32;
    height: 80px;
    align-items: center;
  }

  &__desktop-content {
    display: none;
    @include breakpoints.screen(desktop-and-up) {
      display: block;
      height: 100%;
    }
  }

  &__mobile-content {
    display: none;
    width: 100%;
    background-color: colors.$white;

    &--open {
      display: block;
      @include breakpoints.screen(desktop-and-up) {
        display: none;
      }
    }
  }

  &__top-lvl-menu-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    @include breakpoints.screen(desktop-and-up) {
      width: auto;
    }
  }

  &__container {
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
}
