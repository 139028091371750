@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/z-index';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/animations';
@use '../../sass/base/typography';
@use '../../sass/abstract/border';
@use '../../sass/abstract/border-radius';


// Mobile assessment stylings start from here
.sd-container-modern {
  margin: 0 0 variables.$gutter-80 0;
  position: relative;

  .sv-components-row {
    display: flex;
    width: 100%;
    padding: variables.$gutter-40 var(--Spacing---GLOBAL--spacing-m, 24px);
    flex-direction: column;
    gap: variables.$gutter-40;
    background: var(--Color-White-100, colors.$white);
    box-shadow: 0 variables.$gutter-10 variables.$gutter-20 5px rgba(0, 0, 0, 0.10);
    justify-content: center;
    align-items: center;

    @include breakpoints.screen(desktop-and-up) {
      padding: variables.$gutter-40;
    }


    .sd-question__erbox--above-question {
        color: colors.$scarlet;
        ;
        margin-bottom: variables.$gutter-16;
    }

    .sv-components-column--expandable {
      width: 100%;
    }

    .sd-question__title {
      font-size: typography.$font-size-20;

      @include breakpoints.screen(tablet-and-up) {
        font-size: typography.$font-size-24;
      }

      @include breakpoints.screen(desktop-and-up) {
        font-size: typography.$font-size-36;
        line-height: typography.$line-height-46;
      }
    }

    .sd-question__required-text,
    .sd-action-bar--empty {
      display: none;
    }

    .sd-body__navigation {
      @include breakpoints.screen(tablet-and-up) {
        display: inline-flex;
        flex-direction: row;
      }
    }

    .sd-navigation__start-btn,
    .sd-navigation__next-btn,
    .sd-navigation__prev-btn,
    .sd-navigation__complete-btn {
      width: 100%;
      height: variables.$gutter-40;
      padding-top: variables.$gutter-12 !important;
      padding-bottom:variables.$gutter-12 !important;
      padding-left:variables.$gutter-24 !important;
      padding-right:variables.$gutter-24 !important;
      font-size: typography.$font-size-14;
      font-style: normal;
      font-weight: typography.$font-weight-600;
      line-height: typography.$line-height-16;
      display: flex;
      align-items: center;
      background: colors.$black;
      border-radius: border-radius.$border-radius-28;
      color: colors.$white !important;
      justify-content: center;
      border: 1px solid;
      cursor: pointer;

      @include breakpoints.screen(tablet-and-up) {
        width: auto;
      }
    }

    .sd-navigation__start-btn:hover,
    .sd-navigation__next-btn:hover,
    .sd-navigation__complete-btn:hover {
      @include breakpoints.screen(desktop-and-up) {
        background: colors.$gray-70;
      }
    }

    .sd-navigation__start-btn:active,
    .sd-navigation__next-btn:active,
    .sd-navigation__complete-btn:active {
      @include breakpoints.screen(desktop-and-up) {
        background: colors.$dark-pink;
        color: colors.$white;
      }
    }

    .sd-navigation__prev-btn {
      border-radius: border-radius.$border-radius-28;
      border: 1px solid var(--Border---GLOBAL--color-border-boldest, colors.$gray-70);
      background: var(--Background---GLOBAL--color-background-inverse, colors.$white);
      color: colors.$black !important;
      margin-bottom: variables.$gutter-24;
      cursor: pointer;
      padding-top:9px !important;
      padding-bottom:9px !important;
      padding-left:variables.$gutter-16 !important;
      padding-right:variables.$gutter-16 !important;


      @include breakpoints.screen(tablet-and-up) {
        margin-bottom: 0 !important;
        margin-right: variables.$gutter-24;
      }
    }

    .sv-title-actions,
    .sd-radio {
      margin-bottom: variables.$gutter-40;
    }

    .sd-navigation__start-btn {
      margin-top: variables.$gutter-40;
      cursor: pointer;
    }

    .sd-navigation__next-btn, .sd-navigation__complete-btn {
      margin-bottom: variables.$gutter-48;
      cursor: pointer;

      @include breakpoints.screen(tablet-and-up) {
        margin-bottom: 0;
      }
    }

    .sd-progress-buttons__page-title {
      position: absolute;
      bottom: 0;
      margin-bottom: variables.$gutter-40;
      text-align: center;
      width: 100%;
      left: -2px;

      @include breakpoints.screen(tablet-and-up) {
        left: inherit;
        right: 0;
        margin: 0 variables.$gutter-40 variables.$gutter-40 0 !important;
        text-align: end;
        width: auto;
        bottom: 8px;
      }
    }

    .sd-html {
      .assessment-img-container {
        min-height: 111px;

        @include breakpoints.screen(tablet-and-up) {
          min-height: 233px;
        }

        @include breakpoints.screen(desktop-and-up) {
          min-height: 365px;
        }

        .assessment-img {
          width: 100%;
          height: 111px !important;
          position: absolute;
          top: 0;
          right: 0;
          object-fit: cover;

          @include breakpoints.screen(tablet-and-up) {
              height: 233px !important;
          }

          @include breakpoints.screen(desktop-and-up) {
              height: 365px !important;
          }
        }
      }
    }

    .sd-selectbase__label {
      display: flex;
      gap: 8px;

      .sd-radio__control {
        height: variables.$gutter-24;
        width: variables.$gutter-24;
        min-width: variables.$gutter-24;
      }
    }

    .sd-radio--checked {
      .sd-selectbase__label {
        input[type="radio"]:checked::before {
          transform: scale(1);
        }

        input[type="radio"]::before {
          content: '';
          width: variables.$gutter-16;
          height: variables.$gutter-16;
          border-radius: 50%;
          transform: scale(0);
          box-shadow: inset variables.$gutter-16 variables.$gutter-16 colors.$black;
        }

        input {
          width: variables.$gutter-24;
          height: variables.$gutter-24;
          min-width: variables.$gutter-24 !important;
          appearance: none;
          background-color: colors.$white;
          border: 1.3px solid colors.$black;
          border-radius: 50%;
          display: grid;
          place-content: center;
        }
      }
    }
  }
}

//marketo form stylings start from here
.survey-marketo {
  .survey-form-container {
    padding: variables.$gutter-40 variables.$gutter-24 !important;
    background: colors.$white;
    box-shadow: 0 variables.$gutter-4 variables.$gutter-20 0 rgba(0, 0, 0, 0.10);

    @include breakpoints.screen(desktop-and-up) {
      padding: variables.$gutter-40 !important;
     }

    div{
      h3{
        margin-bottom: variables.$gutter-40;
        font-size: typography.$font-size-20;
        line-height: normal;
        @include breakpoints.screen(tablet-and-up) {
          font-size: typography.$font-size-24;
        }

        @include breakpoints.screen(desktop-and-up) {
          font-size: typography.$font-size-36;
          line-height: 44px;
        }
      }

      .survey-form-title{
        display: none !important;
      }
    }

    .form {
      form.mktoForm {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: flex-start;

        .mktoFormRow {
          flex-direction: column;
          width: 100%;

          @include breakpoints.screen(tablet-and-up) {
              flex-direction: row;
          }

        }

        .mktoButtonRow {
          width: 100%;

          @include breakpoints.screen(tablet-and-up) {
            width: auto;
          }
        }
      }

      form.mktoForm.hide-survey-marketo {
        display: none !important;
      }
    }
  }

  .hide-onresult-form{
    padding: 0 !important;
    box-shadow: none !important;
    background: none;
  }
}

.survey-result-frame {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  background: colors.$white;
  box-shadow: 0 variables.$gutter-4 variables.$gutter-20 0 rgba(0, 0, 0, 0.10);

  .assessment-result-image {
    .next-image__container {
      img {
        width: 100%;
        height: 111px !important;
        object-fit: cover;

        @include breakpoints.screen(tablet-and-up) {
          height: 233px !important;
        }

        @include breakpoints.screen(desktop-and-up) {
          height: 365px !important;
        }
      }
    }
  }

  .result-assessment-body {
    padding: variables.$gutter-24 variables.$gutter-24 variables.$gutter-40 variables.$gutter-24 !important;
    gap: variables.$gutter-24;
    display: flex;
    flex-direction: column;

    @include breakpoints.screen(tablet-and-up) {
      padding: variables.$gutter-40 variables.$gutter-24 !important;
      gap: 35px;
    }
    @include breakpoints.screen(desktop-and-up) {
      padding: variables.$gutter-40 !important;
    }


    h2 {
      color: colors.$black;
      font-size: typography.$font-size-24;
      font-style: normal;
      font-weight: typography.$font-weight-700;
      line-height: normal;

      @include breakpoints.screen(tablet-and-up) {
        font-size: typography.$font-size-28;
        line-height: typography.$line-height-36;
        margin-bottom: 5px;
      }

      @include breakpoints.screen(desktop-and-up) {
        font-size: typography.$font-size-36;
        line-height: typography.$line-height-46;
      }
    }

    .result-assessment-heading {
      color: colors.$black;
      font-size: typography.$font-size-18;
      font-style: normal;
      font-weight: typography.$font-weight-700;
      line-height: normal;
    }

    .retake-assessement {
      gap: variables.$gutter-10;
      cursor: pointer;

      .icon.icon--arrow-right::before {
        transform: rotate(180deg);
      }
    }

    .result-assessment-footer {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;

      @include breakpoints.screen(tablet-and-up) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
      }

      .result-assessment-register {
        width: 100%;
        min-height: variables.$gutter-40;
        height: auto;
        margin-bottom: variables.$gutter-24;

        @include breakpoints.screen(tablet-and-up) {
            margin-bottom: 0;
            width: auto;
        }
      }

      .retake-assessement.link__button-tertiary {
        .link__button-tertiary-text{
          font-size:typography.$font-size-16;
          line-height: typography.$line-height-24;
        }
      }

      .link__button-tertiary:hover {
        background: linear-gradient(90deg, #333 0%, #333 100%);
        -webkit-background-clip: text;
      }
    }
  }
}
