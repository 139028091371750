@use './../../sass/abstract/gradient';
@use './../../sass/abstract/colors';
@use './../../sass/abstract/variables';

.title-reusable {
  @include gradient.titleGradient;
  text-align: center;
}

.grey-title-reusable {
  color: colors.$gray-80;
}
