@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/z-index';

.slider {
  height: 100%;
  width: 44px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  &--incomplete {
    height: 100%;
    background-color: colors.$gray-20;
    width: 1px;
    margin-left: -3px;
  }

  &--done {
    background-color: colors.$orange;
    width: 5px;
    height: 10%;
    transition: height 2s;
    z-index: z-index.$z-index-40;
  }

  &__step {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__step__dot {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
