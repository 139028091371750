@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/base/typography';

.socialShare {
  display: flex;
  gap: variables.$gutter-16;
  color: colors.$gray-80;

  .Share__title{
    font-weight: 700
  }

  &__label {
    @include typography.body1;
  }

  &__share-icon{
    .socialShare__image{
      img{
        height: variables.$gutter-24;
        width: variables.$gutter-24;
        object-fit: contain;
      }
    }
  }
}
