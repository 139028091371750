@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/breakpoints';

.timage-right-square {
  &__header {
    @include gradient.titleGradient;
    margin-bottom: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: variables.$gutter-24;
    }
  }

  &__subtitle {
    margin-bottom: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: variables.$gutter-24;
    }
  }

  &__image-container {
    margin-bottom: variables.$gutter-24;

    @include breakpoints.screen(tablet-and-up) {
      margin-bottom: 0;
      margin-left: variables.$gutter-12;
    }
  }

  &__link {
    margin-top: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-top: variables.$gutter-24;
    }
  }

  &__text-column {
    @include breakpoints.screen(only-mobile) {
      order: 2;
    }
    @include breakpoints.screen(tablet-and-up) {
      padding-right: variables.$gutter-12;
    }
  }

  &__image-column {
    @include breakpoints.screen(only-mobile) {
      order: 1;
    }
  }
}
