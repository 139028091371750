@use '../abstract/colors';
@use '../abstract/gradient';

@function border($size, $variant, $color) {
  @return $size $variant $color;
}

// gray
$border-1-solid-gray20: border(1px, solid, colors.$gray-20);
$border-0-5-solid-gray30: border(0.5px, solid, colors.$gray-30);
$border-1-solid-gray30: border(1px, solid, colors.$gray-30);
$border-1-dashed-gray30: border(1px, dashed, colors.$gray-30);
$border-1-solid-gray40: border(1px, solid, colors.$gray-40);
$border-2-dashed-gray70: border(2px, dashed, colors.$gray-70);
$border-1-solid-gray80: border(1px, solid, colors.$gray-80);
$border-3-solid-gray80: border(3px, solid, colors.$gray-80);
$border-1-solid-gray-10: border(1px, solid, colors.$gray-10);

// red
$border-1-solid-red: border(1px, solid, colors.$scarlet);

// dark pink
$border-1-solid-dark-pink: border(1px, solid, colors.$dark-pink);

// transparent
$border-2-solid-transparent: border(2px, solid, transparent);
$border-3-solid-transparent: border(3px, solid, transparent);
$border-4-solid-transparent: border(4px, solid, transparent);
$border-8-solid-transparent: border(8px, solid, transparent);

@mixin aurora-border {
  background-clip: padding-box;
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    @include gradient.horizontal-aurora-background;
  }
}

@mixin aurora-search-dropdown-border {
  background-clip: padding-box;
  position: relative;
  box-sizing: border-box;
  border: 1px solid transparent;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    @include gradient.horizontal-aurora-background-border;
    background-origin: border-box;
    background-clip: content-box, border-box;
    border: double 2px transparent;
  }
}

@mixin aurora-search-dropdown-list-border {
  border-radius: inherit;
  @include gradient.horizontal-aurora-background-border;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: double 2px transparent;
}
