@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/shadows';
@use '../../../sass/base/typography';
@use '../../../sass/base/icons';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/z-index';
@use '../../../sass/abstract/border-radius';

$spriteImageIcons: '../../../assets/icons/spriteIcon.svg';

@mixin atomic-search-box--primary {
  atomic-search-box {
    &::part(wrapper) {
      border: 0;
      flex-direction: row-reverse;
    }

    &::part(suggestions-wrapper) {
      border: 0;
      display: flex;
      flex-direction: column;
      min-height: 344px;
      margin-bottom: variables.$gutter-32;

      &::before {
        content: 'Suggested Results';
        border: 0;
        font-size: typography.$font-size-16;
        font-style: normal;
        font-weight: typography.$font-weight-400;
        line-height: typography.$line-height-24;
        margin-top: variables.$gutter-56;
        margin-bottom: variables.$gutter-24;
      }
    }

    &::part(suggestion) {
      display: flex;
      margin-bottom: variables.$gutter-8;
      padding: variables.$gutter-8 variables.$gutter-16;

      &:hover {
        background-color: colors.$gray-light;
        border-radius: border-radius.$border-radius-20;
        cursor: pointer;
      }
    }

    &::part(input) {
      padding: 0;
      min-height: 27px;
      font-style: normal;
      font-weight: typography.$font-weight-700;
      line-height: normal;
      font-size: typography.$font-size-20;

      @include breakpoints.screen(tablet-and-up) {
        font-size: typography.$font-size-24;
        min-height: 32px;
      }

      @include breakpoints.screen(desktop-and-up) {
        font-size: typography.$font-size-28;
        min-height: 36px;
        line-height: typography.$line-height-36;
      }
    }

    &::part(clear-button) {
      border: 0;
      @include icons.icon-size-24;
      margin: 0 0 0 variables.$gutter-10;
    }

    &::part(clear-icon) {
      @include icons.icon-size-24;
      margin: 0;
    }

    &::part(submit-icon) {
      @include breakpoints.screen(desktop-and-up) {
        @include icons.icon-size-32;
      }

      @include icons.icon-size-24;
    }

    &::part(submit-button) {
      order: 0;

      @include breakpoints.screen(desktop-and-up) {
        @include icons.icon-size-32;
      }

      @include icons.icon-size-24;
      display: flex;
      align-self: center;
      margin-right: variables.$gutter-16;
    }

    &::part(query-suggestion-icon) {
      @include icons.icon-size-24;
      margin-right: 0 variables.$gutter-16 0 0;
    }
  }

  atomic-search-box-query-suggestions {
    &::before {
      content: 'Suggested Results';
    }

    &::part(query-suggestion-text) {
      font-size: typography.$font-size-18;
      font-style: normal;
      font-weight: typography.$font-weight-700;
      line-height: normal;
    }
  }
}

@mixin atomic-search-box--secondary {
  atomic-search-box {
    width: 100%;

    &::part(wrapper) {
      border: 0;
      flex-direction: row-reverse;
      box-shadow: none;
    }

    &::part(suggestions-wrapper) {
      border: 0;
      background-color: colors.$white;
      flex-direction: column;
      min-height: 344px;
      margin-top: 54px;
      padding: variables.$gutter-16;
      border-radius: border-radius.$border-radius-30;
      @include shadows.drop-shadow-5;

      &::before {
        content: 'Suggested Results';
        border: 0;
        @include typography.subheading1;
        margin-bottom: variables.$gutter-8;
        padding: variables.$gutter-8 variables.$gutter-8 variables.$gutter-8 variables.$gutter-16;
      }
    }

    &::part(suggestion) {
      display: flex;
      margin-bottom: variables.$gutter-8;
      padding: variables.$gutter-8 variables.$gutter-16;

      &:hover {
        background-color: colors.$gray-light;
        border-radius: border-radius.$border-radius-20;
        cursor: pointer;
      }
    }

    &::part(input) {
      width: 100%;
      font-style: normal;
      font-weight: typography.$font-weight-700;
      padding: 0;
      min-height: 27px;
      line-height: normal;
      font-size: typography.$font-size-20;
      text-overflow: ellipsis;

      @include breakpoints.screen(tablet-and-up) {
        font-size: typography.$font-size-24;
        min-height: 32px;
      }

      @include breakpoints.screen(desktop-and-up) {
        font-size: typography.$font-size-28;
        min-height: 36px;
        line-height: typography.$line-height-36;
      }
    }

    &::part(clear-button) {
      border: 0;
      width: variables.$gutter-24;
      height: variables.$gutter-24;
      margin: 0 0 0 variables.$gutter-10;
    }

    &::part(clear-icon) {
      @include breakpoints.screen(desktop-and-up) {
        @include icons.icon-size-32;
      }

      @include icons.icon-size-24;
      margin: 0;
    }

    &::part(submit-icon) {
      width: variables.$gutter-32;
      height: variables.$gutter-32;
    }

    &::part(submit-button) {
      order: 0;

      @include breakpoints.screen(desktop-and-up) {
        @include icons.icon-size-32;
      }

      @include icons.icon-size-24;
      display: flex;
      align-self: center;
      margin-right: variables.$gutter-16;
    }

    &::part(query-suggestion-icon) {
      width: variables.$gutter-24;
      height: variables.$gutter-24;
      margin-right: 0 variables.$gutter-16 0 0;
    }
  }

  atomic-search-box-query-suggestions {
    &::before {
      content: 'Suggested Results';
      @include typography.subheading1;
    }

    &::part(query-suggestion-text) {
      font-size: typography.$font-size-18;
      font-style: normal;
      font-weight: typography.$font-weight-700;
      line-height: normal;
    }
  }
}

@mixin atomic-search-box--tertiary {
  atomic-search-box {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 4px;
      margin-top: variables.$gutter-16;
      @include gradient.horizontal-aurora-background;
    }

    &::part(wrapper) {
      border: 0;
      flex-direction: row-reverse;
      --tw-ring-shadow: unset;
    }

    &::part(suggestions-wrapper) {
      flex-direction: column;
      background-color: colors.$white;
      border: 0;
      @include shadows.drop-shadow-5;
      padding: variables.$gutter-24;
      width: calc(100% + 2px);
      margin-left: -1px;
      z-index: z-index.$z-index-30;
    }

    &::part(suggestion) {
      display: flex;
      margin-bottom: variables.$gutter-8;
      padding: variables.$gutter-8 variables.$gutter-16;
    }

    &::part(query-suggestion-text) {
      @include typography.subheading1;
      display: inline-block;
      vertical-align: middle;
      padding-left: variables.$gutter-32;
      position: relative;

      &::before {
        content: '';
        @include icons.background-icon('search');
        @include icons.icon-size-24;
        display: inline-block;
        vertical-align: middle;
        margin-right: variables.$gutter-8;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background-position: 33% 54%;
      }
    }

    &::part(input) {
      @include typography.subheading1;

      &::placeholder {
        @include typography.subheading2;
        color: colors.$gray-60;
      }
    }

    &::part(clear-button) {
      border: 0;
      margin: 0 0 0 variables.$gutter-10;
      display: flex;
      align-items: center;
      justify-content: center;
      @include icons.icon-size-24;

      &::before {
        content: '';
        @include icons.background-icon('dismiss');
        background-size: 1600%;
        background-position: -82px -82px;
        @include icons.icon-size-24;
        display: flex;
      }
    }

    &::part(clear-icon) {
      display: none;
    }

    &::part(submit-icon) {
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoints.screen(desktop-and-up) {
        @include icons.icon-size-32;
      }

      @include icons.icon-size-24;

      &::before {
        content: '';
        @include icons.background-icon('search');
        background-size: 600px 495px;
        background-position: 33% 54%;

        @include breakpoints.screen(desktop-and-up) {
          @include icons.icon-size-32;
        }

        @include icons.icon-size-24;
        display: flex;
      }
    }

    &::part(submit-button) {
      order: 0;

      @include breakpoints.screen(desktop-and-up) {
        @include icons.icon-size-32;
      }

      @include icons.icon-size-24;
      display: flex;
      align-self: center;
    }
  }
}

atomic-layout-section {
  h5 {
    margin: variables.$gutter-16 0;
    display: inline-block;
    font-size: typography.$font-size-18;

    @include breakpoints.screen(tablet-and-up) {
      font-size: typography.$font-size-20;
    }

    @include breakpoints.screen(desktop-and-up) {
      margin-top: variables.$gutter-24;
    }
  }

  .event__featured {
    height: variables.$gutter-32;
    position: absolute;
    margin: variables.$gutter-16 0 variables.$gutter-16 variables.$gutter-24;
  }

  .sparkle_title {
    margin-left: variables.$gutter-10;
  }
}

:root {
  --atomic-success: #12a244;
  /* Define the custom property */
  --atomic-error: #ce3f00;
}

atomic-smart-snippet {
  &::part(smart-snippet) {
    padding-top: 65px;
    padding-bottom: variables.$gutter-24;
    border-bottom: 0;
    border-top-left-radius: border-radius.$border-radius-30;
    border-top-right-radius: border-radius.$border-radius-28;
    border: 1px solid #c4cfde;

    @include breakpoints.screen(tablet-and-up) {
      padding-bottom: variables.$gutter-16;
    }
  }

  &::part(question) {
    font-size: typography.$font-size-18;
    font-weight: typography.$font-weight-700;
    line-height: 26px;
    letter-spacing: 0;

    @include breakpoints.screen(tablet-and-up) {
      font-size: typography.$font-size-20;
      line-height: typography.$line-height-32;
    }

    @include breakpoints.screen(desktop-and-up) {
      font-size: typography.$font-size-24;
    }
  }

  &::part(show-more-button) {
    margin: variables.$gutter-16 0;
    position: relative;
    text-decoration: none;
  }

  &::part(show-less-button) {
    text-decoration-line: none;
  }

  &::part(source-url) {
    display: none;
  }

  &::part(feedback-inquiry-and-buttons) {
    gap: variables.$gutter-8;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  &::part(feedback-buttons) {
    height: variables.$gutter-24 + variables.$gutter-4;
    display: flex;
    justify-content: flex-end;
    padding-right: 5px !important;
    gap: 0 !important;
  }

  .text-error &::part(feedback-dislike-button) {
    display: none;
  }

  &::part(feedback-dislike-button),
  &::part(feedback-like-button) {
    font-weight: typography.$font-weight-700;
    line-height: normal;
    letter-spacing: 0;
    column-gap: variables.$gutter-8;
    width: variables.$gutter-56 + variables.$gutter-4 !important;
    justify-content: flex-end;
  }

  &::part(feedback-inquiry) {
    line-height: variables.$gutter-24;
  }

  &::part(footer)::before {
    margin-bottom: 0;
  }

  &::part(feedback-thank-you-wrapper) {
    line-height: variables.$gutter-24;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    flex-direction: column;
  }

  &::part(feedback-explain-why-button) {
    display: none;
  }

  &::part(feedback-banner) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &::part(source-title) {
    margin-bottom: variables.$gutter-16;
  }
}

atomic-smart-snippet-suggestions {
  &::part(container) {
    border-top: 0 !important;
    border-bottom-left-radius: border-radius.$border-radius-28;
    border-bottom-right-radius: border-radius.$border-radius-28;
    border: 1px solid #c4cfde;
    padding: variables.$gutter-16 0;
    margin-bottom: variables.$gutter-56;

    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: variables.$gutter-64;
    }
  }

  &::part(heading) {
    padding: variables.$gutter-8 variables.$gutter-24;
    padding-left: 0;
    font-weight: typography.$font-weight-700;
    font-size: typography.$font-size-18;
    line-height: normal;
    border-bottom: 0;
    margin: 0 variables.$gutter-24;

    @include breakpoints.screen(tablet-and-up) {
      font-size: typography.$font-size-20;
    }

    @include breakpoints.screen(desktop-and-up) {
      line-height: variables.$gutter-16 + variables.$gutter-10;
    }
  }

  &::part(headingquestion-text-collapsed) {
    padding: variables.$gutter-8 variables.$gutter-24;
    line-height: variables.$gutter-24;
  }

  &::part(question-text-expanded),
  &::part(question-text-collapsed) {
    border: 0;
    padding: 0;
    width: 100%;
    margin-right: variables.$gutter-40;
    font-weight: typography.$font-weight-700;
  }

  &::part(question-button-collapsed),
  &::part(question-button-expanded) {
    padding: variables.$gutter-8 0;
  }

  &::part(question-text-expanded),
  &::part(question-text-collapsed),
  &::part(question-button-collapsed),
  &::part(question-button-expanded) {
    position: relative;
  }

  &::part(questions)::before,
  &::part(questions)::after {
    border: 0 !important;
    border-style: none !important;
  }

  &::part(questions) {
    border: 0 !important;
    border-style: 0 !important;
    border-width: 0;
    margin: 0 variables.$gutter-24;
  }

  &::part(questions) > li {
    border-bottom: 0;
  }

  &::part(question-icon-collapsed),
  &::part(question-icon-expanded) {
    position: absolute;
    display: flex;
    left: 89%;
    height: variables.$gutter-24;
    width: variables.$gutter-24;

    @include breakpoints.screen(tablet-and-up) {
      left: 96.5%;
    }

    @include breakpoints.screen(desktop-and-up) {
      left: 97%;
    }
  }

  &::part(question-icon-collapsed) {
    transform: rotate(90deg);
  }

  &::part(question-icon-expanded) {
    transform: rotate(180deg);
  }

  &::part(answer-and-source) {
    border: 0;
    border-width: 0;
    margin: 0;
    padding: 0;
  }

  &::part(question-answer-collapsed),
  &::part(question-answer-expanded) {
    border: 0;
    border-top: 1px solid colors.$gray-20;
    width: 100%;
  }

  &::part(source-url) {
    display: none;
  }

  &::part(source-title) {
    margin-bottom: variables.$gutter-8;
  }

  &::part(footer)::before {
    margin-bottom: 0;
  }
}

atomic-query-summary {
  &::part(container) {
    font-size: typography.$font-size-18;
    font-weight: typography.$font-weight-700;
    line-height: typography.$line-height-32;
    margin: 0 0 variables.$gutter-40 0;

    @include breakpoints.screen(tablet-and-up) {
      font-size: typography.$font-size-20;
    }

    @include breakpoints.screen(desktop-and-up) {
      font-size: typography.$font-size-24;
      margin: 0 0 variables.$gutter-40 0;
    }
  }
}

atomic-result-list {
  &::part(outline) {
    padding: 0;

    &::before {
      margin: 0;
    }
  }

  &::part(result-list) {
    grid-row-gap: variables.$gutter-24;
  }
}

@mixin atomic-search-box--search-job {
  atomic-search-box {
    position: relative;

    &::part(input) {
      text-overflow: ellipsis;
      white-space: nowrap;

      &::placeholder {
        @include typography.partPlaceholder;
        color: colors.$black;
      }
    }
  }
}
