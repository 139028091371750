@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/border';
@use '../../../../sass/abstract/breakpoints';

.custom-query-summary {
  margin-top: variables.$gutter-24;
  margin-bottom: variables.$gutter-24;
  display: flex;
  gap: variables.$gutter-24;
  position: relative;

  @include breakpoints.screen(only-mobile) {
    margin-top: variables.$gutter-40;
    margin-bottom: 0;
  }

  &--hide {
    display: none;
  }
}
