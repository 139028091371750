@use '../../sass/base/typography';

.article-by{
    line-height: typography.$line-height-24;
    font-weight: typography.$font-weight-400;
  
    &__title {
      font-size: typography.$font-size-16;
    }
  
    &__list {
      font-weight: typography.$font-weight-700;
    }

    &__name {
      font-weight: typography.$font-weight-700;
    }
  }