@use '../../../../sass/abstract/colors';
@use '../../../../sass/base/waves';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/z-index';
@use '../../../../sass/abstract/blur';
@use '../../../../sass/abstract/border-radius';
@use '../../../../components/shared/nextImg/NextImageWithNullFallback.scss';

@mixin video-mobile-size {
  @include breakpoints.screen(only-mobile) {
    max-width: 327px;
  }
}

@mixin video-tablet-size {
  @include breakpoints.screen(tablet-and-up) {
    max-width: 560px;
  }
}

@mixin video-desktop-size {
  @include breakpoints.screen(desktop-and-up) {
    max-width: 100%;
  }
}

.homepage-hero-video {
  position: relative;
  background: colors.$white;
  padding: variables.$gutter-40 0 0;

  @include breakpoints.screen(desktop-and-up) {
    padding: variables.$gutter-80 0 134px;
    margin-bottom: variables.$gutter-40;
  }

  &__link {
    width: 100%;
    @include breakpoints.screen(tablet-and-up) {
      width: auto;
    }
  }

  &__content {
    margin: 0 auto;
    position: relative;
    z-index: z-index.$z-index-30;
  }

  &__blurBG {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include blur.blur-back-10;
    z-index: z-index.$z-index-10;

    @include breakpoints.screen(desktop-and-up) {
      @include blur.blur-back-20;
    }
  }

  &__title {
    text-align: center;
    max-width: 100%;
    margin-bottom: variables.$gutter-24;
    @include gradient.titleGradient;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
    }
  }

  &__tag {
    text-align: center;
    max-width: 100%;
    margin-bottom: variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
    }
  }

  &__paragraph {
    text-align: center;
    width: 100%;
    margin-bottom: variables.$gutter-24;
    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto variables.$gutter-24;
    align-items: center;

    @include breakpoints.screen(desktop-and-up) {
      padding-right: variables.$gutter-24;
      margin: 0;
      align-items: flex-start;
    }
  }

  &__video-container {
    margin: 0 auto;
    overflow: hidden;
    border-radius: border-radius.$border-radius-30;
    min-height: 327px;
    @include breakpoints.screen(tablet-and-up) {
      min-height: 472px;
    }

    @include breakpoints.screen(desktop-and-up) {
      min-height: 648px;
    }

    .video-js {
      border-radius: border-radius.$border-radius-30;
      overflow: hidden;

      video {
        border-radius: border-radius.$border-radius-30;
        overflow: hidden;
      }
    }
  }
}
