@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/border';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/variables';

.insight-card,
.job-card {
  width: 264px;
  max-width: 264px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: variables.$gutter-24;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  color: colors.$gray-80;
  border: border.$border-3-solid-transparent;

  &:hover {
    cursor: pointer;
  }

  &__default-container {
    &:hover {
      border: border.$border-3-solid-transparent;

      text-decoration: none;
      @include gradient.border-gradient;

      .insight-card__title {
        @include gradient.titleGradient;
      }

      .link {
        @include gradient.titleGradient;

        .link__button-tertiary-arrow {
          display: none;
        }

        .link__button-tertiary-arrow-active {
          display: flex;
        }
      }
    }
  }

  &__tag {
    margin-bottom: variables.$gutter-20;
  }

  &__title {
    margin-bottom: variables.$gutter-20;
  }

  &__icon {
    max-width: 24px;
    float: left;
    margin-right: variables.$gutter-10;
  }

  &__body {
    margin-bottom: variables.$gutter-20;

    &:hover {
      text-decoration: none;
    }
  }
}
