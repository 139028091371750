@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/variables';

.headquarters-header {
  padding-top: variables.$gutter-40;
  border-top: border.$border-1-solid-gray20;

  &.col-12 {
    margin-top: variables.$gutter-80;

    @include breakpoints.screen(tablet-and-up) {
      margin-top: variables.$gutter-40;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin-top: variables.$gutter-120;
    }
  }

  div {
    h4 {
      margin-bottom: 0;
      text-align: left;
    }
  }
}

.headquarters {
  &__item {
    margin-bottom: variables.$gutter-40;

    &:last-child {
      margin-bottom: 0;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: 0;
    }
  }

  &__item-address {
    margin-top: variables.$gutter-16;
  }

  &__item-phone {
    display: flex;
    align-items: center;
    margin-top: variables.$gutter-16;
  }

  &__item-fax {
    margin-top: variables.$gutter-8;
    display: flex;
    align-items: center;
  }
}
