@use '../../sass/abstract/colors.scss';
@use '../../sass/abstract/variables.scss';

.pages-editor-hint-hider {
  display: flex;
  column-gap: variables.$gutter-24;
  margin: variables.$gutter-40 0 variables.$gutter-24;

  button {
    border: 1px solid colors.$dark-pink;
    border-radius: 7px;
    background-color: colors.$pink;
    transition: background-color 0.2s;
    padding: variables.$gutter-8 variables.$gutter-16;
    box-shadow: colors.$gray-30 2px 2px 3px;
    color: colors.$white;
    font-weight: bold;

    &:hover {
      background-color: colors.$dark-pink;
    }
  }
}