@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/z-index';
@use '../../../../sass/abstract/mixins';
@use '../../../../sass/abstract/animations';
@use '../../../../sass/abstract/shadows';
@use '../../../../sass/base/typography';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/border-radius';

.animated-carousel {
  max-width: 1728px;
  width: 100%;
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: static;

  &--edit-active {
    display: block;
  }

  &--edit-inactive {
    display: none;
  }

  @include breakpoints.screen(desktop-and-up) {
    min-height: 740px;
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
    padding: 54px variables.$gutter-80;
    position: relative;
  }

  &__background {
    &--edit {
      z-index: z-index.$z-index-0;
    }
  }

  &__background-image-container {
    @include breakpoints.screen(desktop-and-up) {
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__background-image {
    width: 100vw;
    position: relative;
    z-index: z-index.$z-index-minus-70;

    @include breakpoints.screen(only-mobile) {
      height: auto;
    }
    @include breakpoints.screen(tablet-and-up) {
      min-height: 328px;
    }
    @include breakpoints.screen(desktop-and-up) {
      min-height: 740px;
      width: 100%;
      max-width: 1728px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    img{
      position: relative !important;
    }
  }

  &__background-image-preload {
    display: none;
  }

  &__big-card {
    z-index: z-index.$z-index-10;
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 19rem;
    width: 100vw;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: colors.$white;
    @include shadows.drop-shadow-5;
    margin-right: auto;
    padding: variables.$gutter-24 variables.$gutter-24 0;


    @include breakpoints.screen(desktop-and-up) {
      width: 552px;
      padding: variables.$gutter-40;
    }
  }

  &__big-card-viewport {
    overflow: hidden;
    height: auto;
    max-width: 100%;

    @include breakpoints.screen(desktop-and-up) {
      height: 100%;
      max-width: 768px;
    }
  }

  &__big-card-container {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &__big-card-main-box {
    height: 100%;
  }

  &__big-card-tertiary-btn {
    margin-top: variables.$gutter-24;
  }

  &__big-card-tag {
       margin-bottom: variables.$gutter-24;
  }

  &__big-card-logo-container {
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    max-width: 202px;
    width: 202px;
    min-width: 202px;
    margin-bottom: variables.$gutter-24;
  }

  &__big-card-text {
    margin-top: variables.$gutter-24;
  }

  &__pagination {
    margin-top: variables.$gutter-24;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: variables.$gutter-24;
    @include breakpoints.screen(desktop-and-up) {
      justify-content: flex-start;
      margin-bottom: 0;
    }
  }

  &__pagination-button {
    cursor: pointer;
    text-decoration: none;
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.3;
    }

    &--prev {
      margin-right: 1rem;
    }

    &--next {
      margin-left: 1rem;
    }
  }

  &__pagination-dot {
    cursor: pointer;
    text-decoration: none;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.5rem;
    height: 2rem;
    display: flex;
    align-items: center;
    margin-right: 0.25rem;
    margin-left: 0.25rem;

    &::after {
      content: '';
      background: colors.$gray-20;
      border-radius: border-radius.$border-radius-2;
      width: 100%;
      height: 0.25rem;
    }

    &--selected {
      &::after {
        background: colors.$orange;
      }
    }
  }

  &__small-card {
    max-width: 350px;
    align-self: flex-end;
    margin-left: auto;
    background-color: colors.$white;
    opacity: 0.9;
    display: none;

    @include breakpoints.screen(desktop-and-up) {
      display: block;
    }
  }

  &__small-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include shadows.drop-shadow-5;
    max-width: 768px;
    height: 100%;

    @include animations.animate-height-hidden;
    @include breakpoints.screen(desktop-and-up) {
      max-width: 552px;
    }
  }

  &__small-card-title {
    padding: variables.$gutter-40 variables.$gutter-40 variables.$gutter-24;
    font-size: typography.to-rem(14px);
    line-height: 16px;
    font-weight: typography.$font-weight-600;
  }

  &__small-card-text {
    padding: 0 variables.$gutter-40 variables.$gutter-40;
  }
}
