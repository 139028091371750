@use '../../sass/abstract/colors';
@use '../../sass/abstract/border';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/z-index';
@use '../../sass/abstract/border-radius';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/breakpoints';

.events-cardsbytag {
    &__container {
        display: flex;
        margin: 0 auto;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__title {
        text-align: center;
        margin-bottom: variables.$gutter-40;
        flex-basis: 100%;
    }

    &__cta-container {
        margin-top: variables.$gutter-40;
        display: flex;
        justify-content: center;
        flex-basis: 100%;
    }

    &__cards-container {
        display: grid;
        gap: variables.$gutter-24;
        z-index: z-index.$z-index-50;
        grid-auto-flow: column;
        grid-auto-columns: auto;
        margin-inline: -(variables.$gutter-24);
        padding: 0 variables.$gutter-24;

        @include mixins.scrollable-container;
        scrollbar-width: none;

        @include breakpoints.screen(only-mobile) {
            grid-auto-flow: row;
            touch-action: pan-y;
        }
        @include breakpoints.screen(desktop-and-up) {
            margin-inline: 0;
            padding: 0;
          }
    }
}