.top-five {
  &--hide {
    display: none;
  }

  .right-container {
    .top-five-card:last-child {
      .top-five-card__container {
        margin-bottom: 0;
      }
    }
  }
}