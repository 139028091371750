@use '../../../../sass/abstract/colors';

.full-bleed-banner-gray-title{
  .full-bleed-banner-with-video {
    background: colors.$gray-light;
  }

  .full-bleed-banner-with-video__title
  .full-bleed-banner-with-video__tag {
    color: colors.$gray-80;
    -webkit-text-fill-color: colors.$gray-80;
  }
}