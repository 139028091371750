@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/breakpoints';

.breadcrumbs {
  width: 100%;
  overflow: hidden;

  &__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;

    @include breakpoints.screen(only-mobile) {
      display: none;

      &:first-child,
      &:last-child {
        display: flex;
      }
    }

    &:last-child {
      .breadcrumbs__link {
        @include mixins.disable-link-click;
      }

      .breadcrumbs__arrow {
        display: none;
      }
    }
  }

  &__link {
    color: colors.$gray-80;
    text-decoration: none;
  }

  &__arrow {
    margin: 0 variables.$gutter-8 -3px;
  }
}
