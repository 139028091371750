@use '../../../src/sass/abstract/shadows';
@use '../../../src/sass/abstract/colors';
@use '../../../src/sass/abstract/border';
@use '../../../src/sass/abstract/blur';
@use '../../../src/sass/abstract/variables';
@use '../../../src/sass/base/icons';
@use '../../../src/sass/abstract/breakpoints';
@use '../../../src/sass/abstract/z-index';
@use '../../../src/sass/abstract/border-radius';

$menu-max-height: 591px;

.site-selector {
  padding: variables.$gutter-48 0 variables.$gutter-8;
  width: 100%;

  @include breakpoints.screen(desktop-and-up) {
    padding: 0;
    width: auto;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 variables.$gutter-16;
    @include breakpoints.screen(desktop-and-up) {
      padding: 0;
      width: auto;
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
  }

  &__button-icon {
    display: block;
    @include breakpoints.screen(desktop-and-up) {
      display: none;
    }
  }

  &__current {
    color: colors.$gray-80;
    @include breakpoints.screen(desktop-and-up) {
      display: none;
    }
  }

  &__icon {
    margin-right: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-right: variables.$gutter-32;
    }

    &:hover {
      cursor: pointer;
    }

    &--mobile {
      display: block;
      @include breakpoints.screen(desktop-and-up) {
        display: none;
      }
    }

    &--desktop {
      display: none;
      @include breakpoints.screen(desktop-and-up) {
        display: block;
      }
    }
  }

  &__list-container {
    @include breakpoints.screen(desktop-and-up) {
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      top: 100%;
      width: 100vw;
      height: auto;
      background-color: colors.$white;
      @include shadows.header-drop-shadow;
    }
  }

  &__container {
    padding: variables.$gutter-8 0 0 0;
    margin: 0 auto;

    @include breakpoints.screen(desktop-and-up) {
      max-width: variables.$L-width;
      max-height: $menu-max-height;
      padding: variables.$gutter-24 0 variables.$gutter-80 0;
    }
  }

  &__list {
    width: 100%;
    column-count: 1;

    @include breakpoints.screen(desktop-and-up) {
      column-count: 3;
      column-fill: auto;
      height: 420px;
    }
  }

  &__list-item {
    margin-bottom: variables.$gutter-8;

    &:last-child {
      margin-bottom: 0;
    }

    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: 0;
    }
  }

  &__link {
    color: colors.$gray-80;
    padding: variables.$gutter-8 variables.$gutter-16;
    display: inline-flex;
    text-decoration: none;
    width: 100%;

    @include breakpoints.screen(desktop-and-up) {
      width: auto;
    }

    &:hover {
      background-color: colors.$gray-light;
      border-radius: border-radius.$border-radius-20;
    }

    &--active {
      @include breakpoints.screen(desktop-and-up) {
        border-radius: border-radius.$border-radius-20;

        border: border.$border-1-solid-gray40;
      }
    }
  }

  &__background {
    @include breakpoints.screen(desktop-and-up) {
      z-index: z-index.$z-index-minus-10;
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      @include blur.blur-back-10;
    }
  }
}
