@use '../../sass/abstract/colors';
@use '../../sass/abstract/border-radius';
@use '../../sass/base/typography';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';

.tag {
  background-color: colors.$gray-10;
  border-radius: border-radius.$border-radius-5;
  padding: variables.$gutter-8;
  max-width: 232px;
  @include mixins.disable-link-click;
  font-size: typography.$font-size-14;
  line-height: typography.$line-height-16;
  font-weight: typography.$font-weight-600;

  &--with-icon {
    background-color: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    column-gap: variables.$gutter-10;
  }

  &__content {
    color: colors.$gray-80;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}

.tag-edit {
  pointer-events: stroke;
}
