/* Example how to use:
@include screen(only-mobile) {}
@include screen(tablet-and-up) {}
@include screen(desktop-and-up) {}
@include screen(only-large-desktop) {}
*/

/* 
MOBILE BREAKPOINTS: 
Mobile Design will be shown in Figma at 375px
0px - 767px

TABLET BREAKPOINTS: 
Tablet Design will be shown in Figma at 768px
768px - 1024px

DESKTOP BREAKPOINTS: 
Desktop Design will be shown in Figma at 1728px
1025px - 1728px

LARGE DESKTOP BREAKPOINTS: it is needed only for logolines
1729px +
*/

$breakpoints: (
  'sm': 767px,
  'md': 768px,
  'lg': 1025px,
  'xl': 1729px,
);

@mixin screen($size) {
  @if $size == only-mobile {
    @media screen and (max-width: map-get($breakpoints, 'sm')) {
      @content;
    }
  } @else if $size == tablet-and-up {
    @media screen and (min-width: map-get($breakpoints, 'md')) {
      @content;
    }
  } @else if $size == desktop-and-up {
    @media screen and (min-width: map-get($breakpoints, 'lg')) {
      @content;
    }
  } @else if $size == only-large-desktop {
    @media screen and (min-width: map-get($breakpoints, 'xl')) {
      @content;
    }
  }
}
