@use '../../../../src/sass/abstract/colors';
@use '../../../../src/sass/abstract/shadows';
@use '../../../../src/sass/abstract/blur';
@use '../../../../src/sass/abstract/variables';
@use '../../../../src/sass/abstract/z-index';
@use '../../../../src/sass/base/typography';

$low-lvl-menu-max-height: 591px;

.low-lvl-menu {
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  width: 100vw;
  height: auto;
  background-color: colors.$white;
  @include shadows.header-drop-shadow;

  &__container {
    padding: variables.$gutter-24 0 variables.$gutter-80;
    max-width: variables.$L-width;
    margin: 0 auto;
    max-height: $low-lvl-menu-max-height;
    column-count: 3;
    text-align: left;
  }

  &__list {
    width: 264px;
    display: block;
  }

  &__list-item {
    padding: variables.$gutter-8 variables.$gutter-16;

    &--lvl-3 {
      padding: variables.$gutter-4 variables.$gutter-16 !important;

      :lang(de-de) & {
      hyphens: auto;
      word-wrap: break-word;
      word-break: keep-all;
      }

      &:last-child {
        padding: variables.$gutter-8 variables.$gutter-16 variables.$gutter-24 - variables.$gutter-4 !important; 
      }
    }
  }

  &__link {
    color: colors.$gray-80;
    text-decoration: none;
    display: inline-block;
  }

  &__background {
    z-index: z-index.$z-index-minus-10;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    @include blur.blur-back-10;
  }
  
  &.having-lvl-3 {
    .low-lvl-menu__container {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: baseline;
      height: 452px;
      padding: 0;
      margin: variables.$gutter-24 auto;
      column-count: 4;
      column-gap: variables.$gutter-24;
      width: 1128px;
      max-height: 500px;

      
      a{
        .h6{
          font-size: typography.$font-size-18;
          line-height: 26px;
          letter-spacing: 0;
          font-style: normal;
          font-weight: typography.$font-weight-700;

          :lang(de-de) & {
            hyphens: auto;
            word-wrap: break-word;
            word-break: keep-all;
          } 
        }
      }
    }
  }
}

// for preivew mode
.editing-mode .low-lvl-menu{
  &__list-item {
    &--lvl-3 {
      hyphens: auto;
      word-wrap: break-word;

        a{
          hyphens: auto;
          word-wrap: break-word;
        }
    }
  }

  &.having-lvl-3 {
    .low-lvl-menu__container {
      a {
        .h6 {
          hyphens: auto;
          word-wrap: break-word;
        }
      }
    }
  }
  }
  
  
.prod-mode .low-lvl-menu{
    &__list-item {
      &--lvl-3 {
        word-wrap: break-word;

          a{
            word-wrap: break-word;
          }
      }
    }

    &.having-lvl-3 {
      .low-lvl-menu__container {
        a {
          .h6 {
            word-wrap: break-word;
          }
        }
      }
    }
  }
  
  
    