@use './../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/mixins';

.full-bleed-banner-with-video-tertiary {
  &__main-row {
    max-width: variables.$max-container-width;
    margin: 0 auto;
    padding: 0 variables.$gutter-24 0;
  }

  &__text-container {
    text-align: center;
    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
      padding-right: variables.$gutter-16;
    }
  }

  &__image-container {
    margin: 0 auto;
    padding-top: variables.$gutter-40;
    @include breakpoints.screen(desktop-and-up) {
      padding-top: 0;
      padding-left: variables.$gutter-8;
    }
  }

  &__title, &__tag {
    margin-bottom: variables.$gutter-24;
    color: colors.$gray-80;
    -webkit-text-fill-color: colors.$gray-80;
  }

  &__text {
    color: colors.$gray-80;
  }

  &__link {
    display: flex;
    justify-content: center;
    margin-top: variables.$gutter-24;
    @include breakpoints.screen(desktop-and-up) {
      justify-content: flex-start;
    }
  }

  &.container-gradient-background {
    @include gradient.horizontal-aurora-background;

    .full-bleed-banner-with-video-tertiary {
      &__title, &__tag {
        color: colors.$white;
        -webkit-text-fill-color: colors.$white;
      }

      &__text {
        color: colors.$white;
      }
    }
  }
}
