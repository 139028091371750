@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/variables';

.large-content-card {
  &__container {
    .read-me-button {
      display: flex;
      margin-top: variables.$gutter-16;
    }

    a {
      color: inherit;
      text-decoration-line: none;
    }

    img {
      width: auto;
      height: auto;
    }

    .link {
      .link__button-tertiary-image-container {
        height: 28px;
        position: relative;
        display: inline-block;
      }
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: variables.$gutter-16;
    width: 456px;
    height: 560px;
    background-color: colors.$white;
  }

  &__area {
    line-height: 19px;
    letter-spacing: 0.44px;
  }

  &__logo {
    max-width: 200px;
  }

  &__title {
    overflow: hidden;
    max-height: 215px;
    margin-top: variables.$gutter-8;
  }

  &__body {
    overflow: hidden;
    max-height: 75px;
    margin-top: variables.$gutter-8;

    &:hover {
      text-decoration: none;
    }
  }

  .text-container {
    width: 456px;
  }

  &:hover {
    cursor: pointer;
  }

  &--hide {
    visibility: hidden;
  }
}
