@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/mixins';
@use '../../../../sass/abstract/animations';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/z-index';
@use '../../../../sass/abstract/border-radius';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/base/typography';

$card-border-radius: border-radius.$border-radius-30;

.link-tile {
  &__card {
    padding: 0;
    position: relative;
    height: auto;
    min-height: 428px;
    overflow: hidden;

    &:hover {
      .link-tile__content {
        @include animations.image-zoom-in;
      }

      .link-tile__text-container {
        @include gradient.horizontal-aurora-background;
      }

      .link-tile__title-container {
        background-clip: padding-box;
        background-color: colors.$white;
        border-color: transparent;
      }

      .link-tile__link {
        @include gradient.titleGradient;

        .link__button-tertiary-arrow {
          display: none;
        }

        .link__button-tertiary-arrow-active {
          display: flex;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
  }

  &__content {
    z-index: z-index.$z-index-10;
    @include animations.image-zoom-out;
    height: 264px;
    min-height: 264px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    overflow: hidden;
    border-radius: $card-border-radius $card-border-radius 0 0;
  }

  &__text-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    min-height: 187px;
    border-radius: $card-border-radius;
    margin-top: -$card-border-radius;
    z-index: z-index.$z-index-20;
    background-color: colors.$white;
  }

  &__title-container {
    position: relative;
    box-sizing: border-box;
    padding: variables.$gutter-24;
    background-color: colors.$gray-light;
    border-radius: $card-border-radius;
    border: 3px solid colors.$gray-light;
  }

  &__title {
    overflow: hidden;
    min-height: 96px;
    margin-bottom: variables.$gutter-16;
    word-wrap: normal;
  }
}
