@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/base/typography';

$spriteImageIcons: '../../../../assets/icons/spriteIcon.svg';

//showing result styling starts
.showing-results-container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px 0;
  @include breakpoints.screen(tablet-and-up) {
    margin: 0;
    width: calc(100% - 48px);
  }

  @include breakpoints.screen(desktop-and-up) {
    margin: 0;
    width: 100%;
  }

  @include breakpoints.screen(only-mobile) {
    display: flex;
    width: 100%;
    padding: 40px 0;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    row-gap: 24px;
    flex-shrink: 0;
    align-self: stretch;
    flex-wrap: wrap;
    margin: 0;
  }
}

.sort-facet {
  &__option {
    font-weight: 700;
    margin-right: 24px;
    display: flex;
    gap: variables.$gutter-8;
  }
}

.sort-radio-button {
  width: 24px;
  height: 24px;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1.3px solid #333;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

.showing-results-text {
  width: 645px;
  display: flex;
  flex: 1 0 0;
  @include breakpoints.screen(only-mobile) {
    width: 100%;
    justify-content: center;
  }
  @include breakpoints.screen(tablet-and-up) {
    margin-right: 50px;
  }
  @include breakpoints.screen(desktop-and-up) {
    margin-right: 0;
  }
}

.radio-button-container:not(:empty) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin: variables.$gutter-24 0 variables.$gutter-24 0;

  @include breakpoints.screen(only-mobile) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex: 1 0 0;
    flex-wrap: wrap;
    margin-bottom: variables.$gutter-40;
  }
}

.showing-result-heading:hover {
  white-space: normal;
}

// .showing-result-heading::after {
//   content: '\2026';
//   position: absolute;
// }

.showing-result-heading::after {
  content: attr(title);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 50%);
  position: absolute;
  bottom: 0;
  right: 0;
  // background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  white-space: normal;
  width: max-content;
  max-width: 300px;
  display: none;
  z-index: 1;
}

showing-result-heading.has-tooltip::after {
  display: block;
}

.showing-result-heading {
  font-size: 20px;
  width: 645px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  position: relative;

  @include breakpoints.screen(only-mobile) {
    font-family: inherit;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    width: 327px;
    -webkit-line-clamp: 3;
  }

  @include breakpoints.screen(tablet-and-up) {
    width: auto;
    font-size: 20px;
  }
  @include breakpoints.screen(desktop-and-up) {
    width: 645px;
    font-size: 20px;
  }
}

.radio-button-container input[type='radio']:checked {
  // accent-color: #000;
  border-color: #333;
}

.radio-button-container input[type='radio']:checked::before {
  transform: scale(1);
}

.radio-button-container input[type='radio']::before {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  transform: scale(0);
  box-shadow: inset 16px 16px #333;
}

.radio-button-text {
  font-weight: 700;
  margin-right: 24px;
}

.radio-button-container input {
  width: 24px;
  height: 24px;
  margin: 0;
  appearance: none;
  background-color: #fff;
  border: 1.3px solid #333;
  border-radius: 50%;
  display: grid;
  place-content: center;
}

.saved-cards {
  padding: 20px;
}

.job-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;

  @include breakpoints.screen(tablet-and-up) {
    margin-bottom: 40px;
  }

  @include breakpoints.screen(desktop-and-up) {
    margin-bottom: 0;
  }

  .job-cards-container-internal {
    @include breakpoints.screen(tablet-and-up) {
      width: 100%;
    }
    @include breakpoints.screen(desktop-and-up) {
      width: 100% !important;
    }
    @include breakpoints.screen(only-mobile) {
      width: inherit !important;
    }

    .job-cards-container-cards {
      padding-top: 40px;

      @include breakpoints.screen(only-mobile) {
        display: flex;
        flex-direction: column;
      }

      @include breakpoints.screen(tablet-and-up) {
        width: 560px;
        margin: 40px auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        gap: 32px var(--8-Rule-4, 32px);
        padding-top: 0;
      }

      @include breakpoints.screen(desktop-and-up) {
        display: grid;
        overflow-x: auto;
        overflow-y: hidden;
        grid-template-columns: 3fr 3fr 3fr 3fr;
        scrollbar-width: none;
        padding-top: 40px;
        width: 1128px;
        margin: 0;
        align-items: normal;
        align-content: normal;
        gap: 0;
      }
    }
  }

  .job-card {
    width: 264px;
    flex: none;
    max-height: 459px;
    margin-right: 24px;
    margin-bottom: 24px;
    float: left;
    @include breakpoints.screen(tablet-and-up) {
      margin-right: 0;
      margin-bottom: 0;
    }

    @include breakpoints.screen(desktop-and-up) {
      margin-right: 24px;
      margin-bottom: 24px;
    }

    &:hover .job-card__title {
      @include gradient.titleGradient;
    }

    .link__button-tertiary-image-container {
      margin-left: 8px;
    }

    .bookmarkIcon,
    .shareIcon {
      width: 24px;
      height: 24px;
    }

    .bookmarkIconNotSelected {
      background-image: url($spriteImageIcons);
      background-position: -328px -21px;
      float: left;
    }

    .bookmarkIconSelected {
      background-image: url($spriteImageIcons);
      background-position: -372px -21px;
      float: left;
    }

    .shareIcon {
      background-image: url($spriteImageIcons);
      background-position: -196px -150px;
      float: right;
    }

    .link__button-tertiary {
      float: left;
      white-space: inherit;
      align-items: center;
      max-width: 168px;
      max-height: fit-content;
    }

    .body2 {
      width: 100%;
      text-transform: capitalize;
      float: left;
    }

    &__title {
      font-size: typography.$font-size-16;
      -webkit-line-clamp: 4;
      width: 100%;
      height: 98px;
      text-decoration: none;
      overflow-wrap: break-word;
    }

    &__tag {
      margin-bottom: 24px;
    }

    &__sub-title,
    &__Country {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__sub-title {
      margin-right: 24px;
      float: left;
      width: calc(100% - 48px);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    &__title,
    &__body {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__body * {
      display: contents;
    }

    &__title,
    &__Country,
    &__body {
      margin-bottom: 24px;
    }

    &__Country {
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }

    &__icon {
      max-width: 24px;
      float: left;
      margin-right: 10px;
    }

    &__body {
      // height: 105px;
      -webkit-line-clamp: 5;
      max-height: 120px;
      font-size: typography.$font-size-16;

      &:hover {
        text-decoration: none;
      }
    }

    .link__button-tertiary-text {
      line-height: typography.$line-height-24;
      max-height: unset;
      width: max-content;
    }

    @include breakpoints.screen(only-mobile) {
      margin-bottom: 40px;

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }

    @include breakpoints.screen(desktop-and-up) {
      &:nth-of-type(4n) {
        margin-right: 0 !important;
      }
    }
  }

  .load-more-container {
    margin: 16px 0 40px;
    display: flex;
    justify-content: center;

    @include breakpoints.screen(only-mobile) {
      margin-top: 0;
    }
    @include breakpoints.screen(tablet-and-up) {
      margin: 0;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin: 16px 0 40px;
    }

    div {
      a {
        .link__button-tertiary-text {
          display: inline;
        }
      }
    }
  }

  .no-results {
    margin: 0 auto;
    @include breakpoints.screen(only-mobile) {
      width: calc(100% - 48px);
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (min-device-pixel-ratio: 1) {
      margin: 80px auto !important;
      width: calc(100% - 48px);
    }

    .title-banner__content {
      display: flex;
      width: 100%;
      padding: var(--Spacing-vg--sys-spacing-l, 40px) var(--Spacing-vg--sys-spacing-xs, 8px);
      flex-direction: column;
      align-items: center;
      gap: var(--Spacing-vg--sys-spacing-l, 40px);
      @include breakpoints.screen(tablet-and-up) {
        max-width: 100%;
      }
      @include breakpoints.screen(only-mobile) {
        width: 100%;
        gap: var(--Spacing-vg--sys-spacing-m, 24px);
      }
      @include breakpoints.screen(desktop-and-up) {
        max-width: 100%;
      }

      @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (min-device-pixel-ratio: 1) {
        max-width: unset;
      }
    }

    &__title {
      @include breakpoints.screen(only-mobile) {
        font-size: 32px;
        font-weight: 700;
        width: 100%;
      }

      @include breakpoints.screen(tablet-and-up) {
        width: 100%;
      }

      @include breakpoints.screen(desktop-and-up) {
        width: fit-content;
      }
    }

    .noResult_clearFilter {
      @include breakpoints.screen(tablet-and-up) {
        margin-top: 0;
      }
      @include breakpoints.screen(desktop-and-up) {
        margin-top: 0;
      }
    }
  }
}

.recommended-jobs {
  padding: 80px 0;

  @include breakpoints.screen(tablet-and-up) {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 40px 0;
  }
  @include breakpoints.screen(desktop-and-up) {
    width: 100%;
    overflow: visible;
    padding: 80px 0;
    margin: 0;
  }

  @include breakpoints.screen(only-mobile) {
    width: 100%;
    overflow: hidden;
    padding: 40px 0;
  }

  .job-cards-container-internal {
    overflow-x: auto;
    max-width: 1728px;
    overscroll-behavior-inline: contain;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    align-items: center;
    touch-action: pan-x;
    margin-bottom: -50px;
    padding-bottom: 50px;
    scrollbar-width: none;
    justify-content: center;

    @include breakpoints.screen(tablet-and-up) {
      gap: 24px;
      padding: 0;
      margin-bottom: variables.$gutter-20;
      padding-bottom: variables.$gutter-20;
    }

    @include breakpoints.screen(desktop-and-up) {
      gap: normal;
      padding: 0;
      padding-bottom: 50px;
      margin-bottom: -50px;
    }

    @include breakpoints.screen(only-mobile) {
      justify-content: flex-start;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 20px 20px 20px 0 transparent;
    }
  }

  .job-cards-container-internal:has(> :nth-child(3):last-child) {
    @include breakpoints.screen(tablet-and-up) {
      justify-content: flex-start;
    }
  }

  .job-cards-container-internal:has(> :nth-child(4):last-child) {
    justify-content: flex-start;
  }

  .all-jobs {
    margin-top: 16px;
    display: flex;
    justify-content: center;

    @include breakpoints.screen(only-mobile) {
      margin-top: 0;
      z-index: 9;
    }

    @include breakpoints.screen(tablet-and-up) {
      margin-top: 0;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin-top: 16px;
    }
  }

  .job-alerts {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: center;

    @include breakpoints.screen(tablet-and-up) {
      display: flex;
      width: 768px;
      padding: var(--Spacing-vg--sys-spacing-l, 40px) var(--Spacing-vg--sys-spacing-m, 24px);
      flex-direction: column;
      align-items: center;
      gap: var(--Spacing-vg--sys-spacing-l, 40px);
      margin-bottom: 0 !important;
      padding-top: 0;
    }

    @include breakpoints.screen(desktop-and-up) {
      display: flex;
      width: 100% !important;
      padding: 0 !important;
      flex-direction: row !important;
      align-items: center;
      gap: normal !important;
      margin-bottom: 40px !important;
      padding-top: 0;
    }

    @include breakpoints.screen(only-mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--Spacing-vg--sys-spacing-l, 40px);
      margin-bottom: 40px;
    }

    h3 {
      width: 100%;
      float: left;
      margin-right: 93px;

      @include breakpoints.screen(tablet-and-up) {
        margin-right: 0;
        width: auto;
        text-align: center;
        color: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: inherit;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px; /* 126.667% */
        align-self: stretch;
      }

      @include breakpoints.screen(desktop-and-up) {
        margin-right: 93px;
        width: 100%;
        text-align: start;
        color: #333;
        font-feature-settings: normal;
        font-family: inherit;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 46px;
        align-self: auto;
      }

      @include breakpoints.screen(only-mobile) {
        margin-right: 0;
        width: auto;
        text-align: center;
        color: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: inherit;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 126.667% */
        align-self: stretch;
      }
    }

    a {
      min-width: 188px;
      display: flex;
      justify-content: end;
      white-space: break-spaces;
      max-height: unset;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      .link__button-tertiary-text {
        max-height: unset;
      }

      @include breakpoints.screen(tablet-and-up) {
        color: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
        text-align: center;
        width: 188px;
        font-family: inherit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-decoration-line: underline;
        justify-content: center;
      }

      @include breakpoints.screen(only-mobile) {
        color: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
        text-align: center;
        width: 188px;
        font-family: inherit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-decoration-line: underline;
        justify-content: center;
      }
    }

    @include breakpoints.screen(tablet-and-up) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      margin-bottom: 40px;
    }
  }
}

.job-cards-container:not(.recommended-jobs) .job-card {
  @include breakpoints.screen(only-mobile) {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  @include breakpoints.screen(tablet-and-up) {
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @include breakpoints.screen(desktop-and-up) {
    &:nth-of-type(2n) {
      margin-right: 24px;
    }
  }
}
