@use '../abstract/colors';

// Grid
// all spacing: padding, margins, gaps
$gutter-4: 4px;
$gutter-8: $gutter-4 * 2;
$gutter-16: $gutter-4 * 4;
$gutter-24: $gutter-4 * 6;
$gutter-32: $gutter-4 * 8;
$gutter-40: $gutter-4 * 10;
$gutter-48: $gutter-4 * 12;
$gutter-56: $gutter-4 * 14;
$gutter-64: $gutter-4 * 16;
$gutter-72: $gutter-4 * 18;
$gutter-80: $gutter-4 * 20;
$gutter-120: $gutter-4 * 30;
$gutter-160: $gutter-4 * 40;

$gutter-10: 10px;
$gutter-11: 11px;
$gutter-12: $gutter-24 / 2;
$gutter-20: $gutter-10 * 2;
$gutter-60: $gutter-10 * 6;

$vertical-gutter: $gutter-40;
$horizontal-gutter: $gutter-24;

// Box
$L-width: 1128px;

$max-container-width: $L-width + $horizontal-gutter + $horizontal-gutter; //1176px
$max-body-copy-width: 930px;

// Components
$header-height: 80px;
$sm-header-height: 64px;
