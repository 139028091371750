@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/z-index';
@use '../../sass/abstract/mixins';
@use '../../sass/base/waves';

.logoline {
  @include waves.logoline;

  &--front {
    @include waves.default-front-sm;

    @include breakpoints.screen(tablet-and-up) {
      @include waves.default-front-md;
    }

    @include breakpoints.screen(only-large-desktop) {
      @include waves.default-front-lg;
    }
  }

  &--back {
    @include waves.default-back-sm;

    @include breakpoints.screen(tablet-and-up) {
      @include waves.default-back-md;
    }

    @include breakpoints.screen(only-large-desktop) {
      @include waves.default-back-lg;
    }
  }

  &--default {
    position: relative;
    height: 100%;
    width: 100%;
    @include waves.default-2-combined-lines;

    &-front {
      position: absolute;
      top: 0;
      left: 0;
      @include waves.default-front-sm;

      @include breakpoints.screen(tablet-and-up) {
        @include waves.default-front-md;
      }

      @include breakpoints.screen(only-large-desktop) {
        @include waves.default-front-lg;
      }
    }

    &-back {
      position: absolute;
      top: 0;
      left: 0;
      z-index: z-index.$z-index-minus-10;

      @include waves.default-back-sm;

      @include breakpoints.screen(tablet-and-up) {
        @include waves.default-back-md;
      }

      @include breakpoints.screen(only-large-desktop) {
        @include waves.default-back-lg;
        padding-bottom: 4%;
      }
    }
  }

  &--evp-logoline {
    position: relative;
    height: 100%;
    width: 100%;
    @include waves.evp-logoline-2-combined-lines;

    @include breakpoints.screen(only-large-desktop) {
      height: 65vh;
    }

    &-front {
      position: absolute;
      top: 26px;
      left: 0;
      @include waves.evp-logoline-front-sm;

      @include breakpoints.screen(tablet-and-up) {
        top: 59px;
        @include waves.evp-logoline-front-md;
      }

      @include breakpoints.screen(desktop-and-up) {
        top: 80px;
        @include waves.evp-logoline-front-lg;
      }

      @include breakpoints.screen(only-large-desktop) {
        top: 130px;
      }
    }

    &-back {
      position: absolute;
      top: 0;
      left: 0;
      z-index: z-index.$z-index-minus-10;

      @include waves.evp-logoline-back-sm;

      @include breakpoints.screen(tablet-and-up) {
        @include waves.evp-logoline-back-md;
      }

      @include breakpoints.screen(desktop-and-up) {
        @include waves.evp-logoline-back-lg;
        padding-bottom: 4%;
      }
    }
  }

  &--fat-wave-color {
    @include waves.fat-wave-color-xs;

    @include breakpoints.screen(tablet-and-up) {
      @include waves.fat-wave-color-sm;
    }
    @include breakpoints.screen(only-large-desktop) {
      @include waves.fat-wave-color-md;
    }
  }

  &--fat-wave-white {
    @include waves.fat-wave-white-xs;

    @include breakpoints.screen(tablet-and-up) {
      @include waves.fat-wave-white-sm;
    }
    @include breakpoints.screen(desktop-and-up) {
      @include waves.fat-wave-white-md;
    }
  }

  &--divider {
    @include waves.divider-sm;

    @include breakpoints.screen(tablet-and-up) {
      @include waves.divider-md;
    }

    @include breakpoints.screen(desktop-and-up) {
      @include waves.divider-lg;
    }

    @include breakpoints.screen(only-large-desktop) {
      @include waves.divider-xl;
    }
  }

  &--heart {
    @include waves.heart;
  }

  &--unplug {
    @include waves.unplug;
  }

  &--section-header-primary {
    z-index: z-index.$z-index-minus-20;
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include waves.fat-wave-color-xs;

    @include breakpoints.screen(tablet-and-up) {
      @include waves.fat-wave-color-sm;
    }
    @include breakpoints.screen(only-large-desktop) {
      @include waves.fat-wave-color-md;
    }
  }

  &--section-header-secondary {
    z-index: z-index.$z-index-minus-20;
    position: absolute;
    width: 100%;
    left: 0;
    display: none;

    @include breakpoints.screen(tablet-and-up) {
      bottom: 0;
      @include waves.fat-wave-color-sm;
      display: block;
    }
    @include breakpoints.screen(desktop-and-up) {
      bottom: auto;
      @include waves.default-front-md;
    }
  }

  &--statement {
    position: absolute;
    width: 100%;
    bottom: 0;
    @include waves.fat-wave-white-top-xs;

    @include breakpoints.screen(tablet-and-up) {
      @include waves.fat-wave-white-sm;
    }
    @include breakpoints.screen(desktop-and-up) {
      @include waves.fat-wave-white-md;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
    }
  }

  &--homepage {
    &-front {
      position: absolute;
      z-index: z-index.$z-index-20;
      left: 0;
      bottom: 2%;
      @include waves.default-front-xs;

      @include breakpoints.screen(tablet-and-up) {
        bottom: 10%;
        @include waves.default-front-sm;
      }

      @include breakpoints.screen(desktop-and-up) {
        bottom: -22px;
        @include waves.default-front-md;
      }

      @include breakpoints.screen(only-large-desktop) {
        bottom: 0;
        @include waves.default-front-lg;
      }
    }

    &-back {
      z-index: z-index.$z-index-0;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 30%;
      @include waves.default-back-sm;

      @include breakpoints.screen(tablet-and-up) {
        bottom: 10%;
        @include waves.default-back-sm;
      }

      @include breakpoints.screen(desktop-and-up) {
        bottom: 0;
        @include waves.default-back-md;
      }

      @include breakpoints.screen(only-large-desktop) {
        @include waves.default-back-lg;
        padding-bottom: 4%;
      }
    }
  }

  &--section-hero {
    &-front {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: z-index.$z-index-10;
      bottom: 0;
      @include waves.default-front-sm;

      @include breakpoints.screen(tablet-and-up) {
        @include waves.default-front-md;
      }

      @include breakpoints.screen(only-large-desktop) {
        @include waves.default-front-lg;
      }
    }

    &-back {
      position: absolute;
      width: 100%;
      left: 0;
      z-index: z-index.$z-index-minus-30;
      bottom: 0;
      @include waves.default-back-sm;

      @include breakpoints.screen(tablet-and-up) {
        @include waves.default-back-md;
      }

      @include breakpoints.screen(only-large-desktop) {
        @include waves.default-back-lg;
        padding-bottom: 4%;
      }
    }
  }

  &__unPlugWave {
    background-image: url('../../assets/images/plug-wave.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    top: 0;
    z-index: z-index.$z-index-minus-50;
    width: 100vw;
    height: 556px;
    @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (minpixel-ratio: 1),
      screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) and (min-device-pixel-ratio: 2) {
      height: 247px;
      width: calc(100% + 48px);
      left: -24px;
    }
  }
  @include breakpoints.screen(only-mobile) {
    &__unPlugWave {
      height: 120px;
    }
  }
}
