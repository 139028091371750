@use './colors';
@use './z-index';

@mixin horizontal-aurora-background {
  background: linear-gradient(90deg, colors.$pink 12.66%, colors.$orange 94.55%);
}

@mixin horizontal-luminous-background {
  background: linear-gradient(90deg, colors.$orange 0%, colors.$gold 100%);
}

@mixin horizontal-aurora-background-border {
  background: linear-gradient(colors.$white, colors.$white),
    linear-gradient(90deg, colors.$pink 0%, colors.$orange 100%);
}

@mixin horizontal-aurora-background-inverse {
  background: linear-gradient(90deg, colors.$orange 10%, colors.$pink 90%);
}

@mixin vertical-aurora-background {
  background: linear-gradient(0deg, colors.$orange 10%, colors.$pink 90%);
}

@mixin titleGradient {
  @include horizontal-aurora-background;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-shadow: 0 0 rgba(255, 255, 255, 0);

  .editing-mode & {
    color: colors.$gray-80;
  }
}

@mixin unsetTitleGradient {
  background: none;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  text-shadow: none;
}

.gradient-text {
  @include titleGradient;
}

@mixin border-gradient {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -3px;
    border-radius: inherit;
    z-index: z-index.$z-index-minus-10;
    @include horizontal-aurora-background;
  }
}
