@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/z-index';
@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/border';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/border-radius';

// @media screen and (min-width:1024px) and (max-width:1366px) and (orientation:portrait) and (-webkit-min-device-pixel-ratio: 2)
.rmsc {
  --rmsc-main: #fff0 !important;
  --rmsc-hover: #fff0 !important;
  --rmsc-selected: #fff0 !important;
  --rmsc-border: #fff0 !important;
  --rmsc-gray: #333 !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 0 !important;
  --rmsc-radius: 20px !important;
  --rmsc-h: 38.67px !important;
}

.rmsc .dropdown-heading {
  background-image: url('../../../assets/icons/Location.svg');
  background-repeat: no-repeat;
  background-position: 14px;
}

.rmsc .dropdown-heading > svg {
  margin-right: 16px;
}

.rmsc .search {
  padding-left: 16px;
}

.disableButton {
  background-color: #e5e5e5 !important;
  color: #7f7f7f !important;
  pointer-events: none !important;
}

.search-button {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  background: #333;
  padding: 12px 24px;
  color: #fff;
  border-radius: 28px;
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include breakpoints.screen(only-mobile) {
    width: 100%;
  }
  @include breakpoints.screen(tablet-and-up) {
    width: auto;
    display: flex;
    height: 40px;
    padding: 12px 24px;
    flex-direction: column;
    align-items: center;
  }
  @include breakpoints.screen(desktop-and-up) {
    max-width: 126px;
    width: auto;
    display: flex;
    height: 40px;
    padding: 12px 24px;
    flex-direction: column;
    align-items: center;
  }
}

.searchIconSearchJobs {
  display: inline-block;
  background-image: url('../../../assets/icons/search-grey.svg');
  background-position: 10px 8px;
  padding: 8px 40px;
  width: 358.7px;
  @include breakpoints.screen(only-mobile) {
    width: 100%;
  }
  @include breakpoints.screen(tablet-and-up) {
    width: 318px;
    border-right: 1px solid #fff;
  }
}

.search-area-of-expertise {
  width: 273px;
  padding: 8px 32px 8px 16px !important;
  @include breakpoints.screen(only-mobile) {
    padding: 8px 16px !important;
  }
}

.career_search_page {
  margin: auto;
  max-width: 1128px !important;
}

.header-search-container__expanded__search-bar {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  position: relative
}

.header-search-container__expanded__search-bar::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg,#ce0569,#ff5800)
}

.career_search_page > .header-search-container__expanded__search-bar {
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 0;

  @include breakpoints.screen(only-mobile) {
    justify-content: flex-start;
    gap: 16px 24px;
    padding-bottom: 16px;
    min-width: auto;
  }
  @media screen and (min-width: 850px) and (max-width: 1366px) {
    justify-content: center;
    gap: 16px 23px;
  }
  @include breakpoints.screen(tablet-and-up) {
    padding-bottom: 0;
    justify-content: flex-start;
    gap: 16px 24px;
  }
  @include breakpoints.screen(desktop-and-up) {
    padding-bottom: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0;
  }
}

.search-dropdown {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
}

// .no-left-border {
//   border-top-left-radius: 0px;
//   border-bottom-left-radius: 0px;
//   border-left: 0px white;
// }

.location-icon-background {
  background-image: url('../../../assets/icons/search.svg');
  display: inline-block;
  width: 266px;
}

.input-border {
  border: 1px solid #333;
  border-radius: 20px;
  // width: 360px;
  position: relative;
  @include breakpoints.screen(tablet-and-up) {
    width: 320px;
  }
  @include breakpoints.screen(only-mobile) {
    width: 100%;
  }
  @include breakpoints.screen(desktop-and-up) {
    width: inherit;
  }
}

.AOEGradiantClass {
  @include gradient.border-gradient;
  border-radius: 20px;
  border: 0 !important;

  &::before {
    margin: -1.5px !important;
  }
}

.background-icon-style {
  display: inline-block;
  height: 40px;
  padding: 8px 16px 8px 48px;
  background-position: 16px 8px;
  background-repeat: no-repeat;
  border: 0;
  outline: none;
  border-radius: 20px;
  border-right: 9px solid #fff;
  background-color: #fff;
  color: #333;
  @include breakpoints.screen(tablet-and-up) {
    border-right: 1px solid #fff !important;
    width: 318px !important;
  }
  @include breakpoints.screen(desktop-and-up) {
    border-right: 9px solid #fff !important;
    width: 358.7px !important;
  }
}

.filterItem {
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  background-color: #f5f7fa;
  border: 1px solid #ccc;
  margin-right: 16px;
  padding: 8px;
  border-radius: 5px;
  width: max-content;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 16px;
  @include breakpoints.screen(only-mobile) {
    margin-right: 24px;
    margin-bottom: 16px;
    margin-top:0;
  }
  @include breakpoints.screen(tablet-and-up) {
    margin-bottom: 0;
    gap: 24px;
    margin-right: 0;
    margin-top: 0;
  }

  @include breakpoints.screen(desktop-and-up) {
    margin-bottom: 0;
    gap: 0;
    margin-right: 16px;
    margin-top: 16px;
  }
}

.filterIcon {
  padding-left: 8px;
  display: flex;
  align-items: center;
}

.clearFilter {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;

  @include breakpoints.screen(desktop-and-up) {
    margin-top: 16px;
  }

  @media screen and (min-width: 1025px) {
    display: inline-flex;
    align-items: center;
    margin-bottom: 16px;
  }
  @include breakpoints.screen(only-mobile) {
    display: inline-flex;
    margin-bottom: 16px;
  }
}

.lang-multi-select {
  width: 298px;

  input[type='checkbox'] {
    display: none;
  }
  @include breakpoints.screen(only-mobile) {
    width: 100%;
  }
  @include breakpoints.screen(tablet-and-up) {
    width: 320px;
  }
  @include breakpoints.screen(desktop-and-up) {
    width: 298px;
  }
}

.career_search_page div.header-search-container__expanded__search-bar::before {
  background: transparent;
}

.dropdown-heading-value {
  padding-left: 48px;
  padding-right: 20px;
}

label.select-item {
  padding-left: 0;
  margin: 0;
}

.options {
  padding: 0 !important;
}

.rmsc .dropdown-container {
  border-radius: 20px;

  .dropdown-content {
    margin-top: 8px;
    padding-top: 0 !important;

    .panel-content {
      .select-panel {
        ul.options {
          li {
            .select-item {
              :hover {
                background-color: #f5f7fa;
              }

              .item-renderer {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}

h2.searchjob-text {
  @include breakpoints.screen(tablet-and-up) {
    line-height: 50px;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    width: 472px;
  }
  @include breakpoints.screen(desktop-and-up) {
    line-height: 62px;
    text-align: center;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    width: 930px;
  }
  @include breakpoints.screen(only-mobile) {
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
}

.searchjob-text-container {
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 0 auto;
  @include breakpoints.screen(tablet-and-up) {
    width: 472px;
    padding: 40px 0;
    margin: 0 auto;
  }
  @include breakpoints.screen(desktop-and-up) {
    width: 100%;
    padding: 40px 0;
    margin: 0 auto;
  }
  @include breakpoints.screen(only-mobile) {
    width: 100%;
    padding: 40px 24px;
  }
}

.select-panel {
  padding-left: 0;
  border-radius: 0;
}

.item-renderer {
  // padding: 6px 2px;
  margin: 0;
}

.select-item {
  padding: 0;
  margin: 0;
}

.item-renderer:hover {
  background-color: rgb(78, 75, 240);
  color: #fff;
}

.career_search_page.header-search-container__expanded__content {
  @media screen and (min-width: 1025px) {
    padding: 40px 0;
  }

  @include breakpoints.screen(only-mobile) {
    margin-top: 55px;
    margin-bottom: 40px;
    padding: 0 !important;
  }

  @include breakpoints.screen(tablet-and-up) {
    display: flex;
    width: 768px;
    padding: var(--Spacing-vg--sys-spacing-l, 40px) var(--Spacing-vg--sys-spacing-m, 24px);
    align-items: center;
    align-content: center;
    gap: var(--Spacing-vg--sys-spacing-s, 16px) var(--Spacing-vg--sys-spacing-m, 24px);
    flex-wrap: wrap;
  }
  @include breakpoints.screen(desktop-and-up) {
    display: block;
    width: 1128px;
    padding: 40px 0;
    align-content: center;
  }
}

.line-separator {
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  border-top: 1px solid #b2b2b2;
}

input.searchIconSearchJobs,
input.footerSearch {
  &::placeholder {
    color: #333 !important;
    opacity: 1;
  }
}

.itemRenderer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #333;
}

// .itemRenderer:hover {
//   background-color: #f5f7fa;
// }


.itemRenderer.isChecked {
  background-image: url('../../../assets/icons/checkmark.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
}

.AOEMenuClassName {
  padding: 0 !important;

  div {
    padding-right: 12px;

    &:first-child {
      margin-top: 16px !important;
    }
  }
}

.options,
.AOEMenuClassName,
.custom-menuList {
  z-index: z-index.$z-index-10;
  padding: variables.$gutter-16 0;

  &::-webkit-scrollbar {
    width: 23px;
    height: 0;
  }

  &::-webkit-scrollbar-thumb {
    height: 132px;
    box-shadow: inset 20px 20px 20px 0 colors.$gray-10;
    border: border.$border-8-solid-transparent;
    border-radius: border-radius.$border-radius-20;
  }
}

.gradiantBorder .panel-content {
  @include gradient.border-gradient;
  border: 1px;
}

.AOEClassName {
  width: 271.7px;

  @include breakpoints.screen(only-mobile) {
    width: 100%;
  }

  @include breakpoints.screen(tablet-and-up) {
    width: 318px;
  }

  .AOEControlClassName {
    border-radius: 20px !important;
    border: 0 !important;
    height: 40px;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px !important;
    align-items: normal;
  }

  .AOEMenuClassName > .Dropdown-option.is-selected {
    padding-right: 28px;
    background-image: url('../../../assets/icons/checkmark.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px);
    background-color: #f5f7fa !important;
  }
}

.is-open > .AOEMenuClassName {
  border-radius: 20px;
  border: 1px solid #333;
  margin-top: 5px !important;
}

.AOEMenuClassName > .Dropdown-option {
  font-size: 16px;
  font-weight: 400 !important;
}

.AOEMenuClassName > .Dropdown-option:hover {
  background-color: #f5f7fa !important;
}

.dropdownPlaceHolder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include breakpoints.screen(only-mobile) {
    width: 271px;
  }
}

.AOEMenuGradiant {
  border: double 3px transparent !important;
  border-radius: 20px !important;
  background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #ce0569, #ff5800);
  background-origin: border-box;
  background-clip: content-box, border-box;
  overflow: auto;
}
// to check

span.input-border select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url('../../../assets/icons/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: 237px 8px;
  color: #333;
  width: 273px;

  @include breakpoints.screen(only-mobile) {
    background-position: right 8px top 8px;
    width: 100%;
  }
  @include breakpoints.screen(tablet-and-up) {
    width: 318px;
    background-position: 276px 8px;
    background-color: #fff;
    border-right: 1px solid #fff;
  }
}

.gradientRight {
  // width: 361px;
  &::before {
    @include breakpoints.screen(tablet-and-up) {
      margin-right: 0 !important;
    }
  }
}

.custom-select__control {
  height: 40px;
  border-color: transparent !important;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  padding-left: 8px;
  @include breakpoints.screen(desktop-and-up) {
    width: 273px !important;
  }
}

.custom-select__indicator-separator {
  display: none !important;
}

.custom-select__menu-list {
  padding: 16px 0 !important;
}

.gradiantBorder {
  @include gradient.border-gradient;
  border: 1px solid #fff0 !important;
  font-weight: 700;

  &::before {
    margin: -2px !important;
  }

  .custom-select__menu {
    // @include gradient.border-gradient;
    border: double 3px transparent !important;
    border-radius: 20px !important;
    background-image: linear-gradient(#fff, #fff), linear-gradient(90deg, #ce0569, #ff5800);
    background-origin: border-box;
    background-clip: content-box, border-box;
    overflow: auto;
  }
}

.custom-select__option {
  &:hover {
    background-color: #f5f7fa !important;
  }
}

.custom-select__option--is-focused {
  background-color: #fff !important;
}

.custom-select__indicators {
  margin-right: 10px;
  transform: rotate(360deg);
}

.custom-select__option--is-selected {
  background-color: #f5f7fa !important;
  background-image: url('../../../assets/icons/checkmark.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  padding-right: 28px !important;
}

.custom-select__placeholder {
  color: #333 !important;
}

.custom-select__control--is-focused {
  border: 0 solid transparent;
}

.custom-select__indicator,
.customArrowPosition {
  background-image: url('../../../assets/icons/chevron-down-333.svg');
  background-repeat: no-repeat;
  background-position: center;

  svg {
    path {
      visibility: hidden;
    }
  }
}

.customArrowTransition {
  transform: rotate(-180deg);
  transition: 250ms;
}

.customArrowPosition {
  margin-right: 16px;
}
