@use '../../../sass/layout/layout';
@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/abstract/shadows';
@use '../../../sass/abstract/mixins';
@use '../../../sass/abstract/blur';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/z-index';
@use '../../../sass/base/typography';
@use '../../../sass/base/icons';
@use '../../../sass/vendors/atomic/atomic-search-box.scss';

.search__components-wrapper {
  @include layout.component-with-paddings;
  position: relative;

  .loader {
    &::before {
      background: rgba(colors.$white, 0.5) !important;
    }
  }

  .atomic-search-interface {
    width: 100%;
  }

  .atomic-search-interface-search-executed {
    width: 100%;
  }

  .search-box-container {
    @include atomic-search-box.atomic-search-box--secondary;
    z-index: z-index.$z-index-50;
    background-color: colors.$white;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .grow.flex.items-center {
      align-items: baseline;
    }

    &__search-bar {
      display: flex;
      align-items: center;
      padding-bottom: variables.$gutter-16;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 4px;
        @include gradient.horizontal-aurora-background;
      }

      atomic-search-box {
        &::part(clear-icon) {
          margin: 0;
          @include icons.icon-size-24;
          @include icons.background-icon('dismiss-circle');
          background-position: -64px -108px;
          color: transparent;
        }
      }
    }
  }
}

.search-container {
  padding: 0;
  display: flex;

  .atomic-search-interface {
    width: 100%;
  }

  .atomic-search-interface-search-executed {
    width: 100%;
  }

  .flex.items-center {
    align-items: baseline;
  }
}

.search-box {
  display: flex;
  padding: 0;
  width: 24px;

  &--hidden {
    display: none;
  }

  &__background {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: z-index.$z-index-minus-10;
    @include blur.blur-back-10;
  }

  &__expanded {
    z-index: z-index.$z-index-50;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;

    atomic-search-box {
      &::part(wrapper) {
        border: 0;
        flex-direction: row-reverse;
        box-shadow: none;
      }

      &::part(suggestions-wrapper) {
        border: 0;
        flex-direction: column;
        margin-top: variables.$gutter-10 + variables.$gutter-8;

        &::after {
          content: '';
          display: block;
          background-color: colors.$white;
          width: 100vw;
          height: 100%;
          position: absolute;
          bottom: 0;
          z-index: z-index.$z-index-minus-10;
          left: 50%;
          transform: translate(-50%, 0);
        }

        &::before {
          content: 'Suggested Results';
          border: 0;
          @include typography.subheading1;
          margin-top: variables.$gutter-64 - variables.$gutter-10;
          margin-bottom: variables.$gutter-8 + variables.$gutter-10;
        }
      }

      &::part(query-suggestion-text) {
        font-size: typography.$font-size-18;
      }

      &::part(suggestion) {
        display: flex;
        margin-bottom: variables.$gutter-8;
        padding: variables.$gutter-8 variables.$gutter-16;

        &:hover {
          background-color: colors.$gray-light;
          border-radius: border-radius.$border-radius-20;
          cursor: pointer;
        }
      }

      &::part(input) {
        padding: 0;
        min-height: 27px;
        font-style: normal;
        font-weight: typography.$font-weight-700;
        line-height: normal;
        font-size: typography.$font-size-20;
        @include breakpoints.screen(tablet-and-up) {
          font-size: typography.$font-size-24;
          min-height: 32px;
        }
        @include breakpoints.screen(desktop-and-up) {
          font-size: typography.$font-size-28;
          min-height: 36px;
          line-height: typography.$line-height-36;
        }
      }

      &::part(clear-button) {
        border: 0;
        margin: 0 0 0 variables.$gutter-10;
        @include icons.icon-size-24;
        @include icons.background-icon('dismiss-circle');
        color: transparent;
        background-position: -64px -107px;
      }

      &::part(submit-icon) {
        @include icons.icon-size-24;
        @include breakpoints.screen(desktop-and-up) {
          @include icons.icon-size-32;
        }
      }

      &::part(submit-button) {
        @include icons.icon-size-24;
        order: 0;
        display: flex;
        align-self: center;
        margin-right: variables.$gutter-16;

        @include breakpoints.screen(desktop-and-up) {
          @include icons.icon-size-32;
        }
      }

      &::part(query-suggestion-icon) {
        @include icons.icon-size-24;
        margin-right: 0 variables.$gutter-16 0 0;
      }
    }

    atomic-search-box-query-suggestions {
      &::before {
        content: 'Suggested Results';
        @include typography.subheading1;
      }

      &::part(query-suggestion-text) {
        font-size: typography.$font-size-18;
        font-style: normal;
        font-weight: typography.$font-weight-700;
        line-height: normal;
      }
    }

    .atomic-search-interface {
      width: 100%;
    }

    .atomic-search-interface-search-executed {
      width: 100%;
    }

    .grow.flex.items-center {
      align-items: baseline;
    }

    &__search-bar {
      min-height: 60px;
      display: flex;
      align-items: center;
      padding-bottom: variables.$gutter-16;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 100%;
        height: variables.$gutter-4;
        @include gradient.horizontal-aurora-background;
        z-index: 11;
      }
    }
  }

  &__expanded-box {
    @include shadows.header-drop-shadow;
    padding-top: variables.$gutter-40;
    padding-bottom: variables.$gutter-40;
    background-color: colors.$white;
  }

  &__expanded-content {
    margin: 0 auto;
    padding: 0 variables.$gutter-24;
    width: 100%;
    max-width: variables.$max-container-width;
  }
}

.client-search__search-input {
  margin-bottom: variables.$gutter-80;
  @include atomic-search-box.atomic-search-box--tertiary;

  &.client-search-job-search-field {
    @include atomic-search-box.atomic-search-box--search-job;
    margin-top: variables.$gutter-40;
  }
}
