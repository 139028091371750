@use './../../sass/abstract/gradient';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';

.related-links {
  &__title {
    text-align: center;
    margin-bottom: variables.$gutter-40;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: variables.$gutter-10 + variables.$gutter-12;

    @include breakpoints.screen(desktop-and-up) {
      padding-bottom: 0;
    }
  }

  &__cards-section {
    max-width: variables.$L-width;
    display: flex;
    gap: variables.$gutter-24;
    flex-wrap: nowrap;
    @include mixins.featured-scroll;
    margin-inline: -(variables.$gutter-24);
    padding: 0 variables.$gutter-24 variables.$gutter-64 variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      flex-wrap: wrap;
      justify-content: center;
      overflow: hidden;
      padding: 0;
      margin: 0;
      column-gap: variables.$gutter-24;
      row-gap: variables.$gutter-40;
    }
  }

  &__card-box-content {
    display: flex;
    flex-wrap: wrap;
  }
}