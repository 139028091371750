@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/z-index';

.section-header-primary {
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoints.screen(tablet-and-up) {
      justify-content: center;
    }
  }

  &__header {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &__main-container {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 52px;
    padding-bottom: 107px;

    @include breakpoints.screen(desktop-and-up) {
      padding-top: 118px;
      padding-bottom: 247px;
    }

    .no-padding-container{
      @include breakpoints.screen(only-mobile){
      padding: 0;
      }
    }
  }

  &__image-container {
    z-index: z-index.$z-index-10;
    @include breakpoints.screen(only-mobile){
      height: variables.$gutter-160 + variables.$gutter-10;
    }
  }
  
  &__image{
    img{
      position: relative !important;
    }
    @include breakpoints.screen(only-mobile){
    width: 100vw;
    height: 100%;
    }
  }
}
