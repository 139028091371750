@use '../../sass/abstract/colors';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/shadows';
@use '../../sass/abstract/mixins';

.follow-us {
  &__title {
    @include gradient.titleGradient;
    text-align: center;
    padding-bottom: variables.$gutter-24;
    @include breakpoints.screen(desktop-and-up) {
      padding-bottom: variables.$gutter-32;
    }
  }

  &__description {
    text-align: center;
    padding-bottom: variables.$gutter-24;
    @include breakpoints.screen(tablet-and-up) {
      padding-bottom: variables.$gutter-40;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 22px;

    > div {
      padding: variables.$gutter-8;
      background-color: colors.$gray-10;
      border-radius: variables.$gutter-8;
      transition: background-color 0.3s ease;

      a {
        width: 24px;
        height: 24px;

        .followUs__image {
          img {
            width: 24px;
            height: 24px;
            filter: invert(0%);
            transition: filter 0.3s ease;
            object-fit: fill;

            &:hover {
              filter: invert(1) hue-rotate(183deg) contrast(2);
            }
          }
        }
      }

      &:hover {
        @include gradient.horizontal-aurora-background;
      }
    }
  }
}
