@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/z-index';
@use '../../../../sass/abstract/variables';

.full-bleed-video {
  max-width: 1728px;
  width: 100%;
  display: flex;
  overflow: hidden;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: static;

  @include breakpoints.screen(desktop-and-up) {
    min-height: 740px;
    flex-direction: row;
    justify-content: space-between;
    align-items: normal;
    padding: 54px variables.$gutter-80;
    position: relative;
  }

  &__background-container {
    &--edit {
      z-index: z-index.$z-index-0;
    }
  }

  &__background {
    width: 100%;
    z-index: z-index.$z-index-minus-70;
    position: relative;

    @include breakpoints.screen(only-mobile) {
      height: 160px;
    }
    @include breakpoints.screen(tablet-and-up) {
      height: 328px;
    }
    @include breakpoints.screen(desktop-and-up) {
      max-height: 100%;
      min-height: 100%;
      height: 100%;
      max-width: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__carousel {
    &--edit {
      z-index: z-index.$z-index-10;
    }
  }

  &__upnext {
    max-width: 350px;
    align-self: flex-end;
    margin-left: auto;
    display: none;

    @include breakpoints.screen(desktop-and-up) {
      display: block;
    }
  }
}
