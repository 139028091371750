@use '../../../sass/abstract/breakpoints';

$spriteImageIcons: '../../../assets/icons/spriteIcon.svg';

.JobDetailButton {
  display: inline-flex;
  height: var(--8-rule-5, 40px);
  @include breakpoints.screen(tablet-and-up) {
    display: flex;
    justify-content: center;
    height: auto;
  }
  @include breakpoints.screen(only-mobile) {
    display: flex;
    justify-content: center;
  }
}

.JobDetailApplyNow {
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  height: 40px;
  padding: 12px var(--spacing-vg-sys-spacing-m, 24px);
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-vg-sys-spacing-0, 0);
  flex-shrink: 0;
  border-radius: 28px;
  background: var(--primary-colours-grey-avanade-gray-80, #333);
  color: #fff !important;
  text-decoration: none;
}

.JobDetailSaveJob {
  @include breakpoints.screen(desktop-and-up) {
    max-width: 275px;
  }
  display: inline-flex;
  height: 40px;
  padding: 9px var(--spacing-vg-sys-spacing-s, 16px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  flex-shrink: 0;
  border-radius: 28px;
  border: 1px solid var(--primary-colours-grey-avanade-gray-80, #333);
  background: var(--primary-colours-white-avanade-white, #fff);
  margin-left: 16px;
  text-decoration: none;
  background-repeat: no-repeat;
  cursor: pointer;
}

.bookmarkIconNotSelected::after {
  content: '';
  background-image: url($spriteImageIcons);
  background-position: -328px -21px;
  height: 24px;
  width: 24px;
}

.bookmarkIconSelected::after {
  content: '';
  background-image: url($spriteImageIcons);
  background-position: -372px -21px;
  height: 24px;
  width: 24px;
}
