@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/variables';

.metric {
  text-align: center;
  display: flex;
  justify-content: center;
  height: 100%;

  &__content {
    height: 100%;
    @include breakpoints.screen(tablet-and-up) {
      padding: 0 variables.$gutter-12;
    }
  }

  &__number-container {
    margin-bottom: variables.$gutter-8;
  }

  &__title {
    margin-bottom: variables.$gutter-8;
  }

  &__text {
    margin: 0 auto;

    p {
      text-align: center;
    }
  }

  &__prefix {
    display: inline-block;
  }

  &__number {
    display: inline-block;
  }

  &__postfix {
    display: inline-block;
  }
}
