@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/mixins';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/base/typography';

.text-block-with-image {
  .column-splitter {
    justify-content: space-between;
    gap: 24px;

    .col-6 {
      width: 100%;
      @include breakpoints.screen(tablet-and-up) {
        width: calc(50% - variables.$gutter-12);
      }
    }
  }

  .next-image{
    img{
      position: relative !important;
    }
  }
}

.timage-left {
  &__header {
    @include gradient.titleGradient;
  }

  &__description {
    font-size: typography.$font-size-16;
    padding: variables.$gutter-24 0;
    line-height: typography.$line-height-24;
  }

  &__text-column {
    display: flex;
    flex-direction: column;
    text-align: center;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
      order: 2;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: variables.$gutter-24;
    @include breakpoints.screen(desktop-and-up) {
      justify-content: flex-start;
      margin-bottom: 0;
    }
  }

  &__image-column {
    order: 2;
    @include breakpoints.screen(desktop-and-up) {
      order: 1;
    }
  }

  &__image-container {
    @include breakpoints.screen(desktop-and-up) {
      margin-right: variables.$gutter-24;
    }
  }
}

.remove-extra-margin {
  margin-top: -8px;
}