@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/breakpoints';

.double-accordion-card {
  max-width: variables.$L-width;

  &__content {
    margin-bottom: variables.$gutter-32;
    display: flex;
    flex-wrap: wrap;

    @include breakpoints.screen(tablet-and-up) {
      flex-wrap: nowrap;
    }
  }

  &__avatar {
    @include breakpoints.screen(tablet-and-up) {
      margin-right: variables.$gutter-24;
    }
  }

  &__avatar-picture {
    width: 150px;
    min-width: 150px;
    height: 150px;
    max-height: 150px;
    @include breakpoints.screen(tablet-and-up) {
      width: 264px;
      height: 264px;
      max-height: 264px;
      min-width: 264px;
    }
  }

  &__name {
    display: none;
    @include breakpoints.screen(tablet-and-up) {
      display: block;
    }
  }

  &__position {
    margin-top: variables.$gutter-8;
    text-align: left;
    display: none;
    @include breakpoints.screen(tablet-and-up) {
      display: block;
    }
  }

  &__description-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__social {
    display: flex;
    gap: variables.$gutter-24;
    margin-top: variables.$gutter-16;
  }

  &__bio {
    margin-top: variables.$gutter-24;
    text-align: left;
    width: 100%;

    ul {
      list-style: disc;
      margin-left: variables.$gutter-24;
    }
  }

  &__short-bio--hide {
    display: none !important;
  }

  &__long-bio--hide {
    display: none !important;
  }

  &__show-more {
    height: 24px;
    padding-bottom: variables.$gutter-32;
  }

  &__button-container {
    display: flex;
    align-items: flex-start;
  }

  &__show-more-container {
    margin-top: variables.$gutter-16;
    display: flex;
    align-items: center;
    gap: variables.$gutter-8;
  }

  &__icon-container {
    display: flex;
    align-items: center;
    margin-left: variables.$gutter-8;
  }
}
