@use '../../sass/base/typography';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/border';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/variables';
@use '../../components/shared/nextImg/NextImageWithNullFallback.scss';

.contact-us {
  display: none;
  @include mixins.modal;

  &.show {
    display: block;
  }

  .close-button {
    position: absolute;
    top: -56px;
    right: 0;
    display: flex;
    align-items: center;
    gap: variables.$gutter-16;
    font-size: typography.$font-size-16;
    line-height: typography.$line-height-24;
    font-weight: typography.$font-weight-700;

    @include breakpoints.screen(desktop-and-up) {
      top: -93px;
      right: 8px;
    }
  }

  @include breakpoints.screen(only-mobile) {
    padding-top: variables.$gutter-80 !important;
  }

  .row.column-splitter {
    position: relative;
  }
}

.contact-us-page {
  &.hide-contact-expert {
    display: none;
  }
}

.contact-us,
.contact-us-page {
  margin-top: 0;
  padding-top: variables.$gutter-120;
  overflow-y: auto;

  .row {
    flex-direction: column;
    justify-content: flex-start;
    @include breakpoints.screen(desktop-and-up) {
      flex-direction: row;
    }
  }

  .col-6 {
    &.col-lg-3 {
      width: 100%;
      @include breakpoints.screen(desktop-and-up) {
        width: 25%;
      }
    }

    &.col-lg-9 {
      width: 100%;

      @include breakpoints.screen(desktop-and-up) {
        width: 75%;
      }
    }
  }

  .rich-text {
    h1 {
      @include gradient.titleGradient;
    }

    p {
      margin-bottom: 0;
      font-size: typography.$font-size-18;
      @include breakpoints.screen(desktop-and-up) {
        font-size: typography.$font-size-16;
      }
    }
  }

  .form {
    margin-top: variables.$gutter-40;
    padding-right: 0;

    @include breakpoints.screen(desktop-and-up) {
      padding-right: variables.$gutter-120;
    }
  }

  .mktoFormRow {
    @include breakpoints.screen(only-mobile) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  
  .mktoButtonRow {
    @include breakpoints.screen(only-mobile) {
      display: block;
    }
  }

  .how-can-we-help {
    padding-top: variables.$gutter-40;
    border-top: border.$border-1-solid-gray20;
    max-width: 100%;
    margin: variables.$gutter-40 0 0;
    text-align: left !important;

    @include breakpoints.screen(only-mobile) {
      margin-top: variables.$gutter-80;
      text-align: left !important;
    }

    @include breakpoints.screen(desktop-and-up) {
      max-width: 264px;
      margin: 268px 0 0;
    }

    h6 {
      text-decoration: none;
      margin-bottom: variables.$gutter-16;
    }

    h4 {
      margin-bottom: variables.$gutter-40;
    }

    a {
      display: block;
      margin-bottom: variables.$gutter-24;
    }

    p:last-child > a {
      margin-bottom: variables.$gutter-40;
    }

    h6:last-child {
      margin-top: variables.$gutter-40;
    }
  }

  .link__button-tertiary {
    margin-bottom: variables.$gutter-40;
  }
}

.contact-form-wrapper {
  .how-can-we-help {
    border-top: border.$border-1-solid-gray20;
    padding-top: variables.$gutter-40;

    div {
      p {
        margin: 0;
      }

      p a {
        display: block;
        padding-bottom: variables.$gutter-40;
      }
    }
  }
}
