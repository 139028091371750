@use './../../sass/base/buttons';
@use './../../sass/abstract/mixins';
@use './../../sass/abstract/variables';
@use './../../sass/abstract/breakpoints';

.link {
  @include buttons.link;

  &--capitalize {
    @include mixins.change-uppercase-to-capitalize;
  }

  &__button-primary {
    @include buttons.button-primary;

    &--disabled {
      @include buttons.button-primary-disabled;
    }
    @include breakpoints.screen(only-mobile) {
      width: 100%;
    }
  }

  &__button-secondary {
    @include buttons.button-secondary;

    &--disabled {
      @include buttons.button-secondary-disabled;
    }
  }

  &__button-tertiary {
    @include buttons.button-tertiary;

    a{
      text-decoration: none;
    }
  }
}
