@use '../../../../sass/abstract/variables';

.content-gallery-card {
  width: 264px;
  min-height: 428px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  &__main-container {
    margin-bottom: variables.$gutter-16;
  }

  &__image {
    margin-bottom: variables.$gutter-16;
    width: 264px;
    min-width: 264px;
    max-width: 264px;
    height: 264px;
    min-height: 264px;
    max-height: 264px;
  }

  &__title {
    display: none;
    margin-bottom: variables.$gutter-8;
  }

  &__category {
    display: none;
    margin-bottom: variables.$gutter-8;
  }
}

.content-gallery__show-titles {
  .content-gallery-card {
    &__title {
      display: block;
    }

    &__category {
      display: block;
    }
     
  }
}
