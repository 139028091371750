@use '../../sass/abstract/variables';
@use '../../sass/base/typography';
@use '../../sass/abstract/breakpoints';

.show-hide {
  &--default {
    margin: variables.$gutter-24 0 variables.$gutter-80;

    .show-hide__check-box-container {
      display: flex;
      align-items: center;
      gap: variables.$gutter-8;
    }

    .do-not-sell-script {
      &__paragraph {
        font-size: typography.$font-size-16;
        line-height: variables.$gutter-24;
        margin-bottom: variables.$gutter-24;
        margin-left: variables.$gutter-32;
      }
    }
  }

  &--section-with-placeholder {
    .show-hide__button {
      display: inline-flex;

      &--clicked {
        margin-bottom: variables.$gutter-24;
      }
    }

    .show-hide__icon {
      transition: all 300ms linear;
      margin-left: variables.$gutter-8;

      &--rotate {
        transform: rotate(180deg);
      }

      .icon--chevron-down {
        &::before {
          background-position: -284px -63px;
          background-size: inherit;
        }
      }
    }
  }
}
