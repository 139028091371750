@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border';
@use './../../../sass/abstract/gradient';
@use './../../../sass/abstract/border-radius';
@use '../../../sass/abstract/mixins';
@use '../../../sass/abstract/animations';
@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/breakpoints';

$card-width: 264px;
$card-height: 646px;
$header-height: $card-width;
$header-width: $card-width;

.related-links-card {
  border-radius: border-radius.$border-radius-30;
  width: $card-width;
  min-height: $card-height;
  background-color: colors.$gray-light;
  position: relative;
  border: border.$border-4-solid-transparent;
  transition: transform 0.2s ease-in-out;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  flex: 1;

  &:hover {
    border: border.$border-4-solid-transparent;
    @include gradient.border-gradient;

    .related-links-card__header-image {
      @include animations.image-zoom-in;
    }

    .related-links-card__learn-more {
      @include gradient.horizontal-aurora-background;
      background-clip: border-box;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      .related-links-card__arrow {
        display: none;
      }

      .related-links-card__arrow-active {
        display: flex;
      }
    }
  }

  &__header-image {
    @include animations.image-zoom-out;
    height: $header-height;
    max-height: $header-height;
    min-height: $header-height;
    min-width: $header-width;
    width: 100%;
  }

  &__header {
    border-radius: border-radius.$border-radius-27 border-radius.$border-radius-27 0 0;
    overflow: hidden;
  }

  &__main-box {
    flex: 1;
  }

  &__body {
    padding: variables.$gutter-24;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: colors.$gray-light;
    border-radius: 0 0 border-radius.$border-radius-30 border-radius.$border-radius-30;
    flex-grow: 1;
  }

  &__title {
    color: colors.$gray-80;
    margin-bottom: variables.$gutter-16;
  }

  &__description {
    color: colors.$gray-80;
  }

  &__learn-more {
    display: flex;
    align-items: center;
    color: colors.$gray-80;
    margin-top: variables.$gutter-16;
    text-decoration: none;
  }

  &__arrow-icon-container {
    margin-left: variables.$gutter-8;
    display: flex;
  }

  &__arrow-active {
    display: none;
  }

  &__arrow {
    display: flex;
  }
}
