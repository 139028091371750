/* 
How to use, examples:
in .html: <i className="icon icon--28 icon--chevron-left"></i>
in .scss: @include icons.background-icon('mute');
*/
@use '../../../src/sass/abstract/breakpoints';
$spriteImageIcons: '../../assets/icons/spriteIcon.svg';

$icon-name-map: (
  'add',
  'arrow-circle-right',
  'arrow-down-active',
  'arrow-down',
  'arrow-right-active',
  'arrow-right',
  'arrow-up-active',
  'arrow-up',
  'checkmark',
  'chevron-down-white',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'dismiss',
  'dismiss-circle',
  'double-chevron-down',
  'email',
  'facebook',
  'fax',
  'full-screen',
  'globe-active',
  'globe',
  'hamburger',
  'instagram',
  'link',
  'linkedin',
  'minimize-screen',
  'mute',
  'pause',
  'phone',
  'picture-in-picture-exit',
  'picture-in-picture',
  'pinterest',
  'play-brightcove',
  'play-button',
  'play',
  'quote',
  'search-active',
  'search',
  'share',
  'caption',
  'sparkle',
  'subtract',
  'volume-1',
  'volume-2',
  'volume-3',
  'youtube',
  'calendar',
  'Location',
  'mapicon',
  'spriteIcon',
  'chevron-circle'
);

$icon-size-xs: 16px;
$icon-size-sm: 24px;
$icon-size-md: 28px;
$icon-size-lg: 32px;
$icon-size-xl: 48px;
$icon-size-xxl: 72px;

$icon-size-map: (
  '16': $icon-size-xs,
  '24': $icon-size-sm,
  '28': $icon-size-md,
  '32': $icon-size-lg,
  '48': $icon-size-xl,
  '72': $icon-size-xxl,
);

@mixin icon-size-class($new-icons-size-map) {
  @each $name, $value in $new-icons-size-map {
    .icon--#{$name} {
      width: $value;
      height: $value;
      min-width: $value;
      min-height: $value;
    }
  }
}
@include icon-size-class($icon-size-map);

@mixin icon-name($new-icon-name) {
  @each $name in $new-icon-name {
    .icon--#{$name} {
      &::before {
        background-image: url($spriteImageIcons);
      }
    }
  }
}
@include icon-name($icon-name-map);

@mixin background-icon($new-icon-name) {
  @each $name in $new-icon-name {
    background-image: url($spriteImageIcons);
    background-repeat: no-repeat;
  }
}

@mixin icon-size-16 {
  width: $icon-size-xs;
  height: $icon-size-xs;
  min-width: $icon-size-xs;
  min-height: $icon-size-xs;
}

@mixin icon-size-24 {
  width: $icon-size-sm;
  height: $icon-size-sm;
  min-width: $icon-size-sm;
  min-height: $icon-size-sm;
}

@mixin icon-size-28 {
  width: $icon-size-md;
  height: $icon-size-md;
  min-width: $icon-size-md;
  min-height: $icon-size-md;
}

@mixin icon-size-32 {
  width: $icon-size-lg;
  height: $icon-size-lg;
  min-width: $icon-size-lg;
  min-height: $icon-size-lg;
}

@mixin icon-size-48 {
  width: $icon-size-xl;
  height: $icon-size-xl;
  min-width: $icon-size-xl;
  min-height: $icon-size-xl;
}

.icon {
  display: flex;

  &::before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    // background-position: center;
    // background-size: contain;
    height: 100%;
    width: 100%;
  }
}

//Icons

.icon--globe {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 441px 341px;
    // background-position: -294px -94px;
    background-position: -328px -108px;
  }
}

.icon--globe-active {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 542px 355px;
    // background-position: -267px -99px;
    background-position: -240px -108px;
  }
}

.icon--search {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 1600%;
    // background-position: 33% 54%;
    background-position: -152px -196px;
  }
}

.icon--search-active {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 1600%;
    // background-position: 14% 53%;
    background-position: -64px -196px;
  }
}

.icon--chevron-left {
  &::before {
    background-image: url($spriteImageIcons);
    background-size: 1900%;
    background-position: 71% 17.3%;
  }
}

.icon--chevron-right {
  &::before {
    background-image: url($spriteImageIcons);
    background-size: 1900%;
    background-position: 80% 17.3%;
  }
}

.icon--arrow-right {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 1600%;
    // background-position: 43% 4%;
    background-position: -195px -19px;
  }
}

.icon--arrow-right-active {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 1600%;
    // background-position: 33% 4%;
    background-position: -151px -19px;
  }
}

.icon--dismiss {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 1600%;
    // background-position: 23% 29%;
    background-position: -105px -105px;
  }
}

.icon--phone {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 1600%;
    // background-position: 52% 42%;
    background-position: -240px -150px;
  }
}

.icon--chevron-down {
  &::before {
    background-image: url($spriteImageIcons);
    // background-position: -282px -66px;
    background-position: -530px -118px;
    background-size: 1900%;
  }
}

.icon--chevron-down-white {
  &::before {
    background-image: url($spriteImageIcons);
    // background-position: -238px -46px;
    background-position: -496px -132px;
    background-size: 2100%;
  }
}

.icon--add {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 425px 379px;
    // background-position: -3px -21px;
    background-position: -45px -45px;
    background-size: 2234%;

    @include breakpoints.screen(only-mobile) {
      background-position: -15px -16px;
      background-size: 1700%;
    }
  }
}

.icon--subtract {
  &::before {
    background-image: url($spriteImageIcons);
    // background-size: 425px 379px;
    // background-position: -235px -112px;
    // background-position: -282px -112px;
    background-position: -470px -175px;
    background-size: 1700%;

    @include breakpoints.screen(only-mobile) {
      background-position: -275px -103px;
      background-size: 1700%;
    }
  }
}

.icon--sparkle {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -375px -196px;
  }
}

.icon--calendar {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -63px -64px;
  }
}

.icon--arrow-down {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -88px -14px;
    background-size: 1700%;
  }
}

.icon--arrow-up {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -279px -18px;
  }
}

.icon--fax {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -194px -105px;
  }
}

.icon--email {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -150px -106px;
  }
}

.icon--facebook {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -326px -239px;
  }
}

.icon--linkedin {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -236px -238px;
  }
}

.icon--instagram {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -280px -239px;
  }
}

.icon--youtube {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -192px -239px;
  }
}

.icon--checkmark {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -151px -64px;
  }
}

.icon--mapicon {
  &::before {
    background-image: url("../../assets/icons/mapicon.svg");
  }
}

.icon--Location {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -64px -152px;
  }
}

.icon--quote {
  &::before {
    background-image: url($spriteImageIcons);
    background-position: -421px -295px;
  }
}
