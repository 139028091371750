@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/mixins';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/variables';

.evidence-card {
  &__quote {
    display: flex;
    align-items: flex-start;
    gap: variables.$gutter-24;
    padding-top: variables.$gutter-8;
    @include breakpoints.screen(only-mobile) {
      gap: variables.$gutter-16;
    }
    @include breakpoints.screen(desktop-and-up) {
      padding-top: 0;
    }
  }

  &__signs {
    font-size: typography.to-rem(183px);
    font-family: typography.$ff-secondary;
    font-style: normal;
    font-weight: typography.$font-weight-400;
    line-height: 160px;
    letter-spacing: -0.408px;
    text-decoration: none;
    height: variables.$gutter-80;
    @include gradient.titleGradient;

    @include breakpoints.screen(only-mobile) {
      font-size: typography.to-rem(140px);
      line-height: 1.1;
      margin-top: -21px;
      height: 112px;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: variables.$gutter-24;
    min-width: auto;

    @include breakpoints.screen(desktop-and-up) {
      min-width: 80%;
    }
  }

  &__author {
    color: colors.$gray-80;

    &--link {
      @include mixins.setFontStyles(typography.$font-size-20, normal, 400, underline);

      @include breakpoints.screen(only-mobile) {
        font-size: typography.$font-size-16;
      }
    }

    &--no-link {
      @include mixins.setFontStyles(typography.$font-size-20, normal, 400, none);
      @include mixins.disable-link-click;

      @include breakpoints.screen(only-mobile) {
        font-size: typography.$font-size-16;
      }
    }
    @include breakpoints.screen(tablet-and-up) {
      display: flex;
      align-items: center;
      gap: variables.$gutter-16;
    }
  }

  &__text {
    overflow: hidden;
  }

  &__line {
    width: 88px;
    min-width: 88px;
    display: flex;
    align-items: center;
    @include breakpoints.screen(only-mobile) {
      height: 2px;
      margin-bottom: variables.$gutter-16;
    }

    &::after {
      background: colors.$gray-80;
      width: 100%;
      height: 2px;
      content: '';
    }
  }
}
