@use '../../sass/abstract/colors';
@use '../../sass/abstract/border';
@use '../../sass/abstract/breakpoints';
@use '../../sass/base/typography';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/layout/layout';

#footer {
  background-color: colors.$gray-light;

  strong {
    @include typography.body1;
  }

  .container.color-grey-30 {
    @include layout.component-with-paddings;

    .container.container {
      padding: 0;
    }
  }

  .row {
    margin: 0;
    justify-content: flex-start;

    > * {
      padding-left: 0;
      padding-right: 0;
    }
  }

  p {
    text-align: center;
    margin-bottom: 0;
  }

  .row.indent-bottom {
    flex-direction: column;
  }

  .container-fluid {
    padding: 0;

    &.position-center.indent-bottom {
      .row {
        flex-direction: column;
      }

      .rich-text div p {
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: variables.$gutter-20;
      }
    }
  }

  .position-left {
    .next-image__container {
      width: 146px;
      height: 57px;
      margin-left: 0;
    }
  }

  .position-right {
    justify-content: flex-end;
    display: flex;
    padding: 0;

    >div:has(.color-avanade-orange){
      display: inherit;
    }
  }

  .col-sm-12.col-md-4.col-xxl-3 {
    margin: variables.$gutter-24 0 variables.$gutter-24 0;
    width: 100%;
    max-width: 100%;

    &:first-child {
      margin: 0;
    }

    @include breakpoints.screen(tablet-and-up) {
      margin: 0 variables.$gutter-12;
      max-width: calc((100% - 48px) / 3);

      &:first-child {
        margin: 0 variables.$gutter-12 0 0;
      }
    }

    @include breakpoints.screen(desktop-and-up) {
      max-width: 264px;
    }
  }

  .col-sm-12.col-md-4.col-xxl-6.position-right {
    margin-left: auto;

    @include breakpoints.screen(only-mobile) {
      margin-top: variables.$gutter-48;
      margin-left: 0;
      justify-content: center;
    }

    @include breakpoints.screen(tablet-and-up) {
      max-width: calc((100% - 48px) / 3);
      margin-left: variables.$gutter-12;
    }

    @include breakpoints.screen(desktop-and-up) {
      margin-left: auto;
      max-width: 100%;
    }
  }

  .color-avanade-orange {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: colors.$orange;

    * {
      margin-bottom: 0;
    }
  }

  .field-link,
  .link__footer {
    margin: 0 0 variables.$gutter-16;

    a {
      &:hover {
        text-decoration: underline;
      }

      &:active {
        color: colors.$dark-pink;
        text-decoration: underline;
      }
    }
  }

  .link {
    @include mixins.setFontStyles(typography.to-rem(20px), normal, 700, none);
    margin-bottom: variables.$gutter-8;
    display: block;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: colors.$dark-pink;
      text-decoration: underline;
    }
  }

  .link.link__footer-child {
    margin-bottom: variables.$gutter-8;
    font-weight: typography.$font-weight-400;
    display: block;
    font-size: typography.$font-size-16;
    line-height: typography.$line-height-24;

    &::after {
      content: none;
    }

    &:last-of-type {
      margin-bottom: variables.$gutter-40;
    }
  }

  .link-list {
    @include breakpoints.screen(tablet-and-up) {
      margin-bottom: variables.$gutter-40;
    }
  }

  .list-list__horizontal {
    &:last-child {
      margin-bottom: variables.$gutter-40;
    }

    @include breakpoints.screen(only-mobile) {
      margin-bottom: variables.$gutter-24;

      &:last-child {
        margin-bottom: variables.$gutter-16;
      }
    }

    ul {
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      margin: 0 auto;

      @include breakpoints.screen(desktop-and-up) {
        max-width: 720px;
      }

      @include breakpoints.screen(only-mobile) {
        flex-direction: column;
        align-items: center;
        gap: variables.$gutter-16;
      }

      li {
        white-space: nowrap;

        @include breakpoints.screen(tablet-and-up) {
          margin-right: variables.$gutter-12;
        }

        &:last-child {
          margin-right: 0;
        }

        @include breakpoints.screen(only-mobile) {
          line-height: typography.$line-height-24;
        }

        .field-link {
          margin: 0;

          a {
            @include typography.body2;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .social-icons-container {
    .row {
      display: flex;
      gap: 6px;
    }

    @include breakpoints.screen(only-mobile) {
      width: 100%;
      margin-bottom: variables.$gutter-40;
    }

    .next-image__container {
      width: 37px;
      height: 37px;
    }
  }
}
