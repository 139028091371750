@use '../../../../sass/abstract/mixins';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/z-index';
@use '../../../../sass/abstract/blur';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/base/waves';

.section-hero {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  background: colors.$white;

  &__content {
    width: 100%;
    max-width: variables.$max-container-width;
    margin: 0 auto;
    padding: 0 variables.$gutter-24;
    display: flex;
    justify-content: center;
    padding-bottom: 30%;
    z-index: z-index.$z-index-10;
    padding-top: variables.$gutter-40;

    @include breakpoints.screen(tablet-and-up) {
      padding-bottom: 198px;
      padding-top: variables.$gutter-80;
    }
    @include breakpoints.screen(desktop-and-up) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &__title, &__tag {
    margin-bottom: variables.$gutter-16;
    text-align: center;
    width: 100%;
    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
    }
  }

  &__paragraph {
    margin-bottom: variables.$gutter-16;
    text-align: center;

    @include breakpoints.screen(desktop-and-up) {
      width: 100%;
      text-align: left;
    }
  }

  &__link {
    margin-bottom: variables.$gutter-40;
  }

  &__text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: z-index.$z-index-20;

    @include breakpoints.screen(desktop-and-up) {
      margin-right: variables.$gutter-120;
      padding-top: 54px;
      align-items: flex-start;
    }
  }

  &__image-container {
    display: none;
    @include breakpoints.screen(desktop-and-up) {
      display: block;
      height: 720px;
      min-height: 720px;
      min-width: 360px;
      width: 360px;
      max-width: 360px;

      img{
        position: relative !important;
      }
    }
  }

  .search-field__container{
    margin-top:0;
    margin-bottom: variables.$gutter-40;
    width: 100%;
    @include breakpoints.screen(tablet-and-up){
      width: 359px;
    }
  }
}
