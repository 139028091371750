@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use './../../sass/abstract/gradient';
@use '../../sass/abstract/shadows';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/z-index';
@use '../../sass/layout/layout';

$item-height: 32px;

.jump-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .jump-links {
    width: 100%;

    @include layout.component-with-paddings;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    background-color: colors.$white;
    padding-top: variables.$gutter-40;
    padding-bottom: variables.$gutter-40;

    &--sticky {
      position: fixed;
      z-index: z-index.$z-index-70;
      padding-right: variables.$gutter-24;
      padding-left: variables.$gutter-24;
      padding-top: variables.$gutter-20;
      padding-bottom: variables.$gutter-20;
      left: 0;
      margin: 0;
      width: 100%;
      @include shadows.drop-shadow-5;
      top: variables.$sm-header-height;

      @include breakpoints.screen(desktop-and-up) {
        top: variables.$header-height;
      }
    }

    &__list {
      max-width: variables.$L-width;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      row-gap: variables.$gutter-16;

      @include breakpoints.screen(only-mobile) {
        row-gap: variables.$gutter-12;
      }
    }

    &__list-item {
      height: $item-height;
      position: relative;

      &:not(:last-child) {
        &::after {
          content: '';
          width: 1px;
          height: $item-height;
          background-color: colors.$gray-20;
          position: absolute;
          right: 0;
        }
      }

      &:first-child {
        a {
          margin-left: 0;
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }

      &--active {
        .jump-links__link {
          &:hover {
            color: colors.$gray-80;
            background: none;
            -webkit-text-fill-color: colors.$gray-80;
          }

          &::before {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            display: block;
            width: 100%;
            height: 4px;
            @include gradient.horizontal-aurora-background;
          }
        }
      }
    }

    &__link {
      text-decoration: none;
      color: colors.$gray-80;
      margin: 0 variables.$gutter-24;
      position: relative;

      &:hover {
        text-decoration: none;
      }

      @include breakpoints.screen(desktop-and-up) {
        &:hover {
          text-decoration: none;
          @include gradient.titleGradient;
        }
      }
    }
  }
}
