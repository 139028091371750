@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/z-index';
@use '../../../sass/abstract/shadows';
@use '../../../sass/base/typography';
@use '../../../sass/base/icons';
@use '../../../sass/vendors/atomic/atomic-search-box.scss';

.poc-page {
  @include atomic-search-box.atomic-search-box--tertiary;
  padding-top: variables.$gutter-40;

  &__search-input {
    margin-bottom: variables.$gutter-40;
  }

  &__filters-container {
    padding-top: variables.$gutter-40;
  }

  &__custom-facet-container {
    margin-bottom: variables.$gutter-24;

    @include breakpoints.screen(tablet-and-up) {
      padding-right: variables.$gutter-24;

      &:last-child,
      &:nth-child(2) {
        padding-right: 0;
      }
    }
    @include breakpoints.screen(desktop-and-up) {
      &:nth-child(2) {
        padding-right: variables.$gutter-24;
      }
    }
  }

  &__filters-title {
    margin-bottom: variables.$gutter-16;
  }

  &__filters-box {
    display: flex;
  }
}
