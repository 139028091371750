@use '../../../../sass/abstract/colors';
@use '../../../../sass/base/waves';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/z-index';
@use '../../../../sass/abstract/blur';

.homepage-hero-square-image {
  position: relative;
  background: colors.$white;

  @include breakpoints.screen(desktop-and-up) {
    margin-bottom: variables.$gutter-24;
  }

  @include breakpoints.screen(only-large-desktop) {
    margin-bottom: variables.$gutter-40;
  }

  &__content {
    margin: 0 auto;
    position: relative;
    z-index: z-index.$z-index-30;
  }

  &__blurBG {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    @include blur.blur-back-10;
    z-index: z-index.$z-index-10;

    @include breakpoints.screen(desktop-and-up) {
      @include blur.blur-back-20;
    }
  }

  &__title {
    text-align: center;
    max-width: 100%;
    margin-bottom: variables.$gutter-24;
    @include gradient.titleGradient;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
    }
  }

  &__tag {
    text-align: center;
    max-width: 100%;
    margin-bottom: variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
    }
  }

  &__paragraph {
    text-align: center;
    width: 100%;
    margin-bottom: variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      padding-right: variables.$gutter-24;
      margin: 0;
      align-items: flex-start;
    }
  }

  &__image-box {
    margin: 0 auto;
    min-height: 327px;
    @include breakpoints.screen(tablet-and-up) {
      min-height: 472px;
    }

    @include breakpoints.screen(desktop-and-up) {
      min-height: 648px;
    }
  }

  &__link {
    width: 100%;
    @include breakpoints.screen(tablet-and-up) {
      width: auto;
    }
  }
}
