@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/z-index';

.container-gray-background {
  .card {
    background-color: colors.$white;
  }
}

.cards--default {
  &__container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__title {
    text-align: center;
    margin-bottom: 40px;
    flex-basis: 100%;
  }

  &__cta-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }

  &__card-container {
    &:last-child {
      margin-right: auto;
    }

    &:first-child {
      margin-left: auto;
    }
    
    &:empty {
      display: none;
    }
  }

  &__cards-container {
    display: grid;
    gap: variables.$gutter-24;
    z-index: z-index.$z-index-50;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    margin-inline: -(variables.$gutter-24);
    padding: 0 variables.$gutter-24;

    @include mixins.scrollable-container;
    scrollbar-width: none;
    @include breakpoints.screen(only-mobile) {
      grid-auto-flow: column;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin-inline: 0;
      padding: 0;
    }
  }
}

.single-card-container {
  .card:not(:first-of-type) {
    display: none;
  }
  
  &:empty{
    display: none;
  }
}