@use './../abstract/breakpoints';
@use './../abstract/colors';
@use './../abstract/mixins';
@use './../base/typography';
@use './../abstract/border-radius';
@use './../abstract/border';
@use './../abstract/gradient';
@use './../abstract/variables';

@mixin link {
  font-size: typography.$font-size-14;
  line-height: typography.$line-height-16;
  font-weight: typography.$font-weight-600;
  white-space: nowrap;
  color: colors.$gray-80;
  display: inline-block;

  &:visited {
    color: colors.$gray-80;
  }
}

@mixin button-primary {
  text-align: center;
  padding: variables.$gutter-12 variables.$gutter-24;
  background: colors.$gray-80;
  color: colors.$white;
  font-size: typography.$font-size-14;
  line-height: typography.$line-height-16;
  min-height: variables.$gutter-40;
  border-radius: border-radius.$border-radius-28;
  text-decoration: none;
  font-weight: typography.$font-weight-600;
  white-space:break-spaces;


  &:focus-visible {
    outline-offset: 1px;
  }

  &:visited {
    color: colors.$white;
  }

  &:hover {
    font-weight: typography.$font-weight-600;
    background: colors.$gray-70;
  }

  &:active {
    background: colors.$dark-pink;
    color: colors.$white;
  }

  a {
    color: colors.$white;
    text-decoration: none;
  }
}

@mixin button-primary-disabled {
  background: colors.$gray-20;
  color: colors.$gray-60;
  @include mixins.disable-link-click;

  &:visited {
    color: colors.$gray-60;
  }
}

@mixin button-secondary {
  padding: variables.$gutter-12 variables.$gutter-16;
  background: colors.$white;
  border: border.$border-1-solid-gray80;
  color: colors.$gray-80;
  font-size: typography.$font-size-14;
  line-height: typography.$line-height-16;
  height: 40px;
  border-radius: border-radius.$border-radius-28;
  text-decoration: none;
  font-weight: typography.$font-weight-600;
  text-align: center;

  &:visited {
    color: colors.$gray-80;
  }

  &:hover {
    font-weight: typography.$font-weight-600;
    box-shadow: 0 0 0 1px colors.$gray-80 inset;
  }

  &:active {
    box-shadow: none;
    border: border.$border-1-solid-dark-pink;
    color: colors.$dark-pink;
  }
}

@mixin button-secondary-disabled {
  background: colors.$gray-light;
  border: border.$border-1-solid-gray20;
  color: colors.$gray-70;
  @include mixins.disable-link-click;

  &:visited {
    color: colors.$gray-70;
  }
}

@mixin button-tertiary {
  white-space: normal;
  color: colors.$gray-80;
  display: flex;
  text-decoration: none;
  @include typography.body1;

  &:visited {
    color: colors.$gray-80;
  }

  &:hover {
    font-weight: typography.$font-weight-700;
    @include gradient.titleGradient;

    .link__button-tertiary-arrow {
      display: none;
    }

    .link__button-tertiary-arrow-active {
      display: flex;
    }
  }

  .link__button-tertiary-text {
    display: flex;
    align-items: flex-start;
  }

  .link__button-tertiary-arrow-active {
    display: none;
  }

  .link__button-tertiary-image-container {
    margin-left: variables.$gutter-8;
  }
}
