// keyframes
@keyframes opacity-up {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes fade-in-up {
  0% {
    transform: translate3d(0, 48px, 0);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}
@keyframes fade-in-down {
  0% {
    opacity: 0;
    transform: translate3d(0, -48px, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}
@keyframes fade-zoom {
  0% {
    opacity: 0;
    transform: scale3d(1.05, 1.05, 1);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

// mixins
@mixin opacity-up {
  animation-name: opacity-up;
  animation-duration: 2s;
}
@mixin fade-in-up {
  animation-name: fade-in-up;
  animation-duration: 1s;
}
@mixin fade-in-down {
  animation-name: fade-in-down;
  animation-duration: 2s;
}
@mixin fade-zoom {
  animation-name: fade-zoom;
  animation-duration: 1.5s;
}
@mixin image-zoom-in {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}
@mixin image-zoom-out {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
// height 0 - 100%
@mixin animate-height-closed {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
}
@mixin animate-height-open {
  grid-template-rows: 1fr;
}
@mixin animate-height-hidden {
  overflow: hidden;
}

// classes
.animation {
  &--opacity-up {
    @include opacity-up;
  }

  &--fade-in-up {
    @include fade-in-up;
  }

  &--fade-in-down {
    @include fade-in-down;
  }

  &--fade-zoom {
    @include fade-zoom;
  }

  &--image-zoom-in {
    @include image-zoom-in;
  }

  &--image-zoom-out {
    @include image-zoom-out;
  }

  &--animate-height-closed {
    @include animate-height-closed;
  }

  &--animate-height-open {
    @include animate-height-open;
  }

  &--animate-height-hidden {
    @include animate-height-hidden;
  }
}
