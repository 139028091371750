@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/breakpoints';

.timage-left-circle {
  &__header {
    text-align: center;
    @include gradient.titleGradient;
    margin-bottom: variables.$gutter-40;

    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: variables.$gutter-80;
    }

    @include breakpoints.screen(only-mobile) {
      display: none;
    }

    &-mobile {
      @include gradient.titleGradient;
      margin-bottom: variables.$gutter-24;
      @include breakpoints.screen(tablet-and-up) {
        display: none;
      }
    }
  }

  &__subtitle {
    margin-bottom: variables.$gutter-16;
    
    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: variables.$gutter-24;
    }
  }

  &__image-container {
    margin-bottom: variables.$gutter-24;
 
    @include breakpoints.screen(tablet-and-up) {
      margin-bottom: 0;
      margin-right: variables.$gutter-12;
    }
  }
 
  &__text-container {
    @include breakpoints.screen(tablet-and-up) {
      padding-left: variables.$gutter-12;
    }
  }

  &__link {
    margin-top: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-top: variables.$gutter-24;
    }
  }
}
