@use '../../sass/abstract/colors';
@use '../../sass/abstract/border';
@use './../../sass/abstract/gradient';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/border-radius';
@use '../../sass/abstract/variables';
@use '../../sass/base/typography';

.event {
  width: 264px;
  max-width: 264px;
  min-height: 434px;
  background-color: colors.$gray-light;
  border-radius: border-radius.$border-radius-30;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: variables.$gutter-24;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  border: border.$border-3-solid-transparent;
  @include breakpoints.screen(tablet-and-up) {
    width: 264px;
    min-height: 464px;
  }

  &:hover {
    border: border.$border-3-solid-transparent;
    @include gradient.border-gradient;
    background-color: colors.$white;
    text-decoration: none;
    cursor: pointer;

    .link {
      @include gradient.titleGradient;

      .link__button-tertiary-arrow {
        display: none;
      }

      .link__button-tertiary-arrow-active {
        display: flex;
      }
    }
  }

  &__top-section {
    margin-bottom: variables.$gutter-24;
    display: flex;
    min-height: 234px;
    flex-direction: column;
    gap: variables.$gutter-16;

    &.no-min-height {
      min-height: auto;
    }

    &:empty {
      margin-bottom: 0;
    }

    div {
      &:empty {
        display: none;
      }
    }
  }

  &__bottom-section {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 156px;
    @include breakpoints.screen(tablet-and-up) {
      min-height: 180px;
    }
    @include breakpoints.screen(desktop-and-up) {
      min-height: 186px;
    }

    .link__button-tertiary {
      margin-top: auto;
    }
  }

  &__featured {
    gap: variables.$gutter-8;
    background: colors.$dark-pink;
    color: colors.$white;
    padding: variables.$gutter-8 variables.$gutter-8 variables.$gutter-8 variables.$gutter-8;
    border-radius: border-radius.$border-radius-5;
    display: flex;
    align-items: center;
    min-height: variables.$gutter-32;
    max-width: 171px;
    @include breakpoints.screen(desktop-and-up) {
      max-width: 165px;
      justify-content: space-between;
    }
  }

  &__date {
    text-transform: capitalize;

    :lang(es-es) & {
      text-transform: none;
    }

    &.ja-jp-event-date {
      width: auto;
    }
  }

  &__year {
    margin-left: 0.5rem;
  }

  &__title {
    margin-bottom: variables.$gutter-16;
  }

  &__description {
    margin-bottom: variables.$gutter-16;
  }

  &__date-container {
    display: flex;
    flex-wrap: wrap;
  }

  .today {
    display: block;
  }

  &__type {
    display: block;
  }

  &__location {
    display: block;
  }
}

.prod-mode .event {
  &__date {
    text-transform: none;
  }
}

.editing-mode .event {
  &__date {
    text-transform: none;
  }
}
