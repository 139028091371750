@use '../../sass/base/typography';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/mixins';

.field-content{
    div {
       h5 {
          margin: variables.$gutter-16 0;
          float: left;
          width: 100%;

          &:first-child{
            margin-top: 0
          }
        }

        h3{
          margin-bottom: variables.$gutter-40;
        }
    
        p {
          font-size: typography.$font-size-16;
          line-height: typography.$line-height-24;
          margin-bottom: variables.$gutter-24;

          &:has(strong){
            margin-bottom: 0;
          }
          
        }
    
        ol {
          list-style: decimal;
          margin-bottom: variables.$gutter-24;
        }
    
        ul {
          list-style: disc;
          margin-bottom: variables.$gutter-24;
        }
    
        li {
          font-size: typography.$font-size-16;
          line-height: typography.$line-height-32;
          margin-left: variables.$gutter-20;

          &::marker {
            font-weight: typography.$font-weight-700;
          }

          a {
            margin-bottom: 0;
          }
        }
    
        a {
          margin-bottom: variables.$gutter-16;
        }
    
        strong {
          font-size: typography.$font-size-16;
          line-height: typography.$line-height-24;
          font-weight: typography.$font-weight-700;
        }
    
        u {
          font-weight: typography.$font-weight-400;
          font-size: typography.$font-size-16;
          line-height: typography.$line-height-24;
          text-decoration: underline;
        }
    
        em {
          font-weight: typography.$font-weight-400;
          font-size: typography.$font-size-16;
          line-height: typography.$line-height-24;
          font-style: italic;
        }
      }
}