@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/abstract/border';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/variables';

atomic-load-more-results {
  &::part(progress-bar) {
    display: none;
  }

  &::part(load-more-results-button) {
    // secondary button styles
    padding: variables.$gutter-12 variables.$gutter-16;
    background: colors.$white;
    border: border.$border-1-solid-gray80;
    color: colors.$gray-80;
    font-size: typography.$font-size-14;
    line-height: typography.$line-height-16;
    height: 40px;
    border-radius: border-radius.$border-radius-28;
    text-decoration: none;
    font-weight: typography.$font-weight-600;
    margin-top: 0;
    margin-bottom: variables.$gutter-40;

    &:visited {
      color: colors.$gray-80;
    }

    &:hover {
      font-weight: typography.$font-weight-600;
      box-shadow: 0 0 0 1px colors.$gray-80 inset;
    }

    &:active {
      box-shadow: none;
      border: border.$border-1-solid-dark-pink;
      color: colors.$dark-pink;
    }
  }

  &::part(showing-results) {
    display: none;
  }
}

