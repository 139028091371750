@use '../../../../sass/abstract/colors';

.container-grey-background {
  .full-bleed-banner-mirror {
    background: colors.$gray-light;
  }

  .full-bleed-banner-mirror__text-container {
    .full-bleed-banner-mirror__title , 
    .full-bleed-banner-mirror__tag {
      color: colors.$gray-80;
    }
  }

  .full-bleed-banner-mirror__text {
    color: colors.$gray-80;
  }
}
