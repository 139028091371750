@use '../abstract/variables';
@use '../abstract/gradient';
@use '../abstract/colors';
@use '../abstract/shadows';
@use '../abstract/breakpoints';

.position-left {
  text-align: left !important;
}

.position-center {
  text-align: center !important;
}

.position-right {
  text-align: right !important;
}

.center-text {
  text-align: center !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.drop-shadow-5-class {
  @include shadows.drop-shadow-5;
}

.drop-shadow-20-class {
  @include shadows.drop-shadow-20;
}

.text-shadow-class {
  @include gradient.titleGradient;
}

.color-grey-80 {
  color: colors.$gray-80 !important;
}

.s-gradient-text {
  @include gradient.titleGradient;
}

.s-editing-mode-hint {
  background-color: colors.$status-amber;
  text-align: center;
}

.s-editing-mode-section-hint {
  background-color: colors.$blue;
  text-align: center;
}

.s-padding-top-24 {
  padding-top: variables.$gutter-24 !important;
}

.s-padding-top-40 {
  padding-top: variables.$gutter-40 !important;
}

.s-padding-bottom-24 {
  padding-bottom: variables.$gutter-24 !important;
}

.s-padding-bottom-40 {
  padding-bottom: variables.$gutter-40 !important;
}

.s-padding-top-16 {
  padding-top: variables.$gutter-16;
}

.s-padding-bottom-16 {
  padding-bottom: variables.$gutter-16;
}
