@use '../../../sass/abstract/breakpoints';

.disabled {
  pointer-events: none;
}

.welcome-savedjobs-text-container {
  display: flex;
  width: 100%;
  padding: 16px 0;
  align-items: center;
  justify-content: space-between;
  gap: 42px;
  margin: 0 auto;
  @include breakpoints.screen(tablet-and-up) {
    width: 100%;
  }
  @include breakpoints.screen(desktop-and-up) {
    width: 100%;
  }
  @include breakpoints.screen(only-mobile) {
    width: 100%;
    flex-direction: column;
    gap: 0;
  }

  a {
    @include breakpoints.screen(only-mobile) {
      margin-bottom: 40px;
    }
  }

  .back-text {
    a {
      align-items: center;
    }
  }
}

.welcometext {
  color: var(--primary-colours-grey-avanade-gray-80, #333);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0 21px;
}

.savedjobtext {
  color: var(--primary-colours-grey-avanade-gray-80, #333);
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  padding: 0 21px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  @include breakpoints.screen(only-mobile) {
    position: relative;
    top:3px;
  }
}

.savedjobcount{
  @include breakpoints.screen(only-mobile) {
    display: flex;
    align-items: center;
  }
}

.welcome-savedjob-border {
  display: inline-flex;

  a {
    text-decoration: none;
  }
  @include breakpoints.screen(only-mobile) {
    justify-content: center;
    align-items: center;
  }

  .highlight-savejob a {
    .savedjobtext {
      font-weight: 700;
      border-bottom: 0;
    }

    &::after {
      position: relative;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 4px;
      background: linear-gradient(90deg, #ce0569, #ff5800);
    }
  }
}

.back-text a {
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;

  .link__button-tertiary-image-container {
    margin-left: 0;
  }

  .icon::before {
    transform: rotate(180deg);
  }
}
