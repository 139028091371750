@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/mixins.scss';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/z-index';
@use '../../../../sass/abstract/variables';

$lg-tablet-card-width: 552px;
$desktop-card-width: 784px;

.full-bleed-hero {
  max-width: 1728px;
  margin: 0 auto;
  height: 100%;
  position: relative;

  &.full-bleed-hero--revert {
    .full-bleed-hero__box {
      @include breakpoints.screen(tablet-and-up) {
        margin: variables.$gutter-40 variables.$gutter-40 variables.$gutter-40 auto;
      }
      @include breakpoints.screen(desktop-and-up) {
        margin: variables.$gutter-80 variables.$gutter-80 variables.$gutter-80 auto;
      }
    }
  }

  &__container {
    position: relative;
  }

  &__content {
    height: auto;
    margin: auto 0;
    display: flex;
    align-items: end;
    justify-content: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: relative;

    @include breakpoints.screen(tablet-and-up) {
      min-height: 425px;
    }
    @include breakpoints.screen(desktop-and-up) {
      min-height: 740px;
    }
  }

  &__box {
    background-color: colors.$white;
    padding: variables.$gutter-40 variables.$gutter-24;
    width: 100%;
    z-index: z-index.$z-index-10;
    max-width: 100vw;

    @include breakpoints.screen(only-mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @include breakpoints.screen(tablet-and-up) {
      padding: variables.$gutter-24;
      min-width: $lg-tablet-card-width;
      width: $lg-tablet-card-width;
      margin: variables.$gutter-24 auto variables.$gutter-24 variables.$gutter-24;
    }

    @include breakpoints.screen(desktop-and-up) {
      min-width: $desktop-card-width;
      width: $desktop-card-width;
      padding: variables.$gutter-40;
      margin: variables.$gutter-80 auto variables.$gutter-80 variables.$gutter-80;
    }
  }

  &__title-box {
    @include breakpoints.screen(only-mobile) {
      text-align: center;
    }
  }

  &__text {
    margin-top: variables.$gutter-24;
    @include breakpoints.screen(only-mobile) {
      text-align: center;
    }
  }

  &__title {
    text-align: center;
    @include breakpoints.screen(tablet-and-up) {
      text-align: left;
    }

    :lang(de-de) & {
      hyphens: auto;
      word-break: break-word;
    }
  }

  &__tag {
    text-align: center;
    margin-bottom: variables.$gutter-24;
    @include breakpoints.screen(tablet-and-up) {
      text-align: left;
    }
  }

  &__link {
    margin-top: variables.$gutter-24;
  }

  &__img-container {
    width: 100%;
    height: auto;

    @include breakpoints.screen(tablet-and-up) {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: z-index.$z-index-0;
    }

    img {
      @include breakpoints.screen(only-mobile) {
        position: relative !important;
      }
    }
  }
}

.image-overlay {
  position: relative;
  overflow: hidden;
}

.image-overlay::before {
  content: '';
  border-radius: 1499px;
  opacity: 0.5;
  background: radial-gradient(50% 50% at 50% 50%, #ff5800 0%, rgba(255, 88, 0, 0) 100%);
  position: absolute;
  width: 1090px;
  height: 1499px;
  flex-shrink: 0;
  z-index: 9;
  right: -15%;
  top: 3px;
  @include breakpoints.screen(only-mobile) {
    width: 234.375px;
    height: 419.886px;
    border-radius: 419.886px;
    right: -10%;
    top: 53%;
  }
  @include breakpoints.screen(tablet-and-up) {
    width: 480px;
    height: 859.927px;
    border-radius: 859.927px;
    right: 0;
  }
}
