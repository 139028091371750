@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/base/typography';
@use '../../../../sass/abstract/colors';

.timage-right-with-quote {
  h3.timage-right-with-quote__header {
    @include gradient.titleGradient;
  }

  &__header {
    margin-top: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-top: variables.$gutter-24;
    }
  }

  &__subtitle {
    margin-bottom: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: variables.$gutter-24;
    }
  }

  &__image-container {
    margin-bottom: variables.$gutter-24;

    @include breakpoints.screen(tablet-and-up) {
      margin-bottom: 0;
      margin-left: variables.$gutter-24;
    }

    @include breakpoints.screen(desktop-and-up) {
      margin-left: variables.$gutter-12;
    }
  }

  &__link {
    margin-top: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-top: variables.$gutter-24;
    }
  }

  &__line {
    width: 88px;
    min-width: 88px;
    display: flex;
    align-items: center;
    height: 2px;
    background-color: colors.$gray-80;
    margin-right: variables.$gutter-16;
  }

  &__author-container {
    display: flex;
    align-items: center;
    margin-top: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-top: variables.$gutter-24;
    }
  }

  &__author {
    display: inline-block;
    width: 100%;
  }

  &__button {
    margin-top: variables.$gutter-16;

    @include breakpoints.screen(desktop-and-up) {
      margin-top: variables.$gutter-24;
    }
  }

  &__text-column {
    @include breakpoints.screen(only-mobile) {
      order: 2;
    }
    
    @include breakpoints.screen(desktop-and-up) {
      padding-right: variables.$gutter-12;
    }
  }

  &__image-column {
    @include breakpoints.screen(only-mobile) {
      order: 1;
    }
  }
}
