@use '../../sass/abstract/colors';
@use '../../sass/abstract/border';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/z-index';
@use '../../sass/abstract/border-radius';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/breakpoints';
 
.cardByTag-card {
  width: 264px;
  max-width: 264px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: variables.$gutter-24;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  color: colors.$gray-80;
  border: border.$border-3-solid-transparent;

  &.card {
    min-width: 264px;
    min-height: 464px;
    height: 100%;
    background-color: colors.$gray-light;
    padding: variables.$gutter-24;
    border-radius: border-radius.$border-radius-30;
    flex: 1 0 264px;
  }
 
  &:hover {
    cursor: pointer;
  }
 
  &__default-container {
    &:hover {
      border: border.$border-3-solid-transparent;
 
      text-decoration: none;
      @include gradient.border-gradient;
 
      .insight-card__title {
        @include gradient.titleGradient;
      }
 
      .link {
        @include gradient.titleGradient;
 
        .link__button-tertiary-arrow {
          display: none;
        }
 
        .link__button-tertiary-arrow-active {
          display: flex;
        }
      }
    }
  }
 
  &__tag {
    margin-bottom: variables.$gutter-20;
  }
 
  &__title {
    margin-bottom: variables.$gutter-20;
  }
 
  &__icon {
    max-width: 24px;
    float: left;
    margin-right: variables.$gutter-10;
  }
 
  &__body {
    margin-bottom: variables.$gutter-20;
 
    &:hover {
      text-decoration: none;
    }
  }
}
 
.cards--default {
  &__container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
  }
 
  &__title {
    text-align: center;
    margin-bottom: 40px;
    flex-basis: 100%;
  }
 
  &__cta-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }
 
  &__card-container {
    &:last-child {
      margin-right: auto;
    }
 
    &:first-child {
      margin-left: auto;
    }
  }
 
  &__cards-container {
    display: grid;
    gap: variables.$gutter-24;
    z-index: z-index.$z-index-50;
    grid-auto-flow: column;
    grid-auto-columns: auto;
 
    @include mixins.scrollable-container;
    scrollbar-width: none;
 
    @include breakpoints.screen(only-mobile) {
      grid-auto-flow: column;
    }
  }
}