@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/mixins';
@use '../../../../sass/abstract/shadows';
@use '../../../../sass/base/typography';
@use '../../../../sass/abstract/variables';

.carousel-card {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include breakpoints.screen(desktop-and-up) {
    height: auto;
  }

  &--hide {
    visibility: hidden;
  }

  &__main-box {
    height: 100%;

    @include breakpoints.screen(desktop-and-up) {
      height: auto;
    }
  }

  &__container {
    max-width: 768px;
    height: 100%;
    padding: variables.$gutter-40 variables.$gutter-24;
    background-color: colors.$white;
    @include shadows.drop-shadow-5;

    &--small-card {
      width: 350px;
      opacity: 0.9;

      .carousel-card__element-margin {
        margin-bottom: variables.$gutter-24;
        font-size: typography.$font-size-14;
        line-height: typography.$line-height-16;
        font-weight: typography.$font-weight-600;
      }
    }

    @include breakpoints.screen(desktop-and-up) {
      width: 100%;
      max-width: 552px;
      padding: variables.$gutter-40;
    }
  }

  &__element-margin {
    margin-bottom: variables.$gutter-24;
  }

  &__area {
    letter-spacing: 0;
    line-height: typography.$line-height-16;
    font-size: typography.$font-size-14;
    font-weight: typography.$font-weight-600;
  }

  &__logo-container {
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    max-width: 202px;
    width: 202px;
    min-width: 202px;
  }

  &__title {
    &--small-card {
      font-size: typography.$font-size-24;
      font-weight: typography.$font-weight-700;
      line-height: normal;
    }
  }
}

.editing-mode {
  .carousel-card{
    &__title {
      opacity: 1 !important;
      visibility: visible !important;
    }
  }
}
