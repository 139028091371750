@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/z-index';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/border';

.search-facet {
  &--empty {
    .search-facet__container {
      cursor: default;
      background: colors.$gray-light;
      color: colors.$gray-30;
    }
  }

  &--selected {
    .search-facet__container {
      @include border.aurora-search-dropdown-border;

      .search-facet__list {
        @include border.aurora-search-dropdown-list-border;
      }
    }
  }

  &__container {
    border-radius: border-radius.$border-radius-20;
    padding: variables.$gutter-8 variables.$gutter-16;
    display: flex;
    align-items: center;
    background: colors.$white;
    border: border.$border-1-solid-gray30;
    margin-bottom: variables.$gutter-32;
    position: relative;
    cursor: pointer;
    margin: 0 variables.$gutter-12;
  }

  .full-width {
    width: 100% !important;
    @include breakpoints.screen(tablet-and-up) {
      width: auto !important;
      margin: 0 variables.$gutter-12 !important;
    }
    @include breakpoints.screen(desktop-and-up) {
      width: 100% !important;
      margin: 0 !important;
    }
  }

  .AOE-margin{
    @include breakpoints.screen(tablet-and-up) {
      margin: 0 variables.$gutter-12 !important;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin: 0 !important;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .icon.icon--Location,
  .icon--mapicon{
    margin-right:variables.$gutter-8;
    display: block !important;
  }

  &__list {
    position: absolute;
    max-height: 272px;
    overflow-y: auto;
    overflow-x: hidden;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: variables.$gutter-4;
    background-color: colors.$white;
    border-radius: border-radius.$border-radius-20;
    border: border.$border-1-solid-gray30;
    z-index: z-index.$z-index-10;

    &::-webkit-scrollbar {
      width: 30px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 26px 26px transparent;
      border: solid 26px transparent;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 26px 26px colors.$gray-20;
      border: solid 12px transparent;
      border-radius: 26px;
    }

    &::-webkit-scrollbar-thumb:vertical {
      height: variables.$gutter-80 + variables.$gutter-20;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: variables.$gutter-8 variables.$gutter-16;

    &:hover {
      background-color: colors.$gray-light;
    }

    &:first-child {
      padding-top: variables.$gutter-16;
    }

    &:last-child {
      padding-bottom: variables.$gutter-16;
    }
  }
}
