@use '../../sass/abstract/colors';
@use './../../sass/abstract/gradient';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/variables';

.scrolling-accordion {
  position: relative;
  display: none;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    visibility: hidden;
  }

  @include breakpoints.screen(desktop-and-up) {
    display: flex;
  }

  &__header {
    @include gradient.titleGradient;
    margin-bottom: variables.$gutter-40;
    text-align: center;

    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: variables.$gutter-80;
      text-align: left;
    }
  }

  .inView-container {
    flex: 1;

    :target{
      scroll-margin-top: 220px;
    }
  }

  &__left-container {
    height: fit-content;
    position: sticky;
    top: 80px;
    flex: 1;
  }

  &__slider {
    height: 95vh;
    position: sticky;
    top: 80px;
  }

  &__sectionIndex {
    display: flex;
    flex-direction: column;
    gap: variables.$gutter-24;

    &__option--current {
      @include gradient.titleGradient;
    }
  }

  &__inView {
    &__title {
      margin-bottom: variables.$gutter-24;
      @include gradient.titleGradient;
    }

    &__emptyCard {
      height: 264px;
    }

    &--hidden {
      visibility: hidden;
    }
  }
}

.scrolling-accordion-mobile-view {
  display: block;

  @include breakpoints.screen(desktop-and-up) {
    display: none;
  }
}

.animate__animated.animate__fadeIn {
  --animate-duration: 2s;
}
