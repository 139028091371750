@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/shadows';
@use '../../../../sass/abstract/z-index';

.section-header-secondary {
  margin: 0 auto;

  &__content {
    position: relative;
    height: 100%;

    @include breakpoints.screen(tablet-and-up) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include breakpoints.screen(desktop-and-up) {
      padding: variables.$gutter-40 0;
    }
  }

  &__link {
    margin-top: variables.$gutter-24;
  }

  &__image-text-container {
    padding: variables.$gutter-24;

    @include breakpoints.screen(only-mobile) {
      padding: 0;
      margin-top: variables.$gutter-40;
    }
  }

  &__text {
    background-color: colors.$white;
    margin-top: variables.$gutter-24;
  }

  &__text-container {
    @include breakpoints.screen(tablet-and-up) {
      padding-bottom: variables.$gutter-40;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    @include breakpoints.screen(desktop-and-up) {
      height: auto;
      padding-bottom: 0;
      justify-content: flex-start;
      margin-right: variables.$gutter-24;
    }
  }

  &__image-box {
    background-color: colors.$white;

    @include breakpoints.screen(tablet-and-up) {
      @include shadows.drop-shadow-5;
    }
  }

  &__main-row {
    justify-content: center;
  }

  &__image {
    display: none;

    @include breakpoints.screen(tablet-and-up) {
      display: block;
    }

    img{
      position: relative !important;
    }
  }
}