@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/base/typography';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/mixins';

.scrolling-accordion-edit,
.scrolling-accordion {
  .reference {
    max-height: 254px;
    display: flex;
    column-gap: variables.$gutter-24;
    margin-bottom: variables.$gutter-24;
    color: colors.$gray-80;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &--disabled {
      @include mixins.disable-link-click;
    }

    &__image-container {
      overflow: hidden;
      width: 254px;
    }

    &__content-container {
      width: 164px;
    }

    &__title {
      line-height: typography.$line-height-24;
      margin-bottom: variables.$gutter-8;
    }

    &__text {
      font-size: typography.$font-size-16;
      line-height: typography.$line-height-24;
      overflow: hidden;
      max-height: calc(typography.$line-height-24 * 9);
    }

    &__link-container,
    &__category {
      display: none;
    }
  }
}

.scrolling-accordion {
  &__card {
    display: flex;
    color: colors.$gray-80;
    margin-bottom: variables.$gutter-40;

    @include breakpoints.screen(desktop-and-up) {
      margin-bottom: variables.$gutter-24;
    }

    &:hover {
      text-decoration: none;
    }

    &--disabled {
      @include mixins.disable-link-click;
    }
  }

  &__card-image-container {
    padding-right: variables.$gutter-12;
  }

  &__card-details-container {
    @include breakpoints.screen(desktop-and-up) {
      padding-left: variables.$gutter-12;
    }
  }

  &__card-image {
    &.next-image__container {
      display: none;
      @include breakpoints.screen(desktop-and-up) {
        display: flex;
      }
    }
  }

  &__card-header {
    line-height: typography.$line-height-32;
  }

  &__card-body {
    margin-top: variables.$gutter-8;
  }

  &__card-link {
    margin-top: variables.$gutter-16;
  }
}
