@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/mixins';
@use '../../../../sass/base/typography';
@use '../../../../sass/abstract/variables';

.scrolling-accordion-mobile {
  .reference {
    display: flex;
    column-gap: variables.$gutter-24;
    margin-bottom: variables.$gutter-40;
    color: colors.$gray-80;

    &__image-container {
      display: none;
    }

    &__content-container {
      max-width: fit-content;
    }

    &__text {
      max-height: fit-content;
    }

    &__title {
      font-size: typography.$font-size-18;
      line-height: normal;
      padding-bottom: variables.$gutter-8;
    }

    &__link-container,
    &__category {
      display: none;
    }
  }

  .accordion__item-header:hover {
    text-decoration: none;
    background: none;
    -webkit-text-fill-color: colors.$gray-80;
  }

  .scrolling-accordion__card-header {
    @include typography.subheading1;
  }
}
