@use '../../sass/abstract/colors';
@use '../../sass/abstract/border-radius';
@use '../../sass/base/typography';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/breakpoints';

.mobile-navigation {
  a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    height: variables.$gutter-24 + variables.$gutter-4;
    font-size: typography.$font-size-20;
    @include breakpoints.screen(tablet-and-up) {
      font-size: typography.$font-size-18;
    }

  }

  &__main-menu {
    display: flex;
    align-items: center;
    gap: variables.$gutter-16;
    padding: variables.$gutter-16 variables.$gutter-8 variables.$gutter-16 variables.$gutter-16;

    &:hover {
      cursor: pointer;
    }
  }

  &__level1 {
    &:hover {
      cursor: pointer;
    }

    @include breakpoints.screen(tablet-and-up) {
      margin-bottom:variables.$gutter-8;

      &:empty {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__level1-container {
    padding: variables.$gutter-8 variables.$gutter-8 variables.$gutter-8 variables.$gutter-16;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--open {
      a {
        justify-content: flex-start;
        gap: variables.$gutter-16;
      }
      padding: variables.$gutter-16 variables.$gutter-8 variables.$gutter-16 variables.$gutter-16 !important;

    }
  }

  &__level2 {
    li{
    @include breakpoints.screen(tablet-and-up) {
      margin-bottom:variables.$gutter-8;

      &:empty {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  }

  &__level2-container {
    padding: variables.$gutter-8 variables.$gutter-8 variables.$gutter-8 variables.$gutter-16;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:active {
      background-color: colors.$gray-light;
      border-radius: border-radius.$border-radius-20;
      @include typography.subheading1;
    }
  }

  &__level3-item {
    padding: variables.$gutter-8 variables.$gutter-16;
  }
}
