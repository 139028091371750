@use '../abstract/colors';
@use '../abstract/z-index';
@use '../abstract/breakpoints';
@use '../abstract/blur';
@use '../abstract/variables';
@use '../abstract/border';

@mixin setFontStyles($font-size, $line-height, $font-weight, $text-decoration) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  text-decoration: $text-decoration;
}

@mixin bold-on-hover {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &::after {
    content: attr(data-text);
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: 700;
  }
}

@mixin invisible-scroll {
  &::-webkit-scrollbar {
    visibility: hidden;
    height: 0;
  }
}

@mixin featured-scroll {
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    width: variables.$gutter-4;
    height: variables.$gutter-4;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    margin : 0 variables.$gutter-24;
    background-color: transparent;
    border-bottom: border.$border-1-solid-gray20;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: variables.$gutter-4;
    background-color: colors.$orange;
  }
}

@mixin scrollable-container {
  @include invisible-scroll;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  @include breakpoints.screen(desktop-and-up) {
    &::-webkit-scrollbar {
      visibility: visible;
      height: auto;
    }
  }
}

@mixin modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 100vh;
  max-height: 100%;
  width: 100%;
  min-width: 100vw;
  background: rgba(colors.$white, 0.9);
  z-index: z-index.$z-index-90;
  @include blur.blur-back-10;
}

@mixin change-uppercase-to-capitalize {
  text-transform: lowercase;

  &::first-letter,
  &::first-line {
    text-transform: capitalize;
  }
}

@mixin disable-link-click {
  pointer-events: none;
  cursor: default;
}
