@use '../../sass/abstract/colors';
@use '../../sass/abstract/border';
@use '../../sass/abstract/border-radius';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/mixins';
@use '../../sass/base/typography';
@use '../../sass/abstract/variables';
@use '../../sass/base/icons';


.search-field
{
    &__container{
        width: 100%;
        display: flex;
        border: border.$border-1-solid-gray80;
        padding: variables.$gutter-8 variables.$gutter-16;
        border-radius: border-radius.$border-radius-20;
        position: relative;
        gap: variables.$gutter-8;
        margin-top: variables.$gutter-24;

        &:hover, &:focus-within{
            background-color: colors.$gray-light;
        }
        
    }

    &__box{
        color: colors.$gray-80;
        background-color: transparent;
        outline: none;
        @include typography.body2;
        border: 0;
        width: calc(100% - variables.$gutter-24);
        padding: 0;
        margin: 0;

        &::placeholder{
            color: colors.$gray-80;
        }

        &::-webkit-search-decoration{
            display: none;
        }

        &::-webkit-search-cancel-button {
            @include icons.background-icon('dismiss-circle');
            background-position: -66px -110px;
            width: variables.$gutter-20;
            height: variables.$gutter-20;
            min-width: variables.$gutter-20;
            min-height: variables.$gutter-20;
            -webkit-appearance: none;
            opacity: 1;
            pointer-events: auto;

        }
        
    }
    
    &__icon{
        float: left;
        margin: 0;
    }
}

