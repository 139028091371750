@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/shadows';
@use '../../../../sass/abstract/border-radius';
@use '../../../../sass/abstract/variables';

.carousel-content {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: colors.$white;
  margin: 0 auto;
  width: 100vw;
  padding: variables.$gutter-24 variables.$gutter-24 variables.$gutter-40 variables.$gutter-24;

  @include breakpoints.screen(desktop-and-up) {
    width: 552px;
    padding: variables.$gutter-40;
    @include shadows.drop-shadow-5;
  }

  &__viewport {
    margin-bottom: variables.$gutter-24;
    overflow: hidden;
    height: auto;
    max-width: 100%;
    @include breakpoints.screen(tablet-and-up) {
      margin-bottom: variables.$gutter-40;
    }
    @include breakpoints.screen(desktop-and-up) {
      height: 100%;
      max-width: 768px;
    }
  }

  &__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    flex-direction: row;
    height: auto;
    margin-left: calc(var(--slide-spacing) * -1);
    @include breakpoints.screen(desktop-and-up) {
      height: 100%;
    }
  }

  &__slide {
    overflow: hidden;
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;

    &--hide {
      height: 0;
    }
  }

  &__dots {
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoints.screen(desktop-and-up) {
      justify-content: flex-start;
    }
  }

  &__dot {
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    text-decoration: none;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.5rem;
    height: 2rem;
    display: flex;
    align-items: center;
    margin-right: variables.$gutter-4;
    margin-left: variables.$gutter-4;

    &::after {
      content: '';
      background: colors.$gray-20;
      border-radius: border-radius.$border-radius-2;
      width: 100%;
      height: 0.25rem;
    }

    &--selected {
      &::after {
        background: colors.$orange;
      }
    }
  }

  &__button {
    cursor: pointer;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    text-decoration: none;
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.3;
    }

    &--prev {
      margin-right: variables.$gutter-16;
    }

    &--next {
      margin-left: variables.$gutter-16;
    }
  }
}
