@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/blur';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/z-index';
@use '../../../sass/base/icons';
@use '../../../sass/abstract/variables';
@use '../../../sass/base/typography';

@mixin dot-styles {
  @include icons.icon-size-32;
  border-radius: border-radius.$border-radius-half;
  @include gradient.horizontal-aurora-background;
}

@mixin first-dot-styles {
  @include dot-styles;
  animation: first-dot-animation 1.5s infinite ease-in-out;
}

@mixin middle-dot-styles {
  @include dot-styles;

  animation: middle-dot-animation 1.5s infinite ease-in-out;
}

@mixin last-dot-styles {
  @include dot-styles;
  animation: last-dot-animation 1.5s infinite ease-in-out;
}

.loader-main-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .insights-search-results {
    position: relative;
  }

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background: rgba(colors.$white, 0.8);
      z-index: z-index.$z-index-10;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    &__container {
      position: sticky;
      padding-top: 222px;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: z-index.$z-index-10;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: variables.$gutter-24;
    }

    &__dots {
      display: flex;
      align-items: center;
      justify-content: center;
      height: variables.$gutter-56 - variables.$gutter-4;
    }

    &__first-dot {
      @include first-dot-styles;
    }

    &__middle-dot {
      margin: 0 32px;
      @include middle-dot-styles;
    }

    &__last-dot {
      @include last-dot-styles;
    }

  }
}

@keyframes first-dot-animation {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.63, 1.63);
  }

  100% {
    transform: scale(1.63, 1.63);
  }
}

@keyframes middle-dot-animation {
  0% {
    transform: scale(1.63, 1.63);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.63, 1.63);
  }
}

@keyframes last-dot-animation {
  0% {
    transform: scale(1.63, 1.63);
  }

  50% {
    transform: scale(1.63, 1.63);
  }

  100% {
    transform: scale(1, 1);
  }
}