@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/variables';

.horizontal-card {
  padding: 0;
  height: 264px;
  min-width: 452px;
  border-radius: 0;

  &:hover {
    cursor: pointer;
  }

  &__image {
    height: 264px;
    min-height: 264px;
    max-height: 264px;
    width: 264px;
    min-width: 264px;
    max-width: 264px;
  }

  &__content {
    padding: 0;
    border-radius: 0;
    background-color: colors.$white;
    display: flex;
    flex-direction: row;
  }

  &__card-info {
    display: flex;
    flex-direction: column;
    padding-left: variables.$gutter-24;
  }

  &__link {
    margin-top: variables.$gutter-16;
  }

  &__title {
    margin-bottom: variables.$gutter-8;
  }
}
