.dateTimeRangeLabel {
  text-transform: capitalize;

  :lang(es-es) & {
    text-transform: none;
  }

  h4 {
    &:first-child {
      float: left;
    }
  }
}

.editing-mode .dateTimeRangeLabel {
  text-transform: none !important;
}

.prod-mode .dateTimeRangeLabel {
  text-transform: none !important;
}