@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/mixins';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/variables';

.top-lvl-menu {
  display: flex;
  height: 100%;
  align-items: center;

  &__link {
    font-weight: typography.$font-weight-700;
    font-size: typography.$font-size-16;
    line-height: typography.$line-height-24;
    color: colors.$gray-80;
    text-decoration: none;

    &--active, &--hovered {
      @include gradient.titleGradient;
    }

    &--hovered {
      &::after{
        height: 0; //overriding height
      }
    }

    &:visited {
      color: colors.$gray-80;
    }
  }

  &__list-item {
    padding: 0 variables.$gutter-16;
    white-space: nowrap;

    &:first-child {
      padding: 0 variables.$gutter-16 0 0;
    }

    &:last-child {
      padding: 0 0 0 variables.$gutter-16;
    }
  }
}
