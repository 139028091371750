@use '../../../../sass/base/typography';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/shadows';
@use '../../../../sass/abstract/breakpoints';

.carousel-small-card {
  display: flex;
  flex-direction: column;
  opacity: 0.9;
  width: 100%;
  background-color: colors.$white;
  @include shadows.drop-shadow-5;
  max-width: 768px;
  height: 100%;
  padding: 40px 24px;

  @include breakpoints.screen(desktop-and-up) {
    max-width: 552px;
    padding: 40px;
  }

  &__title {
    margin-bottom: 24px;
    font-size: typography.to-rem(14px);
    line-height: 16px;
    font-weight: typography.$font-weight-600;
  }
}
