@use '../../sass/abstract/variables';
@use '../../sass/abstract/border';

.divider {
  hr {
    margin: 0;
    opacity: 0.2;
    border-top: border.$border-1-solid-gray40;
  }
}
