@use '../../../src/sass/abstract/colors';
@use '../../../src/sass/base/typography';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../../src/sass/base/icons';
@use '../../../src/sass/abstract/border-radius';
@use '../../sass/abstract/border';

.playlist-video {
  .playlist-video-text {
    .playlist-video-container {
      display: flex;
      flex-direction: column;
      gap: variables.$gutter-48 - variables.$gutter-10;

      @include breakpoints.screen(tablet-and-up) {
        gap: variables.$gutter-16;
      }

      @include breakpoints.screen(desktop-and-up) {
        flex-direction: row;
        gap: variables.$gutter-16;
      }

      .video-js {
        width: 100%;
        height: 160px;
        font-family: inherit;
        @include breakpoints.screen(tablet-and-up) {
          height: 329px;
        }

        @include breakpoints.screen(desktop-and-up) {
          max-width: 1244px;
          height: 700px;
        }

        .vjs-big-play-button {
          border: 0;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: border-radius.$border-radius-half;
          margin-left: 0;
          margin-top: 0;
          transform: translate(-50%, -50%);
          width: variables.$gutter-120 - variables.$gutter-20;
          height: variables.$gutter-120 - variables.$gutter-20;

          &:hover {
            background-color: colors.$orange;
            opacity: 1;
          }

          .vjs-icon-placeholder {
            display: flex;
            border-radius: border-radius.$border-radius-half;

            &::before {
              content: '';
              display: block;
              background-repeat: no-repeat;
              background-position: center;
              background-size: inherit;
              height: 100%;
              width: 100%;
              border-radius: border-radius.$border-radius-half;
            }
          }

          &[type='button'] {
            .vjs-icon-placeholder::before {
              @include icons.background-icon('play-brightcove');
              background-position: 73% 100%;
              background-size: 492%;
            }
          }
        }

        .vjs-control-bar {
          height: 64px;
          border-radius: border-radius.$border-radius-17;
          background-color: colors.$gray-80;
          margin: 0 variables.$gutter-16 variables.$gutter-16;
          width: calc(100% - 32px);

          @include breakpoints.screen(tablet-and-up) {
            padding: 0 variables.$gutter-12;
          }

          @include breakpoints.screen(only-mobile) {
            height: variables.$gutter-32;
            margin: 0 variables.$gutter-8 variables.$gutter-8;
            border-radius: border-radius.$border-radius-8;
            padding: variables.$gutter-8 variables.$gutter-20;
            width: calc(100% - variables.$gutter-16);
            align-items: center;
            gap: variables.$gutter-20;
          }

          // controlbar valume panel
          .vjs-control.vjs-volume-control {
            .vjs-slider,
            .vjs-load-progress {
              background: colors.$gray-60;
              border-radius: border-radius.$border-radius-13;

              &:hover,
              &:focus {
                text-shadow: none;
              }

              .vjs-volume-level {
                border-radius: border-radius.$border-radius-13;
              }
            }
          }

          // controlbar progress panel
          .vjs-control.vjs-progress-control {
            .vjs-progress-holder .vjs-play-progress {
              border-radius: border-radius.$border-radius-13;
            }

            .vjs-load-progress div {
              background: colors.$gray-60;
              border-radius: border-radius.$border-radius-13;
            }

            .vjs-slider,
            .vjs-load-progress {
              background-color: colors.$gray-60;
              border-radius: border-radius.$border-radius-13;

              .vjs-mouse-display {
                border-radius: border-radius.$border-radius-13;
              }
            }
          }

          .vjs-time-control.vjs-time-divider {
            line-height: 60px;
          }

          .vjs-control {
            align-items: center;

            @include breakpoints.screen(only-mobile) {
              height: 16px;
              width: 16px;
            }

            @include breakpoints.screen(tablet-and-up) {
              width: variables.$gutter-56 - variables.$gutter-4;
            }
            // controlbar icons glow
            &:hover,
            &:focus {
              text-shadow: none;
            }

            &.vjs-time-control {
              margin: auto 0;
              @include typography.body2;
              line-height: 64px;

              .vjs-current-time-display,
              .vjs-duration-display {
                display: flex;
                align-items: center;
              }
            }

            &.vjs-remaining-time {
              padding: 0;
            }

            &.vjs-button {
              .vjs-icon-placeholder {
                &::before {
                  content: '';
                  display: block;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: inherit;
                  height: 100%;
                  width: 100%;

                  @include breakpoints.screen(only-mobile) {
                    background-size: variables.$gutter-16;
                    @include icons.icon-size-16;
                  }
                }
              }

              // Next button
              &.vjs-next-button {
                display: none;
              }

              // play and pause button
              &.vjs-play-control {
                &[title='Play'],
                &.vjs-playing {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('pause');
                    background-size: 946%;
                    background-position: 21% 41%;
                    @include breakpoints.screen(only-mobile) {
                      background-size: 1500%;
                      background-position: 23% 42%;
                    }
                  }
                }

                &.vjs-paused {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('play');
                    background-size: 583%;
                    background-position: 72% 100%;
                    @include breakpoints.screen(only-mobile) {
                      background-size: 1000%;
                      background-position: 70% 93%;
                    }
                  }
                }
              }

              // volume button
              &.vjs-mute-control {
                &.vjs-vol-0 {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('mute');
                    background-size: 1150%;
                    background-position: 62% 41%;

                    @include breakpoints.screen(only-mobile) {
                      background-size: 2000%;
                      background-position: 61% 42%;
                    }
                  }
                }

                &.vjs-vol-1 {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('volume-1');
                  }
                }

                &.vjs-vol-2 {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('volume-2');
                  }
                }

                &.vjs-vol-3 {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('volume-3');
                    background-size: 1150%;
                    background-position: 2.8% 54.3%;
                    @include breakpoints.screen(only-mobile) {
                      background-size: 1900%;
                      background-position: 4% 54.3%;
                    }
                  }
                }
              }

              &.vjs-share-control {
                .vjs-icon-placeholder::before {
                  @include icons.background-icon('share');
                  background-size: 1300%;
                  background-position: 32.5% 41.5%;

                  @include breakpoints.screen(only-mobile) {
                    background-size: 2000%;
                    background-position: 32.7% 41.5%;
                  }
                }
              }

              &.vjs-subs-caps-button {
                .vjs-icon-placeholder::before {
                  @include icons.background-icon('caption');
                  // background-size: 1500%;
                  // background-position: 23% 17%;

                  background-size: 2100%;
                  background-position: 23% 17.5%;

                  @include breakpoints.screen(tablet-and-up) {
                    background-size: 1400%;
                    background-position: 22.6% 15.7%;
                  }
                }
                display: block !important;

                .vjs-menu-content {
                  bottom: 0;
                  @include breakpoints.screen(tablet-and-up) {
                    bottom: variables.$gutter-40;
                  }
                }
              }

              &.vjs-picture-in-picture-control {
                @include breakpoints.screen(only-mobile) {
                  display: none;
                }

                &[title='Picture-in-Picture'] {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('picture-in-picture');
                    background-size: 1300%;
                    background-position: 71.4% 54%;
                  }
                }

                &[title='Exit Picture-in-Picture'] {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('picture-in-picture-exit');
                    background-size: 1300%;
                    background-position: 61.7% 54%;
                  }
                }
              }

              // fullscreen button
              &.vjs-fullscreen-control {
                &[title='Fullscreen'] {
                  .vjs-icon-placeholder::before {
                    @include icons.background-icon('full-screen');
                    background-size: 1330%;
                    background-position: 42% 54%;
                    @include breakpoints.screen(only-mobile) {
                      background-size: 2000%;
                      background-position: 42% 54.5%;
                    }
                  }
                }

                &[title='Exit Fullscreen'] {
                  .vjs-icon-placeholder::before {
                    // @include icons.background-icon('minimize-screen');
                    background-image: url('../../assets/icons/minimize-screen.svg');
                  }
                }
              }
            }
          }
        }

        .vjs-text-track-display {
          display: block !important;
          @include breakpoints.screen(desktop-and-up) {
            bottom: variables.$gutter-72;
          }
        }
      }

      .video-tab-menu {
        width: 100%;
        padding: 0 variables.$gutter-24;

        @include breakpoints.screen(tablet-and-up) {
          padding: 0 variables.$gutter-16;
        }

        @include breakpoints.screen(desktop-and-up) {
          padding: 0;
          overflow-y: scroll;
          height: 700px;
          max-width: 444px;

          &::-webkit-scrollbar {
            width: variables.$gutter-24;
            height: 0;
          }

          &::-webkit-scrollbar-thumb {
            height: 132px;
            box-shadow: inset variables.$gutter-20 variables.$gutter-20 variables.$gutter-20 0
              colors.$gray-10;
            border: border.$border-8-solid-transparent;
            border-radius: border-radius.$border-radius-20;
          }
        }

        .vjs-playlist {
          background-color: colors.$white;
          font-family: inherit;
        }

        .vjs-playlist-vertical {
          .vjs-playlist-item-list {
            display: flex;
            gap: variables.$gutter-48 - variables.$gutter-10;
            flex-direction: column;
            @include breakpoints.screen(tablet-and-up) {
              gap: variables.$gutter-24;
            }

            @include breakpoints.screen(desktop-and-up) {
              gap: variables.$gutter-24;
            }

            .vjs-playlist-ad-overlay {
              display: none;
            }

            .vjs-playlist-item {
              width: 100%;
              height: 264px;
              display: flex;
              margin-bottom: 0;

              @include breakpoints.screen(tablet-and-up) {
                height: 121px;
              }

              @include breakpoints.screen(desktop-and-up) {
                width: 100%;
                height: 121px;
              }

              &.vjs-selected {
                background: none;

                .vjs-playlist-now-playing-text {
                  display: flex;
                }

                &::after {
                  content: '';
                  background: rgba(0, 0, 0, 0.3);
                  width: 100%;
                  height: 192px;
                  position: absolute;

                  @include breakpoints.screen(tablet-and-up) {
                    width: 216px;
                    height: 121px;
                  }

                  @include breakpoints.screen(desktop-and-up) {
                    width: 216px;
                    height: 100%;
                  }
                }

                img {
                  opacity: unset;
                }
              }

              .vjs-playlist-duration {
                display: none;
              }

              .vjs-playlist-thumbnail {
                display: flex;
                flex-direction: column;

                gap: 24px;

                .vjs-up-next-text {
                  display: none;
                }

                @include breakpoints.screen(tablet-and-up) {
                  flex-direction: row;
                }

                @include breakpoints.screen(desktop-and-up) {
                  flex-direction: row;
                }

                img {
                  width: 100%;
                  height: 192px;

                  @include breakpoints.screen(tablet-and-up) {
                    width: 216px;
                    height: 100%;
                  }

                  @include breakpoints.screen(desktop-and-up) {
                    width: 216px;
                    height: 100%;
                  }
                }

                .vjs-playlist-now-playing-text {
                  color: colors.$orange;
                  width: 100%;
                  height: 192px;
                  margin: 0;
                  justify-content: center;
                  align-items: center;
                  font-size: typography.$font-size-20;
                  font-style: normal;
                  font-weight: typography.$font-weight-700;
                  line-height: normal;
                  z-index: 2;

                  @include breakpoints.screen(tablet-and-up) {
                    width: 216px;
                    height: 121px;
                  }

                  @include breakpoints.screen(desktop-and-up) {
                    width: 216px;
                    height: 100%;
                  }
                }
              }

              .vjs-playlist-title-container {
                height: max-content;
                width: calc(100% - variables.$gutter-24);

                position: relative;
                color: colors.$gray-80;
                padding: 0;
                font-size: typography.$font-size-18;
                font-style: normal;
                font-weight: typography.$font-weight-400;
                line-height: normal;
                text-shadow: none;

                .vjs-playlist-name {
                  text-overflow: unset;
                  overflow: unset;
                  white-space: normal;
                  padding: 0;
                  max-height: inherit;
                }

                @include breakpoints.screen(tablet-and-up) {
                  height: 100%;
                }

                @include breakpoints.screen(desktop-and-up) {
                  width: 204px;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal {
  .video-tab-menu {
    max-height: 330px;
    overflow-y: scroll;
    @include breakpoints.screen(tablet-and-up) {
      max-height: 560px;
    }
    @include breakpoints.screen(desktop-and-up) {
      max-height: unset;
    }
  }

  .playlist-video {
    @include breakpoints.screen(desktop-and-up) {
      height: 80%;
    }
  }
}
