@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/z-index';
@use '../../sass/abstract/border';

.client-search {
  &--sticky {
    padding-top: 0;
    display: block;
    background-color: colors.$white;
    padding-bottom: variables.$gutter-40;
    border-bottom: border.$border-1-solid-gray30;

    @include breakpoints.screen(desktop-and-up) {
      padding-top: variables.$gutter-40;
    }

    .column-splitter {
      row-gap: variables.$gutter-24;
      position: relative;
      z-index: z-index.$z-index-20;
      display: flex;

      @include breakpoints.screen(tablet-and-up) {
        column-gap: 0 !important;
        margin-left: -(variables.$gutter-12);
      }

      >div:empty {
        display: none;
      }

      >div {
        @include breakpoints.screen(only-mobile) {
          width: 100%;
        }
      }
    }

    .column-splitter.gap-24 {
      column-gap: variables.$gutter-24;
      @include breakpoints.screen(tablet-and-up) {
        column-gap: 0 !important;
        margin-left: 0 !important;
      }
      @include breakpoints.screen(desktop-and-up) {
        column-gap: variables.$gutter-24 !important;
      }
    }
  }

  &__content {
    position: relative;
  }
}

.newsroom-search {
  //border-bottom: 0;

  &__search-input {
    margin-bottom: variables.$gutter-40;
  }
}