@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/mixins';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/base/typography';

.text-image-left {
  &__header {
    @include gradient.titleGradient;
  }

  &__description {
    font-size: typography.$font-size-16;
    padding: variables.$gutter-24 0;
    line-height: typography.$line-height-24;
  }

  &__text-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include breakpoints.screen(desktop-and-up) {
      align-items: flex-start;
      text-align: left;
      padding-left: variables.$gutter-16;
    }
  }

  &__image-column {
    order: 1;
    @include breakpoints.screen(desktop-and-up) {
      order: 0;
    }
  }

  &__image-container {
    margin-top: variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      margin-top: 0;
      margin-right: variables.$gutter-8;
    }
  }
}
