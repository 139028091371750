@use '../../sass/abstract/mixins';
@use '../../sass/base/typography';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/breakpoints';

.sitemap-navigation {
  .button-hidden {
    display: none;
  }

  &__list {
    display: grid;
    gap: variables.$gutter-16 0;
    @include breakpoints.screen(tablet-and-up) {
      grid-template-columns: repeat(2, 1fr);
      
      .level1 {
        padding-left: variables.$gutter-16;

        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1 / span 4;
        }

        &:nth-child(2) {
          grid-column: 1;
          grid-row: 5;
        }

        &:nth-child(3) {
          grid-column: 2;
          grid-row: 1;
        }

        &:nth-child(4) {
          grid-column: 2;
          grid-row: 2;
        }

        &:nth-child(5) {
          grid-column: 2;
          grid-row: 3;
        }
      }
    }
    @include breakpoints.screen(desktop-and-up) {
      
      grid-template-columns: repeat(3, 1fr);
      
      .level1 {
        padding-left: variables.$gutter-16;

        &:nth-child(1) {
          grid-column: 1;
          grid-row: 1 / span 4;
        }

        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1 / span 4;
        }

        &:nth-child(3) {
          grid-column: 3;
          grid-row: 1;
        }

        &:nth-child(4) {
          grid-column: 3;
          grid-row: 2;
        }

        &:nth-child(5) {
          grid-column: 3;
          grid-row: 3;
        }
      }
    }
  }

  .level1 {
    a {
      @include typography.h5;
    }

    .sitemap-navigation__level-title {
      padding-bottom: variables.$gutter-12;
      padding-top: variables.$gutter-12;
      @include breakpoints.screen(only-mobile) {
        padding-bottom: variables.$gutter-24;
        padding-top: 0;
      }
    }
  }

  .level2 {
    a {
      @include typography.body1;
    }

    .sitemap-navigation__level-title {
      padding-top: variables.$gutter-12;
      @include breakpoints.screen(only-mobile) {
        padding-bottom: variables.$gutter-24;
        padding-top: 0;
      }
    }
  }

  .level3 {
    a {
      @include typography.body2;
    }

    .sitemap-navigation__level-title {
      padding-top: variables.$gutter-12;
      @include breakpoints.screen(only-mobile) {
        padding-bottom: variables.$gutter-24;
        padding-top: 0;
      }
    }
  }
}
