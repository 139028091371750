@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/variables';

.cards--stack {
  &__container {
    display: flex;
    gap: variables.$gutter-24;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
  }

  &__title {
    text-align: center;
    margin-bottom: variables.$gutter-40;
    flex-basis: 100%;
  }

  &__tiles-container {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: variables.$gutter-40;

    &:empty {
      display: none;
    }

    &--center {
      @include breakpoints.screen(desktop-and-up) {
        align-items: center;
        justify-content: center;
        display: flex;
        width: auto;
      }
    }

    @include breakpoints.screen(tablet-and-up) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoints.screen(desktop-and-up) {
      grid-gap: variables.$gutter-40 23px;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
