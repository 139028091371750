@use '../../../../sass/abstract/variables';

.hero {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: variables.$gutter-24;
    margin: 0 auto;
    text-align: center;
  }
  
  &__text {
    text-align: center;
    width: 100%;
  }
}
