@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/border';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/border-radius';
@use '../../sass/abstract/mixins';
@use '../../sass/base/typography';
@use '../../sass/base/buttons';
@use '../../sass/abstract/variables';
@use '../../sass/base/icons';

$spriteImageIcons: '../../assets/icons/spriteIcon.svg';

.form {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  form.mktoForm {
    width: 100% !important;
    padding: 0;

    .mktoField {
      padding: variables.$gutter-8 variables.$gutter-16;
      height: 40px;
      border-radius: border-radius.$border-radius-20;
      border: border.$border-1-solid-gray40;
      background: colors.$white;

      &.mktoInvalid {
        border: border.$border-1-solid-red;
        background-color: colors.$status-red-5;
      }
    }

    select.mktoField {
      appearance: none;
      background: colors.$white;
      // @include icons.background-icon('chevron-down');
      // background-image: url($spriteImageIcons);
      background-image: url("../../assets/icons/chevron-down.svg");
      background-position: -196px -150px;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 12px);
      background-position-y: center;
      padding: variables.$gutter-8 variables.$gutter-40 variables.$gutter-8 variables.$gutter-16;

      option {
        color: colors.$gray-80;
        font-size: typography.$font-size-16;
        line-height: typography.$line-height-24;
        font-weight: typography.$font-weight-400;
        position: relative;

        &:checked {
          font-weight: typography.$font-weight-600;
        }
      }
    }

    input[type='checkbox'].mktoField {
      border: border.$border-3-solid-gray80;
      padding: variables.$gutter-8 0 variables.$gutter-8 variables.$gutter-16;
      margin: 3px;
    }

    .mktoFieldWrap .mktoLogicalField.mktoCheckboxList.mktoHasWidth,
    .mktoHtmlText.mktoHasWidth,
    .mktoFieldWrap,
    .mktoField.mktoHasWidth.mktoRequired,
    .mktoLabel.mktoHasWidth,
    input[type='tel'] {
      width: 100% !important;
    }

    .mktoLabel,
    .mktoField,
    .mktoCheckboxList > label,
    .mktoFieldWrap span {
      line-height: typography.$line-height-24 !important;
      font-weight: typography.$font-weight-400 !important;
      font-family: typography.$ff-primary !important;
      color: colors.$gray-80 !important;
      font-size: typography.$font-size-16 !important;
    }

    .mktoLabel {
      padding-top: 0 !important;
      margin-bottom: variables.$gutter-8 !important;
    }

    .mktoOffset,
    .mktoClear {
      display: none;
      height: 0;
    }

    .mktoFormRow {
      display: flex;
      column-gap: 25px;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    textarea.mktoField {
      height: 110px;
    }

    .mktoFieldDescriptor.mktoFormCol {
      flex: 1;
      padding-bottom: 14px;
      width: auto;

      @include breakpoints.screen(only-mobile) {
        min-width: 100%;
        display: flex;
      }
    }

    .mktoFieldDescriptor.mktoFormCol:has(input[type='checkbox']) {
      margin-bottom: 2px !important;
    }

    .mktoCheckboxList {
      padding: 0;

      > label {
        margin-top: variables.$gutter-11;
        margin-bottom: 0;
        margin-right: 0;
        margin-left: variables.$gutter-8;
        float: left;
        max-width: 75%;
      }
    }

    .mktoButtonWrap.mktoPaperMashup .mktoButton {
      width: 100%;
      margin-top: 30px;
      font-family: typography.$ff-primary !important;
      @include buttons.button-primary;
      @include mixins.change-uppercase-to-capitalize;
    }

    .mktoButtonWrap.mktoPaperMashup {
      margin-left: 0 !important;
      @include breakpoints.screen(only-mobile) {
        width: 100%;
      }
    }

    .mktoAsterix {
      color: colors.$scarlet;
    }

    .mktoError {
      position: static;
      height: 28px;
      margin-top: variables.$gutter-80;
    }

    .mktoErrorMsg {
      color: colors.$scarlet;
    }

    .mktoError .mktoErrorArrowWrap {
      display: none;
    }

    .mktoError .mktoErrorMsg {
      background-color: transparent;
      background-image: none;
      text-shadow: none;
      box-shadow: none;
      font-size: typography.$font-size-16;
      line-height: typography.$line-height-24;
      font-weight: typography.$font-weight-400;
      border: 0;
      margin-top: variables.$gutter-8;
      padding: 0;
      font-family: typography.$ff-primary !important;
    }

    .mktoError .mktoErrorMsg .mktoErrorDetail {
      display: none;
    }
  }
}

.close-button,.form-submit{
  display: none;
}

