@use '../../sass/abstract/colors';
@use '../../sass/abstract/border';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/border-radius';
@use '../../sass/abstract/breakpoints';

.is-featured {
  &__tag {
    gap: variables.$gutter-8;
    background: colors.$dark-pink;
    color: colors.$white;
    padding: variables.$gutter-8 variables.$gutter-8 variables.$gutter-8 variables.$gutter-8;
    border-radius: border-radius.$border-radius-5;
    display: flex;
    align-items: center;
    min-height: variables.$gutter-32 + variables.$gutter-10;
    max-width: 171px;
    @include breakpoints.screen(desktop-and-up) {
      max-width: 165px;
      justify-content: space-between;
    }
  }
}

.multi-day {
  &__tag {
    gap: variables.$gutter-8;
    background: colors.$gray-70;
    color: colors.$white;
    padding: variables.$gutter-8;
    border-radius: border-radius.$border-radius-5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: variables.$gutter-32 + variables.$gutter-10;
    max-width: 169px;
  }
}
