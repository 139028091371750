@use '../../sass/base/typography';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/breakpoints';

.rich-text {
  &.s-gradient-text {
    div {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        @include gradient.titleGradient;
      }
    }
  }

  div {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: variables.$gutter-24;
    }

    p {
      font-size: typography.$font-size-16;
      line-height: typography.$line-height-24;
      margin-bottom: variables.$gutter-24;
    }

    ol {
      list-style: decimal;
      margin-bottom: variables.$gutter-24;
    }

    ul {
      list-style: disc;
      margin-bottom: variables.$gutter-24;
    }

    li {
      font-size: typography.$font-size-16;
      line-height: typography.$line-height-32;
      margin-left: variables.$gutter-24 + variables.$gutter-4;

      a {
        margin-bottom: 0;
      }
    }

    a {
      margin-bottom: variables.$gutter-16;
    }

    strong {
      font-size: typography.$font-size-16;
      line-height: typography.$line-height-24;
      font-weight: typography.$font-weight-700;
    }

    u {
      font-weight: typography.$font-weight-400;
      font-size: typography.$font-size-16;
      line-height: typography.$line-height-24;
      text-decoration: underline;
    }

    em {
      font-weight: typography.$font-weight-400;
      font-size: typography.$font-size-16;
      line-height: typography.$line-height-24;
      font-style: italic;
    }
  }

  table {
    border-collapse: collapse;
    width: 100% !important;
    height: auto !important;

    tr {
      &:first-of-type {
        td {
          @include typography.body2;
          background-color: colors.$gray-60;
          color: colors.$white !important;
        }
      }

      td {
        border: 1px solid colors.$black;
        padding: variables.$gutter-8 !important;
        width: 50% !important;
        height: auto !important;
        @include typography.body2;
        vertical-align: top !important;

        ul {
          vertical-align: top !important;
          margin-bottom: 0;
        }
      }
    }
  }

  figure.table {
    border-collapse: collapse;
    width: 100% !important;
    height: auto !important;
  }
}

div:has(.contact-expert) 
{
  .rich-text
  {
    text-align: center;
    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
  }
}
}