@use '../../../../sass/abstract/colors';

.container-grey-background {
  .full-bleed-banner {
    background: colors.$gray-light;
  }

  .full-bleed-banner__title,
  .full-bleed-banner__tag {
    color: colors.$gray-80;
  }

  .full-bleed-banner__text {
    color: colors.$gray-80;
  }
  
  &--hide {
    display: none;
  }
}
