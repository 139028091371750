@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use './../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/z-index';

.events-list {
  background-color: colors.$white;

  &__container {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__title {
    text-align: center;
  }

  &__title-container {
    flex-basis: 100%;
    margin-bottom: variables.$gutter-40;
  }

  &__cta-container {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    margin-top: variables.$gutter-40;
  }

  &__cards-container {
    display: grid;
    gap: variables.$gutter-24;
    z-index: z-index.$z-index-50;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    margin-inline: -(variables.$gutter-24);
    padding: 0 variables.$gutter-24;


    @include mixins.scrollable-container;
    scrollbar-width: none;
    @include breakpoints.screen(only-mobile) {
      grid-auto-flow: column;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin-inline: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      grid-gap: 40px 24px;
    }
  }
}

