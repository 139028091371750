@use '../../../../src/sass/abstract/mixins';
@use '../../../../src/sass/abstract/variables';
@use '../../../../src/sass/abstract/breakpoints';

.modal {
  @include mixins.modal;
  border: 0;
  padding: 0 variables.$gutter-24;
  @include breakpoints.screen(desktop-and-up) {
    padding: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
    max-width: 1128px;
    margin: 0 auto;
    @include breakpoints.screen(desktop-and-up){
      width: 70%;
    }
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    padding: variables.$gutter-8;
    margin-bottom: variables.$gutter-24;

    &:focus-visible {
      outline: none;
      @include breakpoints.screen(desktop-and-up) {
        outline: inherit;
      }
    }
  }

  &__close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: variables.$gutter-16;
    background-color: unset;

    &:focus-visible {
      outline: none;
      @include breakpoints.screen(desktop-and-up) {
        outline: inherit;
      }
    }
  }
}
