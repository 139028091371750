@use '../../sass/abstract/colors';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/shadows';
@use '../../sass/abstract/z-index';
@use '../../sass/base/icons';
@use '../../sass/base/typography';
@use '../../components/shared/nextImg/NextImageWithNullFallback.scss';

.header {
  position: fixed;
  top: 0;
  z-index: z-index.$z-index-80;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colors.$white;
  @include shadows.drop-shadow-5;
  min-height: variables.$sm-header-height;

  @include breakpoints.screen(desktop-and-up) {
    min-height: variables.$header-height;
  }

  &.jl__sticky {
    box-shadow: none;
  }

  .container {
    display: flex;
    align-items: center;
  }

  .next-image__container {
    margin-right: auto;
    width: 117px;
    min-width: 117px;
    max-width: 117px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
  }

  .header__main-content {
    order: 1;
    @include breakpoints.screen(desktop-and-up) {
      display: flex;
      align-items: center;
      width: auto;
      order: 0;
    }
  }

  .header__container {
    display: none;

    &--open {
      display: flex;
      padding: 0 variables.$gutter-8;
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      background: colors.$white;
      height: calc(100vh - variables.$sm-header-height);
      overflow-y: auto;
    }

    @include breakpoints.screen(desktop-and-up) {
      display: flex;
      align-items: center;
    }
  }

  .header__navigation-hamburger {
    cursor: pointer;
    display: flex;
    width: 24px;
    height: typography.$line-height-24;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @include icons.background-icon('hamburger');
    // background-size: contain;
    background-repeat: no-repeat;
    margin-left: variables.$gutter-24;
    // background-size: 1600%;
    // background-position: 82% 29%;
    background-position: -369px -106px;
    @include breakpoints.screen(desktop-and-up) {
      display: none;
      background-image: none;
    }

    &--open {
      @include icons.background-icon('dismiss');
      background-position: -107px -107px;
    }
  }

  .link {
    order: 1;
    width: calc(100% - variables.$gutter-24);
    margin: variables.$gutter-8 0;

    @include breakpoints.screen(desktop-and-up) {
      width: auto;
      order: 0;
      margin: 0 variables.$gutter-32 0 0;
    }
  }
}
