@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/mixins';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/variables';

.tabs {
  width: 100%;

  &__container {
    @include breakpoints.screen(tablet-and-up) {
      display: flex;
      flex-wrap: wrap;
      row-gap: variables.$gutter-16;
      align-items: end;
      justify-content: center;
    }
    @include breakpoints.screen(desktop-and-up) {
      display: flex;
      flex-wrap: nowrap;
      align-items: end;
      justify-content: center;
    }
  }

  &__single-tab {
    border-bottom: border.$border-1-solid-gray20;
    color: colors.$gray-80;
    font-size: typography.$font-size-16;
    font-style: normal;
    line-height: typography.$line-height-24;
    padding: 0 21px variables.$gutter-8 21px;
    padding-bottom: 9px;
    text-align: center;
    transition: font-weight 0.1s ease-in-out;
    margin: 0;
    @include mixins.bold-on-hover;

    :lang(de-de) & {
      hyphens: auto;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
 

    &:not([data-text]) {
      display: none;
    }

    &--active {
      font-weight: typography.$font-weight-700;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 4px;
        @include gradient.horizontal-aurora-background;
      }
    }
    @include breakpoints.screen(desktop-and-up) {
      &:hover {
        @include gradient.titleGradient;
        font-weight: typography.$font-weight-700;
      }
    }
  }
}
