@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/variables';

.vertical-card {
  padding: 0;
  min-height: 452px;
  height: 100%;
  width: 264px;
  border-radius: 0;
  background-color: colors.$white;

  &__image {
    height: 264px;
    width: 264px;
  }

  .link__button-tertiary {
    margin-top: variables.$gutter-16;
  }

  &__content__text__title {
    margin-top: variables.$gutter-16;
  }

  &__content__text__body {
    margin-top: variables.$gutter-8;
  }

  &:hover {
    cursor: pointer;
  }
}
