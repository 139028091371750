@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/border';

.metric-blocks {
  &__title {
    text-align: center;
    margin-bottom: variables.$gutter-40 - variables.$gutter-10;
  }

  &__main-content {
    display: flex;
    justify-content: center;
  }

  &__metric {
    &--no-border {
      padding-top: 0;
      @include breakpoints.screen(tablet-and-up) {
        &:first-child {
          &::before {
            border-right: 0 !important;
          }
        }
      }
    }

    &--border-bottom {
      @include breakpoints.screen(tablet-and-up) {
        // border-bottom: border.$border-1-solid-gray20;
        // margin-bottom: variables.$gutter-40;
        // padding-bottom: variables.$gutter-40;

        &:first-child {
          position: relative;

          &::before {
            height: calc(100% - variables.$gutter-40);
          }
        }

        &:last-child {
          // border-bottom: 0;
          // margin-bottom: 0;
          // padding-bottom: 0;
          margin-top: 40px;
          padding-top: 40px;
          border-top: 1px solid #ccc;
          width: 100%;
          @include breakpoints.screen(desktop-and-up) {
            border-top: 0;
            margin-top: 0;
            padding-top: 0;
            width: 25%;
          }
        }
      }
    }

    @include breakpoints.screen(only-mobile) {
      padding: variables.$gutter-40 0;

      &:first-child {
        padding: 0 0 variables.$gutter-40 0;
      }

      &:last-child {
        padding: variables.$gutter-40 0 0 0;
      }

      &:not(:last-child) {
        border-bottom: border.$border-1-solid-gray20;
      }
    }

    @include breakpoints.screen(tablet-and-up) {
      &:first-child {
        position: relative;

        &::before {
          content: '';
          height: 100%;
          position: absolute;
          right: 0;
          border-right: border.$border-1-solid-gray20;
        }
      }

      &:nth-child(3) {
        &:not(:last-child) {
          margin-top: variables.$gutter-40;
          padding-top: variables.$gutter-40;
          border-top: border.$border-1-solid-gray20;
        }
      }

      &:nth-child(4) {
        margin-top: variables.$gutter-40;
        padding-top: variables.$gutter-40;
        border-top: border.$border-1-solid-gray20;
        position: relative;

        &::before {
          content: '';
          border-left: border.$border-1-solid-gray20;
          height: calc(100% - variables.$gutter-40);
          position: absolute;
          left: 0;
        }
      }
    }

    @include breakpoints.screen(desktop-and-up) {
      border-right: border.$border-1-solid-gray20;
      border-bottom: 0;

      &:first-child {
        &::before {
          display: none;
        }
      }

      &:nth-child(3) {
        &:not(:last-child) {
          margin-top: 0;
          padding-top: 0;
          border-top: 0;
        }
      }

      &:nth-child(4) {
        margin-top: 0;
        padding-top: 0;
        border-top: 0;

        &::before {
          display: none;
        }
      }

      &:last-child {
        border-right: 0;
      }
    }
  }
}
