@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/z-index';

.container-gray-background {
  .card {
    background-color: colors.$white;
  }
}

.cards--top-title {
  &__container {
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__title {
    text-align: center;
    flex-basis: 100%;
    margin-bottom: variables.$gutter-40;
  }

  &__cards-container {
    display: grid;
    gap: variables.$gutter-24;
    z-index: z-index.$z-index-50;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    margin-inline: -(variables.$gutter-24);
    padding: 0 variables.$gutter-24;


    @include mixins.scrollable-container;
    
    @include breakpoints.screen(desktop-and-up) {
      margin-inline: 0;
      padding: 0;
    }
  }

  &__single-card-container {
    &:last-child {
      margin-right: auto;
    }

    &:first-child {
      margin-left: auto;
    }

    &:empty {
      display: none;
    }
  }
}
