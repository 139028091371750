@use '../../sass/abstract/colors';
@use '../../sass/abstract/breakpoints';
@use '../../sass/base/typography';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';

.career-footer{
  display: flex;
  margin: variables.$gutter-40 auto;
  flex-direction: column;
  gap: variables.$gutter-40;
  @include breakpoints.screen(tablet-and-up) {
    margin: variables.$gutter-40 auto;
    align-items: center;
  }

  @include breakpoints.screen(desktop-and-up) {
    width: inherit;
    align-items: normal;
    margin: variables.$gutter-80 auto;
  }

  &__container  {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: variables.$gutter-24;
  
    @include breakpoints.screen(tablet-and-up) {
      gap: variables.$gutter-40;
      flex-shrink: 0;
    }
  
    @include breakpoints.screen(desktop-and-up) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      gap: 119px;
      flex-shrink: inherit;
    }
    
  }

  &__Image {
    width: 208.727px;
    height: 217.424px;
    float: left;
    display: block;
    object-fit: cover;
    gap: 119px;
    margin: 0;
  
    @include breakpoints.screen(tablet-and-up) {
      width: 223.918px;
      height: 233.248px;
    }
  
    @include breakpoints.screen(desktop-and-up) {
      width: 457px;
      height: 476px;
    }

    img{
      position: relative !important;
    }
  }
  
  &__footerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: variables.$gutter-40;
    text-align: center;
  
    @include breakpoints.screen(desktop-and-up) {
      width: 552px;
      align-items: flex-start;
      gap: variables.$gutter-48;
      text-align: inherit;
    }
  
    &__top {
      display: flex;
      gap: variables.$gutter-16;
      flex-direction: column;
      width: 100%;

      .search-field__container{
        margin-top: 0;
        @include breakpoints.screen(tablet-and-up){
          width:342px;
          margin:0 auto;
        }
        @include breakpoints.screen(desktop-and-up){
          margin: 0;
        }
      }
    }
    
    &__bottom{
      gap:variables.$gutter-16;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include breakpoints.screen(desktop-and-up){
        align-items: start;
      }

      .link__button-primary{
        @include breakpoints.screen(tablet-and-up)
        {
          width: fit-content;
        }
      }
    }

    &__footer-button{
      width: 100%;
      
      @include breakpoints.screen(desktop-and-up){
        width: max-content;
      }
    }
  }

  &__disclaimer{
    text-align: center;
    margin: variables.$gutter-40 0;
  }
}



