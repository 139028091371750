@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/base/typography.scss';
@use '../../../sass/base/icons';

.inside-accordion {
  max-width: variables.$L-width;

  &__root {
    max-width: variables.$L-width;
  }

  &__item {
    width: 100%;
  }

  &__container {
    border-bottom: border.$border-1-solid-gray20;

    &:first-child {
      border-top: border.$border-1-solid-gray20;
    }

    &--open {
      .inside-accordion__icon--plus {
        display: none;
      }

      .inside-accordion__icon--minus {
        display: flex;
      }

      .inside-accordion__item-info-box {
        @include breakpoints.screen(tablet-and-up) {
          display: none;
        }
      }

      .inside-accordion__item-container {
        justify-content: flex-end;
        padding: variables.$gutter-32 0 variables.$gutter-16 0;
        @include breakpoints.screen(tablet-and-up) {
          padding: variables.$gutter-32 0;
        }

        @include breakpoints.screen(only-mobile){
          flex-direction: column-reverse;
 
          .inside-accordion__item-info-box{
            align-self: flex-start;
          }
 
          .inside-accordion__trigger{
            align-self: flex-end;
            padding-bottom: variables.$gutter-16;
          }
        }
      }

      .inside-accordion__accordion-content {
        display: block;
      }
    }
  }

  &__image {
    display: none;
    height: 104px;
    min-height: 104px;
    max-height: 104px;
    width: 104px;
    min-width: 104px;
    max-width: 104px;
    margin-right: variables.$gutter-24;

    @include breakpoints.screen(tablet-and-up) {
      display: block;
    }
  }

  &__accordion-content {
    border-radius: border-radius.$border-radius-20 border-radius.$border-radius-20 0 0;
    overflow: hidden;
    display: none;
  }

  &__item-divider {
    display: none;
    margin-right: variables.$gutter-20;
    justify-content: center;
    position: relative;
    margin-top: variables.$gutter-12;
    margin-bottom: variables.$gutter-12;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      height: 100%;
      width: 1px;
      padding-top: variables.$gutter-16;
      padding-bottom: variables.$gutter-16;
      background-color: colors.$gray-20;
    }

    @include breakpoints.screen(tablet-and-up) {
      display: flex;
    }
  }

  &__social-icon {
    margin-right: variables.$gutter-24;

    &:last-child {
      margin-right: 0;
    }
  }

  &__item-social {
    display: none;
    align-items: center;

    @include breakpoints.screen(tablet-and-up) {
      display: flex;
    }
  }

  &__item-info-box {
    display: flex;
    flex: 1;
  }

  &__name {
    margin-top: variables.$gutter-8;
  }

  &__item-container {
    padding: variables.$gutter-32 0;
    color: colors.$gray-80;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main-container {
    display: flex;
    align-items: center;
    width: 100%;
    @include breakpoints.screen(tablet-and-up) {
      width: 50%;
    }

    &--full {
      @include breakpoints.screen(tablet-and-up) {
        width: 100%;
      }
    }
  }

  &__item-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: variables.$gutter-20;
    width: 100%;
  }

  &__icon {
    @include icons.icon-size-28;

    @include breakpoints.screen(tablet-and-up) {
      @include icons.icon-size-48;
    }

    &--minus {
      display: none;
    }

    &--plus {
      transition: transform 300ms;
      flex-shrink: 0;
      color: colors.$gray-80;
    }
  }

  &__trigger {
    @include breakpoints.screen(tablet-and-up) {
      margin-right: variables.$gutter-40;
      margin-left: variables.$gutter-24;
    }
  }

  &__show-more-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: variables.$gutter-20 0;
  }

  &__show-more-icon {
    width: 24px;
    height: 24px;
  }

  &__item-cards {
    margin: variables.$gutter-20 0;
    display: block;
    color: colors.$gray-80;

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin: 0;
    }
  }
}
