@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use './../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/base/typography';

.related-client-stories {
  .content-gallery-card {
    &__title {
      display: block;
    }
  }

  &__all-cards-container {
    display: none;
    @include breakpoints.screen(tablet-and-up) {
      display: flex;
    }
  }

  &__header-container {
    justify-content: center;
  }

  &__title {
    @include gradient.titleGradient;
    text-align: center;
    margin-bottom: variables.$gutter-40 - variables.$gutter-10;
  }

  &__card-container {
    width: 264px;
    display: inline-block;
    margin-right: variables.$gutter-24;

    &:last-child {
      margin-right: auto;
    }

    &:first-child {
      margin-left: auto;
    }
  }

  &__main-container {
    @include breakpoints.screen(only-mobile) {
      display: block;
    }

    @include breakpoints.screen(tablet-and-up) {
      display: none;
    }
  }

  &__category {
    margin-bottom: variables.$gutter-8;
    line-height: typography.$line-height-24;
  }

  &__dropdown {
    margin-bottom: variables.$gutter-40;
  }

  &__mobile-cards-container {
    flex-wrap: nowrap;
    display: flex;
    @include mixins.scrollable-container;
    margin-inline: -(variables.$gutter-24);
    padding: 0 variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      padding: 0;
      margin: 0;
    }
  }

  &__card-big-container {
    display: flex;
    margin-top: variables.$gutter-40;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    @include mixins.invisible-scroll;
  }

  &__link {
    margin-top: variables.$gutter-40;
    display: flex;
    justify-content: flex-start;

    @include breakpoints.screen(tablet-and-up) {
      justify-content: center;
    }
  }
}
