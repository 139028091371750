@use './../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/mixins';

.container-gray-background .full-bleed-banner-mirror .container {
  padding: 0;
}

.full-bleed-banner-mirror {
  @include breakpoints.screen(desktop-and-up) {
    @include gradient.horizontal-aurora-background-inverse;
  }
  @include gradient.vertical-aurora-background;

  &.container-white-background {
    .full-bleed-banner-mirror__title {
      @include gradient.titleGradient;
    }

    .full-bleed-banner-mirror__text,
    .full-bleed-banner-mirror__tag {
      color: colors.$gray-80;
    }
  }

  &__text-container {
    text-align: center;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
      padding-left: variables.$gutter-16;
    }
  }

  &__image-container {
    margin: 0 auto;
    order: 1;
    padding-top: variables.$gutter-40;
    @include breakpoints.screen(desktop-and-up) {
      padding-top: 0;
      padding-right: variables.$gutter-8;
      order: 0;
    }
  }

  &__title {
    color: colors.$white;
    margin-bottom: variables.$gutter-24;
  }

  &__tag {
    color: colors.$white;
    margin-bottom: variables.$gutter-24;
    font-size: 0.875rem;
    line-height: 16px;
    font-weight: 600;
  }

  &__text {
    color: colors.$white;
  }

  &__link {
    display: flex;
    justify-content: center;
    margin-top: variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      justify-content: flex-start;
    }
  }
}
