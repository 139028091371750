@use '../abstract/breakpoints';
@use '../abstract/colors';
@use '../abstract/shadows';
@use '../abstract/variables';
@use '../abstract/border';

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100vh;

  &.no-scroll {
    overflow-y: hidden;
  }
}

body {
  max-width: 100%;
}

[data-nextjs-dialog-overlay] {
  display: none;
}

.editing-mode {
  #header {
    position: relative;
  }

  .is-metadata-hint {
    width: 100%;
    padding: 5px;
    margin: 5px;
    border: border.$border-1-dashed-gray30;
  }

  div.container {
    .sc-jss-empty-placeholder {
      width: 100%;
    }
  }
}

main,
#storybook-root {
  padding-top: variables.$sm-header-height;
  @include breakpoints.screen(desktop-and-up) {
    padding-top: variables.$header-height;
  }
}

.footer {
  overflow-x: hidden;
}

@mixin component-with-paddings {
  padding-top: variables.$vertical-gutter !important;
  padding-bottom: variables.$vertical-gutter !important;
}

.component--with-paddings {
  @include component-with-paddings;
}

// needs to be remove in the future and refactor to have dropdowns with paddings options on all sections and set spacings there.
.compact-layout {
  .breadcrumbs {
    padding-top: variables.$gutter-40;
    padding-bottom: variables.$gutter-24;
  }

  .client-story-logo {
    padding-bottom: variables.$gutter-24;
  }

  .title {
    padding-bottom: variables.$gutter-24;
  }

  .tags-list {
    padding-bottom: variables.$gutter-24;
  }

  .socialShare{
    padding-bottom: variables.$gutter-24;
  }

  .posted-date, .estimated-time, .article-by, .dateTimeRangeLabel, .location {
    padding-bottom: variables.$gutter-24;
  }

  .field-content{
    padding-bottom: variables.$gutter-24;
  }
  
  .link__button-secondary, .link__button-primary {
    margin-bottom: variables.$gutter-40;

    @include breakpoints.screen(only-mobile) {
      width: 100%;
    }
  }

  .hide-block{
    display: none;
  }
}

:nth-last-child(1 of .compact-layout) {
  .tags-list, .socialShare {
    padding-bottom: variables.$gutter-40;
  }
}