@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use './../../sass/abstract/gradient';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/mixins';
@use '../../sass/base/typography';

.content-gallery--default {
  .content-gallery {
    &__mobile-cards-container {
      flex-wrap: nowrap;
      display: flex;
      column-gap: variables.$gutter-24;
      @include mixins.scrollable-container;
      margin-inline: -(variables.$gutter-24);
      padding: 0 variables.$gutter-24;

      &.single-card{
        justify-content: center;
      }

      @include breakpoints.screen(desktop-and-up) {
        margin-inline: 0;
        padding: 0;

      }
    }

    &__all-cards-container {
      margin-top: variables.$gutter-40;
      display: none;

      @include breakpoints.screen(tablet-and-up) {
        display: flex;
      }
    }

    &__desktop-cards-container {
      display: flex;
      margin-top: variables.$gutter-40;
      column-gap: variables.$gutter-24;
      overflow-x: auto;
      margin-inline: -(variables.$gutter-24);
      padding: 0 variables.$gutter-24;
      @include mixins.invisible-scroll;

      &.single-card{
        justify-content: center;
      }

      @include breakpoints.screen(desktop-and-up) {
        flex-wrap: wrap;
        justify-content: center;
        row-gap: variables.$gutter-40;
        margin-inline: 0;
        padding: 0;

        &--equal {
          padding: 0 variables.$gutter-4;
        }
      }
    }

    &__header-container {
      margin: 0 auto;
    }

    &__title {
      @include gradient.titleGradient;
      text-align: center;
      margin-bottom: variables.$gutter-16;

      @include breakpoints.screen(tablet-and-up) {
        margin-bottom: variables.$gutter-40 - variables.$gutter-10;
      }
    }

    &__body {
      text-align: center;
      width: 100%;
      margin: 0 auto;
      flex-shrink: 1;
    }

    &__main-container {
      display: block;

      @include breakpoints.screen(tablet-and-up) {
        display: none;
      }
    }

    &__category {
      margin-bottom: variables.$gutter-8;
      margin-top: variables.$gutter-40;
      line-height: typography.$line-height-24;
    }

    &__dropdown {
      margin-bottom: variables.$gutter-40;
    }

    &__card-container {
      width: 264px;
      display: inline-block;
    }

    &__link {
      margin-top: variables.$gutter-40;
      display: flex;
      justify-content: flex-start;

      @include breakpoints.screen(tablet-and-up) {
        justify-content: center;
      }
    }
  }
}
