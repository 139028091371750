@use '../../sass/abstract/variables';
@use '../../sass/abstract/colors';

.table {
  overflow: auto;
  width: 100%;

  table {
    border: 1px solid colors.$gray-30;
    height: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 1px;
    text-align: left;
  }

  caption {
    caption-side: top;
    text-align: left;
    margin-bottom: variables.$gutter-24;
  }

  th {
    border: 1px solid colors.$gray-30;
    background-color: colors.$gray-10;
    color: colors.$gray-80;
    padding: variables.$gutter-16 variables.$gutter-24;
  }

  td {
    border: 1px solid colors.$gray-30;
    background-color: colors.$white;
    color: colors.$gray-80;
    padding: variables.$gutter-24;
  }
}
