@use '../../sass/abstract/colors';
@use '../../sass/abstract/border';

.jump-link-anchor {
  display: block;
  position: relative;
  top: -320px;

  .editing-mode & {
    width: 100%;
    border: border.$border-2-dashed-gray70;
    top: 0;

    span {
      line-height: 28px;
    }
  }
}
