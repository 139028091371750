// Avanade.com Approved Colors

// ! Don't add new colors to this list without validating with the Design Team that the new color is valid and not by mistake !

$orange: #ff5800;
$dark-orange: #dc4600;

$gray-light: #f5f7fa;
$gray-10: #e5e5e5;
$gray-20: #ccc;
$gray-30: #b2b2b2;
$gray-40: #999;
$gray-50: #7f7f7f;
$gray-60: #666;
$gray-70: #4c4c4c;
$gray-80: #333;

$white: #fff;

$black: #000;

$pink: #ce0569;
$dark-pink: #9b044f;

$gold: #ffb314;
$dark-gold: #ce8c00;

$blue: #2a6dc8;
$dark-blue: #1a5181;

$scarlet: #c80000;
$dark-scarlet: #960000;

$red: #fa0000;

$green: #47800a;
$dark-green: #356008;

$purple: #890078;
$dark-purple: #67005a;

$aqua: #008376;
$dark-aqua: #1c6062;

/* Statuses */

// Warning/Error //
$status-red: $scarlet;
$status-red-5: #fcf2f2;

// Alert //
$status-amber: #ffaf38;
$status-amber-5: #fffbf5;

// Success/Complete //
$status-green: $green;
$status-green-5: #f6f8f3;

// Info/Update //
$status-purple: $purple;
$status-purple-15: #edd9eb;
