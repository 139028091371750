@use '../../sass/abstract/colors';
@use '../../sass/abstract/border';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/animations';
@use '../../sass/base/typography';
@use '../../sass/abstract/variables';
@use '../../sass/base/icons';

.accordion-with-images {
  &__item {
    border-top: border.$border-1-solid-gray20;
    text-align: left;

    &:last-child {
      border-bottom: border.$border-1-solid-gray20;
    }
  }

  &__item-title {
    text-align: left;
    margin-bottom: variables.$gutter-8;
  }

  &__item-header {
    display: flex;
    flex-wrap: wrap;
    color: colors.$gray-80;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: variables.$gutter-32;
    padding: variables.$gutter-32 0;
    cursor: pointer;

    @include breakpoints.screen(tablet-and-up) {
      min-height: 141px;
    }
    @include breakpoints.screen(only-mobile) {
      gap: variables.$gutter-16;
    }

    &:not(.accordion-with-images__item-header--open) {
      &:hover {
        .accordion-with-images__item-title {
          @include breakpoints.screen(desktop-and-up) {
            @include gradient.titleGradient;
          }
        }
      }
    }
  }

  &__header-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
  }

  &__text-container {
    height: 100%;
    width: 100%;
  }

  &__description {
    width: 100%;
    text-align: left;
  }

  &__header-container {
    display: flex;
    align-items: center;
    flex: 1;

    &--open {
      flex-basis: 100%;
      order: 1;
      align-items: stretch;

      &:hover {
        cursor: default;
      }
      @include breakpoints.screen(only-mobile) {
        flex-wrap: wrap;
        gap: variables.$gutter-24;
      }

      .accordion-with-images__image-container {
        @include breakpoints.screen(only-mobile) {
          width: 100%;
        }
      }

      .accordion-with-images__image {
        width: calc(150px + variables.$gutter-24);
        min-width: calc(150px + variables.$gutter-24);
        height: calc(150px + typography.$line-height-24);
        @include breakpoints.screen(tablet-and-up) {
          padding-bottom: variables.$gutter-24;
          width: calc(264px + variables.$gutter-24);
          min-width: calc(264px + variables.$gutter-24);
          height: calc(264px + typography.$line-height-24);
        }
        @include breakpoints.screen(only-mobile) {
          padding-right: 0;
          display: flex;
          width: 150px;
          min-width: 150px;
          height: 150px;
        }
      }

      .accordion-with-images__item-text {
        display: block;
      }

      .accordion-with-images__read-more-container {
        display: block;
      }

      .accordion-with-images__description {
        margin-bottom: variables.$gutter-16;
      }
    }
  }

  &__read-more-container {
    display: none;
  }

  &__image {
    margin-right: variables.$gutter-24;
    width: 93px;
    height: 93px;
    @include breakpoints.screen(only-mobile) {
      display: none;
    }
  }

  &__icon-container {
    transition: all 300ms linear;
    margin-left: auto;
    @include breakpoints.screen(tablet-and-up) {
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  &__icon {
    @include icons.icon-size-28;
    @include breakpoints.screen(tablet-and-up) {
      @include icons.icon-size-48;
    }
  }

  &__item-text {
    display: none;
    margin-bottom: variables.$gutter-16;
    text-align: left;
  }
}
