@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/base/typography';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/border';

.expert-card {
  width: 264px;
  max-width: 264px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  gap: variables.$gutter-8;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;

  &__default-container {
    .link {
      &:hover {
        @include gradient.titleGradient;

        .link__button-tertiary-arrow {
          display: none;
        }

        .link__button-tertiary-arrow-active {
          display: flex;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: variables.$gutter-16;
    width: 216px;
    align-items: center;
  }

  &__image {
    height: 216px;
    min-height: 216px;
    max-height: 216px;
    width: 216px;
    min-width: 216px;
    max-width: 216px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: variables.$gutter-8;
  }

  &__body {
    &:hover {
      text-decoration: none;
    }
  }

  &__link {
    align-self: center;
  }
}
