@use '../../sass/abstract/border';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/colors';
@use '../../sass/base/typography';

.top-five-card {
  color: colors.$gray-80;
  font-size: typography.$font-size-16;

  &__container {
    display: flex;
    flex-direction: column;
    gap: variables.$gutter-8;
    text-transform: capitalize;

    :lang(es-es) & {
      text-transform: none;
    }

    .dateTimeRangeLabel {
      color: colors.$gray-80;
    }

    @include breakpoints.screen(only-mobile) {
      border-bottom: border.$border-1-solid-gray20;
      padding-bottom: variables.$gutter-16;
      margin-bottom: variables.$gutter-24;
    }

    &:first-of-type {
      border-bottom: border.$border-1-solid-gray20;
      padding-bottom: variables.$gutter-16;
      margin-bottom: variables.$gutter-24;

      @include breakpoints.screen(tablet-and-up) {
        margin-left: variables.$gutter-24;
      }
    }
  }

  &__content-type {
    color: colors.$gray-60;
  }

  &__img {
    margin-bottom: variables.$gutter-16;
  }

  &__title {
    &:hover {
      @include gradient.titleGradient;
    }

    &--big {
      @include typography.h5;
    }

    &--small {
      @include typography.subheading1;
    }
  }

  &__link {
    text-decoration: none;
  }
}


.prod-mode .top-five-card
{
  &__container {
    text-transform: none;
  }
}

.editing-mode .top-five-card
{
  &__container {
    text-transform: none;
  }
}