@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border';
@use '../../../sass/abstract/gradient';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/mixins';
@use '../../../sass/abstract/animations';
@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/border-radius';

.double-accordion-component {
  &__item {
    border-radius: border-radius.$border-radius-20;
    overflow: hidden;
    margin-bottom: variables.$gutter-24;
    max-width: variables.$L-width;

    &:last-child {
      margin-bottom: 0;
    }

    &--open {
      border-top: border.$border-1-solid-gray20;
      border-radius: 0;

      .double-accordion-component__icon {
        transform: rotate(180deg);
        color: colors.$gray-80;
      }

      .double-accordion-component__item-title {
        color: colors.$gray-80;
      }

      .double-accordion-component__accordion-content {
        display: block;
      }

      .double-accordion-component__trigger {
        padding: variables.$gutter-24 0 variables.$gutter-64;
        background: transparent;
        @include breakpoints.screen(tablet-and-up) {
          padding: variables.$gutter-24 variables.$gutter-40 variables.$gutter-64;
        }
      }
    }
  }

  &__accordion-content {
    border-radius: border-radius.$border-radius-20 border-radius.$border-radius-20 0 0;
    display: none;

    &--open {
      margin-bottom: variables.$gutter-40;
    }
  }

  &__trigger {
    padding: variables.$gutter-24;
    text-align: left;
    color: colors.$gray-80;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: border-radius.$border-radius-20;
    overflow: hidden;
    @include gradient.horizontal-aurora-background;

    @include breakpoints.screen(tablet-and-up) {
      padding: variables.$gutter-24 variables.$gutter-40;
    }
  }

  &__item-title {
    color: colors.$white;
  }

  &__icon {
    transition: transform 300ms;
    flex-shrink: 0;
  }
}
