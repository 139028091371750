@use '../../sass/abstract/breakpoints';

.three-up-pages--align-right {
  .three-up-pages__secondary-page-container {
    .reference {
      @include breakpoints.screen(desktop-and-up) {
        flex-direction: row-reverse;
      }
    }
  }

  .three-up-pages__main-container {
    @include breakpoints.screen(desktop-and-up) {
      flex-direction: row-reverse;
    }
  }
}
