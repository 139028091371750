@use './../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/mixins';

.container-gray-background .full-bleed-banner-mirror-tertiary .container 
{ 
  padding: 0; 
}

.full-bleed-banner-mirror-tertiary {
  &__text-container {
    text-align: center;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
      padding-left: variables.$gutter-16;
    }
  }

  &__image-container {
    margin: 0 auto;
    order: 1;
    padding-top: variables.$gutter-40;
    @include breakpoints.screen(desktop-and-up) {
      padding-top: 0;
      padding-right: variables.$gutter-8;
    }
    @include breakpoints.screen(desktop-and-up) {
      order: 0;
    }
  }

  &__title, &__tag {
    color: colors.$gray-80;
    margin-bottom: variables.$gutter-24;
  }

  &__text {
    color: colors.$gray-80;
  }

  &__link {
    display: flex;
    justify-content: center;
    margin-top: variables.$gutter-24;

    @include breakpoints.screen(desktop-and-up) {
      justify-content: flex-start;
    }
  }
}
