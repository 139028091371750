@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/mixins.scss';
@use '../../../../sass/abstract/breakpoints';

.full-bleed-hero-ava-styling{
 .h1{
    padding:0;
    margin-bottom: 24px;
    @media screen and (max-width: 850px) {
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
    }
  }

  .full-bleed-hero__link{
    margin-top:0;
    @media screen and (max-width: 850px) {
      display: inline-flex;
    }
  }

  .icon--arrow-right::before{
    top: 2px;
    position: relative;
  }
   
    .full-bleed-hero__text{
      margin-bottom: 24px;
      @media screen and (max-width: 850px) {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .full-bleed-hero__img-container{
    img{
      @include breakpoints.screen(only-mobile) {
      height:207px;
      width:max-content;
    }
  }
}
}
