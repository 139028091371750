@use '../../../src/sass/abstract/colors';
@use '../../../src/sass/base/typography';
@use '../../../src/sass/abstract/border-radius';
@use '../../../src/sass/abstract/breakpoints';
@use '../../../src/sass/base/icons';
@use '../../../src/components/shared/nextImg/NextImageWithNullFallback.scss';
@use '../../../src/sass/abstract/variables';

.mapbrightcovecom {
  display: none;
}

.single-video {
  .video-js {
    background-color: transparent;
    width: 100%;
    height: auto;
    @include NextImageWithNullFallback.next-image-container;
    @include NextImageWithNullFallback.next-image-container-16-9;
    border-radius: border-radius.$border-radius-30;

    &.vjs-fullscreen {
      border-radius: 0;

      .vjs-tech {
        height: auto !important;
        position: absolute;
        top: 50%;
        right: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        line-height: 64px;
        @include breakpoints.screen(only-mobile) {
          height: auto;
          width: 100%;
        }
      }
    }

    .modal & {
      width: 100%;
      max-width: 1128px;
      height: auto;
      max-height: 635px;
      border-radius: 0;
    }

    video {
      overflow: hidden;
      @include NextImageWithNullFallback.next-image;
    }

    .vjs-picture-in-picture-control {
      @include breakpoints.screen(only-mobile) {
        display: none;
      }
    }

    // hide 'about brightcove menu' on right click
    .vjs-menu.vjs-contextmenu-ui-menu {
      display: none;
    }

    .vjs-big-play-button {
      border: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: border-radius.$border-radius-half;
      margin-left: 0;
      margin-top: 0;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;

      &:hover {
        background-color: colors.$orange;
        opacity: 1;
      }

      .vjs-icon-placeholder {
        display: flex;
        border-radius: border-radius.$border-radius-half;

        &::before {
          content: '';
          display: block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: inherit;
          height: 100%;
          width: 100%;
          border-radius: border-radius.$border-radius-half;
        }
      }

      &[type='button'] {
        .vjs-icon-placeholder::before {
          @include icons.background-icon('play-brightcove');
          background-size: 492%;
          background-position: 73% 100%;
        }
      }
    }

    .vjs-control-bar {
      height: 64px;
      border-radius: border-radius.$border-radius-17;
      background-color: colors.$gray-80;
      margin: 0 variables.$gutter-16 variables.$gutter-16;
      width: calc(100% - 32px);

      @include breakpoints.screen(tablet-and-up) {
        padding: 0 variables.$gutter-12;
      }

      @include breakpoints.screen(only-mobile) {
        height: variables.$gutter-32;
        margin: 0 variables.$gutter-8 variables.$gutter-8;
        border-radius: border-radius.$border-radius-8;
        padding: variables.$gutter-8 variables.$gutter-20;
        width: calc(100% - variables.$gutter-16);
        align-items: center;
        gap: variables.$gutter-20;
      }

      // controlbar valume panel
      .vjs-control.vjs-volume-control {
        .vjs-slider,
        .vjs-load-progress {
          background: colors.$gray-60;
          border-radius: border-radius.$border-radius-13;

          &:hover,
          &:focus {
            text-shadow: none;
          }

          .vjs-volume-level {
            border-radius: border-radius.$border-radius-13;
          }
        }
      }

      // controlbar progress panel
      .vjs-control.vjs-progress-control {
        .vjs-progress-holder .vjs-play-progress {
          border-radius: border-radius.$border-radius-13;
        }

        .vjs-load-progress div {
          background: colors.$gray-60;
          border-radius: border-radius.$border-radius-13;
        }

        .vjs-slider,
        .vjs-load-progress {
          background-color: colors.$gray-60;
          border-radius: border-radius.$border-radius-13;

          .vjs-mouse-display {
            border-radius: border-radius.$border-radius-13;
          }
        }
      }

      .vjs-time-control.vjs-time-divider {
        line-height: 60px;
      }

      .vjs-control {
        align-items: center;

        @include breakpoints.screen(only-mobile) {
          height: 16px;
          width: 16px;
        }
        @include breakpoints.screen(tablet-and-up) {
          width: variables.$gutter-56 - variables.$gutter-4;
        }
        // controlbar icons glow
        &:hover,
        &:focus {
          text-shadow: none;
        }

        &.vjs-time-control {
          margin: auto 0;
          @include typography.body2;
          line-height: 64px;

          .vjs-current-time-display,
          .vjs-duration-display {
            display: flex;
            align-items: center;
          }
        }

        &.vjs-remaining-time {
          padding: 0;
        }

        &.vjs-button {
          .vjs-icon-placeholder {
            &::before {
              content: '';
              display: block;
              background-repeat: no-repeat;
              background-position: center;
              background-size: inherit;
              height: 100%;
              width: 100%;

              @include breakpoints.screen(only-mobile) {
                background-size: variables.$gutter-16;
                @include icons.icon-size-16;
              }
            }
          }

          // Next button
          &.vjs-next-button {
            display: none;
          }

          // play and pause button
          &.vjs-play-control {
            &[title='Play'],
            &.vjs-playing {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('pause');
                background-size: 946%;
                background-position: 21% 41%;
                // background-position: -95px -132px;
              }
            }

            &.vjs-paused {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('play');

                background-size: 583%;
                background-position: 72% 100%;
                // background-position: -376px -314px;
                @include breakpoints.screen(only-mobile) {
                  background-size: 800%;
                  background-position: 71% 93%;
                }
              }
            }
          }

          // volume button
          &.vjs-mute-control {
            &.vjs-vol-0 {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('mute');
                background-size: 1150%;
                background-position: 62% 41%;
              }
            }

            &.vjs-vol-1 {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('volume-1');
              }
            }

            &.vjs-vol-2 {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('volume-2');
              }
            }

            &.vjs-vol-3 {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('volume-3');
                background-size: 1150%;
                background-position: 2.8% 54.3%;
                @include breakpoints.screen(only-mobile) {
                  background-size: 1500%;
                  background-position: 2% 54%;
                }
              }
            }
          }

          &.vjs-share-control {
            .vjs-icon-placeholder::before {
              @include icons.background-icon('share');
              background-size: 1300%;
              background-position: 32.5% 41.5%;

              @include breakpoints.screen(only-mobile) {
                background-size: 1500%;
                background-position: 32% 42%;
              }
            }
          }

          &.vjs-subs-caps-button {
            .vjs-icon-placeholder::before {
              @include icons.background-icon('caption');
              background-size: 1500%;
              background-position: 23% 17%;

              @include breakpoints.screen(tablet-and-up) {
                background-size: 1400%;
                background-position: 22.6% 15.7%;
              }
            }
            display: block !important;

            .vjs-menu-content {
              bottom: 0;
              @include breakpoints.screen(tablet-and-up) {
                bottom: variables.$gutter-40;
              }
            }
          }

          &.vjs-picture-in-picture-control {
            &[title='Picture-in-Picture'] {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('picture-in-picture');
                background-size: 1300%;
                background-position: 71.4% 54%;
              }
            }

            &[title='Exit Picture-in-Picture'] {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('picture-in-picture-exit');
                background-size: 1300%;
                background-position: 61.7% 54%;
              }
            }
          }

          // fullscreen button
          &.vjs-fullscreen-control {
            &[title='Fullscreen'] {
              .vjs-icon-placeholder::before {
                @include icons.background-icon('full-screen');
                background-size: 1330%;
                background-position: 42% 54%;
                @include breakpoints.screen(only-mobile) {
                  background-size: 1500%;
                  background-position: 43% 55%;
                }
              }
            }

            &[title='Exit Fullscreen'] {
              .vjs-icon-placeholder::before {
                // @include icons.background-icon('minimize-screen');
                background-image: url('../../assets/icons/minimize-screen.svg');
              }
            }
          }
        }
      }
    }

    .vjs-text-track-display {
      display: block !important;
      @include breakpoints.screen(desktop-and-up) {
        bottom: variables.$gutter-72;
      }
    }
  }

  // controlbar position
  .bc-player-SJaXcOpAb_default.not-hover.vjs-has-started.vjs-paused.vjs-user-active
    .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
  .bc-player-SJaXcOpAb_default.not-hover.vjs-has-started.vjs-paused.vjs-user-inactive
    .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
  .bc-player-SJaXcOpAb_default.not-hover.vjs-has-started.vjs-playing.vjs-user-active
    .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
  .bc-player-SJaXcOpAb_default.not-hover.vjs-has-started.vjs-playing.vjs-user-inactive
    .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible),
  .bc-player-SJaXcOpAb_default.vjs-has-started.vjs-paused.vjs-ad-playing.vjs-user-inactive
    .vjs-control-bar,
  .bc-player-SJaXcOpAb_default.vjs-has-started.vjs-playing.vjs-user-inactive
    .vjs-control-bar:not(.vjs-focus-within):not(.vjs-control-bar-visible) {
    transform: translateY(88px);
  }

  .video-tab {
    min-height: 185px;
    @include breakpoints.screen(tablet-and-up) {
      min-height: 270px;
    }
    @include breakpoints.screen(desktop-and-up) {
      min-height: 370px;
    }
  }
}

.hide-block {
  display: none !important;
}

.before-video-js {
  width: 100%;
  height: 100%;
  min-height: 185px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include breakpoints.screen(tablet-and-up) {
    min-height: 270px;
  }
  @include breakpoints.screen(desktop-and-up) {
    min-height: 370px;
  }

  &__thumbnail {
    width: 100%;
    height: 100%;
    border-radius: border-radius.$border-radius-30;
    position: relative;

    img {
      position: relative !important;
    }

    &[alt]::after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: colors.$white;
      text-align: center;
      content: attr(alt);
    }
  }

  &__play-button {
    border: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: border-radius.$border-radius-half;
    margin-left: 0;
    margin-top: 0;
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 9;

    &:hover {
      background-color: colors.$orange;
      opacity: 1;
    }

    &__icon {
      display: flex;
      border-radius: border-radius.$border-radius-half;
      height: 100%;

      &::before {
        content: '';
        display: block;
        background-repeat: no-repeat;
        background-position: center;
        background-size: inherit;
        height: 100%;
        width: 100%;
        border-radius: border-radius.$border-radius-half;
        position: absolute;
        z-index: 10;
      }
    }

    &[type='button'] {
      .before-video-js__play-button__icon {
        &::before {
          @include icons.background-icon('play-brightcove');
          background-size: 492%;
          background-position: 73% 100%;
        }
      }
    }
  }
}

.modal {
  .before-video-js {
    &__thumbnail {
      border-radius: 0;
    }
  }
}
