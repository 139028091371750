@use '../../sass/abstract/gradient';
@use '../../sass/base/typography';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/animations';
@use '../../sass/abstract/variables';

.three-up-pages {
  .reference {
    &__category {
      font-size: typography.$font-size-16;
      line-height: typography.$line-height-24;
      font-weight: typography.$font-weight-700;
    }

    &__link-container {
      margin-top: variables.$gutter-16;
      @include breakpoints.screen(desktop-and-up) {
        display: flex;
      }
    }

    &__image-container {
      .next-image {
        @include animations.image-zoom-out;

        &:hover {
          @include animations.image-zoom-in;
        }
      }
    }
  }

  &__main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: variables.$gutter-24;
    padding-bottom: variables.$gutter-40;
    align-items: stretch;
    gap: variables.$gutter-16;

    @include breakpoints.screen(only-mobile) {
      gap: variables.$gutter-40;
    }

    @include breakpoints.screen(tablet-and-up) {
      padding-bottom: variables.$gutter-40;
    }

    @include breakpoints.screen(desktop-and-up) {
      display: flex;
      flex-direction: row;
      padding-top: variables.$gutter-80;
      padding-bottom: variables.$gutter-80;
      gap: variables.$gutter-24;
    }
  }

  &__primary-page {
    .reference {
      display: flex;
      flex-direction: column;
      gap: variables.$gutter-16;

      &__image-container {
        height: 288px;
        min-height: 288px;
        max-height: 288px;
      }

      @include breakpoints.screen(desktop-and-up) {
        &__image-container {
          width: 456px;
          min-width: 456px;
          max-width: 456px;
          height: 456px;
          min-height: 456px;
          max-height: 456px;
        }
      }

      &__title {
        display: inline;
        @include typography.h5;
        @include mixins.disable-link-click;
      }

      &__content-container {
        gap: variables.$gutter-16;
      }
    }
  }

  &__secondary-page-container {
    display: flex;
    flex-direction: column;
    gap: variables.$gutter-40;

    @include breakpoints.screen(tablet-and-up) {
      flex-direction: row;
      gap: variables.$gutter-16;
    }

    @include breakpoints.screen(desktop-and-up) {
      flex-direction: column;
      gap: variables.$gutter-40;
    }

    .reference {
      display: flex;
      flex-direction: column;
      gap: variables.$gutter-16;
      width: 100%;

      @include breakpoints.screen(desktop-and-up) {
        flex-direction: row;
        gap: variables.$gutter-24;
      }

      &__content-container {
        gap: variables.$gutter-16;
        width: 100%;
      }

      &__title {
        display: inline;
        overflow-y: auto;
        @include mixins.disable-link-click;
      }

      &__text {
        overflow: hidden;
      }

      &__image-container {
        height: 288px;
        max-height: 288px;
        min-height: 288px;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        @include breakpoints.screen(desktop-and-up) {
          width: 264px;
          min-width: 264px;
          max-width: 264px;
          height: 264px;
          max-height: 264px;
          min-height: 264px;
        }
      }
    }
  }

  &__title-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @include gradient.titleGradient;
    text-align: center;
  }

  &__view-all-container {
    display: flex;
    align-items: center;

    @include breakpoints.screen(desktop-and-up) {
      justify-content: center;
    }
  }
}
