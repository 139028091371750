@use '../../sass/base/typography';

.posted-date {
  line-height: typography.$line-height-24;
  font-weight: typography.$font-weight-400;

  &__title {
    font-size: typography.$font-size-16;
  }

  &__date {
    font-weight: typography.$font-weight-700;
  }
}
