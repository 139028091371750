@use '../../../sass/abstract/animations';
@use '../../../sass/abstract/border';
@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/mixins';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/breakpoints';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/abstract/variables';
$spriteImageIcons: '../../../assets/icons/spriteIcon.svg';

.office-directory {
  margin-bottom: variables.$gutter-40;

  @include breakpoints.screen(only-mobile) {
    margin-bottom: 0;
  }

  @include breakpoints.screen(tablet-and-up) {
    border-bottom: border.$border-1-solid-gray20;
    padding-bottom: 80px !important;
  }

  &__container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    row-gap: 0;
    column-gap: variables.$gutter-40;

    @include breakpoints.screen(tablet-and-up) {
      row-gap: variables.$gutter-40;
    }
    @include breakpoints.screen(desktop-and-up) {
      column-gap: 23px;
    }
  }

  &__contacts-align {
    float: left;
    clear: left;
    @include breakpoints.screen(only-mobile) {
      &:last-child {
        padding-bottom: variables.$gutter-40;
      }
    }
  }

  &__dropdown-row {
    margin-bottom: variables.$gutter-40;
    justify-content: center;
  }

  &__dropdown-title {
    font-size: typography.$font-size-16;
    font-weight: typography.$font-weight-400;
    margin-bottom: variables.$gutter-8;
  }

  .Dropdown-control,
  .Dropdown-option {
    font-family: typography.$ff-primary;
  }

  .Dropdown-control {
    background-image: url('../../../assets/icons/Location.svg');
    background-position: 16px;
    background-repeat: no-repeat;
    padding-left: variables.$gutter-40 + variables.$gutter-8;
  }

  &__item {
    max-width: 264px;
    width: 100%;
    height: 360px;
    padding: variables.$gutter-40 variables.$gutter-32;
    border-radius: border-radius.$border-radius-30;
    background-color: colors.$gray-light;

    @include breakpoints.screen(only-mobile) {
      max-width: 100%;
      width: 100%;
      background-color: colors.$white;
      height: 100%;
      border-radius: 0;
      padding: variables.$gutter-24 0 0 0;
      border-top: border.$border-1-solid-gray20;

      &:last-child {
        border-bottom: border.$border-1-solid-gray20;
        padding-bottom: variables.$gutter-40;
      }
    }
  }

  &__country-name {
    text-align: left;
    cursor: auto;
  }

  &__office-name {
    margin-bottom: variables.$gutter-16;

    &--subheading1 {
      @include breakpoints.screen(only-mobile) {
        @include typography.subheading1;
      }
    }
  }

  &__item-header {
    margin-bottom: variables.$gutter-16;
    transition: margin-bottom 0.3s ease-in-out;

    @include breakpoints.screen(only-mobile) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: variables.$gutter-40;
    }

    &--open {
      @include breakpoints.screen(only-mobile) {
        margin-bottom: variables.$gutter-24;
      }
    }
  }

  &__icon {
    display: none;

    @include breakpoints.screen(only-mobile) {
      display: block;
      transition: all 300ms linear;
      margin-left: auto;

      &--rotate {
        transform: rotate(180deg);
      }

      .icon--chevron-down {
        &::before {
          background-image: url($spriteImageIcons);
          background-position: 42.3% 18%;
          background-size: 2300%;
        }
      }
    }
  }

  &__address {
    margin-bottom: variables.$gutter-32;
    @include breakpoints.screen(only-mobile) {
      margin-bottom: variables.$gutter-40;
    }
  }

  &__item-content {
    @include breakpoints.screen(only-mobile) {
      @include animations.animate-height-closed;

      &--open {
        @include animations.animate-height-open;
      }
    }
  }

  &__inside-content {
    @include breakpoints.screen(tablet-and-up) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 237px;
    }
    @include breakpoints.screen(only-mobile) {
      @include animations.animate-height-hidden;
    }
  }
}
