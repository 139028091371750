@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/colors';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/variables';
@use '../../sass/layout/layout';
@use '../../components/shared/nextImg/NextImageWithNullFallback.scss';

.contact-expert {
  @include layout.component-with-paddings;
  display: block;

  &.hide-contact-expert {
    display: none;
  }

  .offset-lg-1 {
    margin-left: 0;

    @include breakpoints.screen(desktop-and-up) {
      margin-left: auto;
    }
  }

  .next-image__container {
    margin: 0 auto;
    @include NextImageWithNullFallback.next-image-container-square;

    @include breakpoints.screen(only-mobile) {
      display: none;
    }
    @include breakpoints.screen(tablet-and-up) {
      width: 200px;
      min-width: 200px;
      max-width: 200px;
      height: 209px;
      min-height: 209px;
      max-height: 209px;
    }
    @include breakpoints.screen(desktop-and-up) {
      width: 360px;
      min-width: 360px;
      max-width: 360px;
      height: 375px;
      min-height: 375px;
      max-height: 375px;
    }

    img{
      position:relative !important;
    }
  }

  form {
    margin: 0 auto;

    @include breakpoints.screen(tablet-and-up) {
      max-width: 468px;
    }
    @include breakpoints.screen(desktop-and-up) {
      max-width: 100%;
    }
  }

  form.mktoForm .mktoButtonWrap.mktoPaperMashup .mktoButton {
    margin-top: 14px;
    @include breakpoints.screen(only-mobile) {
      width: 100%;
    }
  }

  .rich-text h2 {
    margin-bottom: variables.$gutter-40 - variables.$gutter-10;
    text-align: center;

    @include breakpoints.screen(desktop-and-up) {
      text-align: left;
    }
  }

  .mktoButtonRow {
    display: flex;
    justify-content: center;
    @include breakpoints.screen(desktop-and-up) {
      justify-content: flex-start;
    }
  }

  .column-splitter {
    align-items: center;
    row-gap: variables.$gutter-24;
    flex-direction: column;

    @include breakpoints.screen(desktop-and-up) {
      align-items: flex-start;
      flex-direction: row;
      row-gap: 0;
    }
  }
}
