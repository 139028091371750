@use '../../../sass/abstract/colors';
@use '../../../sass/abstract/border';
@use '../../../sass/base/typography';
@use '../../../sass/abstract/border-radius';
@use '../../../sass/abstract/variables';
@use '../../../sass/abstract/z-index';

.dropdown {
  .is-open {
    .Dropdown-arrow {
      transform: rotate(180deg);
    }
  }

  &__container {
    .Dropdown-control,
    .Dropdown-menu {
      border-radius: border-radius.$border-radius-20;
      border: border.$border-1-solid-gray40;
      color: colors.$gray-80;
      font-size: typography.$font-size-16;
      font-family: typography.$ff-primary;
      line-height: typography.$line-height-24;
    }

    .Dropdown-control {
      height: variables.$gutter-40;
      display: flex;
      align-items: center;
      padding: variables.$gutter-8 variables.$gutter-16;
    }

    .Dropdown-menu {
      z-index: z-index.$z-index-10;
      padding: variables.$gutter-16 0;

      &::-webkit-scrollbar {
        width: 23px;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        height: 132px;
        box-shadow: inset 20px 20px 20px 0 colors.$gray-10;
        border: border.$border-8-solid-transparent;
        border-radius: border-radius.$border-radius-20;
      }
    }

    .Dropdown-option {
      padding: variables.$gutter-8 variables.$gutter-16;
      color: colors.$gray-80;
      font-size: typography.$font-size-16;
      font-family: typography.$ff-primary;
      line-height: typography.$line-height-24;

      &.is-selected {
        background-color: colors.$gray-light;
      }

      &:hover {
        background-color: colors.$gray-light;
      }
    }

    .Dropdown-arrow {
      content: url('../../../assets/icons/chevron-down.svg');
      width: 24px;
      border: 0;
      height: 24px;
      color: colors.$gray-80;
      top: 8px;
      right: variables.$gutter-16;
      cursor: pointer;
      transition: transform 300ms;
    }
  }
}
