@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/mixins';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/base/typography';
 
.section-header-tertiary {
  &--hide {
    display: none;
  }
  
  &__main-container {
    display: flex;
    gap: variables.$gutter-40;
    flex-direction: column;
    @include breakpoints.screen(desktop-and-up) {
      flex-direction: row;
      gap: variables.$gutter-24;
    }
  }
 
  &__title-container {
    width: 100%;
 
    @include breakpoints.screen(desktop-and-up) {
      width: 50%;
    }
  }
 
  &__subtitle {
    @include gradient.titleGradient;
  }
 
  &__description-container {
    width: 100%;
 
    @include breakpoints.screen(desktop-and-up) {
      width: 50%;
    }
 
    @include breakpoints.screen(only-mobile) {
      > .section-header-default__link .link {
        width: 100%;
      }
    }
 
    @include breakpoints.screen(tablet-and-up) {
      > .section-header-default__link .link {
        margin-top: 0;
      }
    }
  }
 
  &__title {
    margin-bottom: variables.$gutter-24;
 
    @include breakpoints.screen(only-mobile) {
      font-size: typography.$font-size-20;
      line-height: typography.$line-height-base;
    }
  }
 
  &__description {
    margin-bottom: variables.$gutter-24;
  }
}
