@use '../../sass/abstract/colors';
@use './../../sass/abstract/gradient';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/variables';

.text-blocks {
  width: 100%;

  &--center-aligned {
    .text-blocks {
      &__block:nth-child(3) {
        display: none;
      }

      &__blocks-container {
        width: 100%;

        @include breakpoints.screen(tablet-and-up) {
          width: 448px;
        }

        @include breakpoints.screen(desktop-and-up) {
          width: 734px;
        }
      }

      &__container {
        @include breakpoints.screen(tablet-and-up) {
          align-items: center;
          display: flex;
          flex-direction: column;
        }
      }

      &__text-container {
        width: 100%;
      }
    }
  }

  &--default {
    .text-blocks__title {
      @include gradient.titleGradient;
    }
  }

  &__container {
    padding: 0;
  }

  &__title-container {
    text-align: center;
    margin-bottom: variables.$gutter-24;

    @include breakpoints.screen(tablet-and-up) {
      margin-bottom: variables.$gutter-40;
    }
  }

  &__text-container {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: variables.$gutter-80;
  }

  &__blocks-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoints.screen(tablet-and-up) {
      flex-direction: row;
    }
  }

  &__block {
    display: flex;
    flex: 1;
    position: relative;

    &:not(:first-child) {
      margin: variables.$gutter-40 0 0 0;
      padding: variables.$gutter-40 0 0 0;

      @include breakpoints.screen(tablet-and-up) {
        margin: 0 0 0 variables.$gutter-32;
        padding: 0 0 0 variables.$gutter-32;
      }

      .text-block {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          height: 1px;
          width: 100%;
          background-color: colors.$gray-20;

          @include breakpoints.screen(tablet-and-up) {
            height: 100%;
            width: 1px;
            top: 0;
          }
        }
      }
    }

    &:last-child {
      @include breakpoints.screen(only-mobile) {
        padding-bottom: variables.$gutter-40;
        margin-bottom: -(variables.$gutter-40);

        .text-block {
          &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            height: 1px;
            width: 100%;
            background-color: colors.$gray-20;
          }
        }
      }
    }

    &.no-first-divider,
    &.no-second-divider {
      &::before {
        display: none;
      }

      @include breakpoints.screen(only-mobile) {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}

body
  section:has(.text-blocks)
  ~ section:has(.text-blocks):not(
    :has(.text-blocks__title-container:not(:empty), .text-blocks__text-container:not(:empty))
  )
  .text-blocks__block:first-child {
  @include breakpoints.screen(only-mobile) {
    padding: variables.$gutter-40 0 0 0;
    margin-top: -40px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      height: 1px;
      width: 100%;
      background-color: colors.$gray-20;
    }
  }
}

.text-blocks__block:empty {
  @include breakpoints.screen(only-mobile) {
    padding-bottom: 0 !important;
    display: none;
  }
}
