
.shareOptions{
    flex-direction: column;
    background: #fff;
    gap: 16px;
    padding: 17px 13px;
    position: absolute;
    right: 21px;
    bottom: 30px;
    display: none;
}

.visible{
    display: flex;
}