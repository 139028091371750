@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';

.title-banner {
  text-align: center;
  display: flex;
  gap: variables.$gutter-24;
  flex-direction: column;

  @include breakpoints.screen(tablet-and-up) {
      gap: variables.$gutter-40;
    }

  &__title {
    @include gradient.titleGradient;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
