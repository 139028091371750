@use '../../sass/abstract/colors';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/mixins';
@use '../../sass/abstract/animations';
@use '../../sass/abstract/variables';
@use '../../sass/base/typography';
@use '../../sass/abstract/border';

$spriteImageIcons: '../../assets/icons/spriteIcon.svg';

.accordion {
  margin: 0 auto;
  font-size: typography.$font-size-14;
  line-height: typography.$line-height-16;
  font-weight: typography.$font-weight-600;

  &__image-container {
    margin-right: variables.$gutter-24;
  }

  &__image {
    width: 93px;
    height: 93px;
  }

  &__inside-content {
    @include animations.animate-height-hidden;
  }

  &__header-container {
    display: flex;
    align-items: center;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;

    &--open {
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }
  }

  &__container {
    max-width: variables.$L-width;
    display: flex;
    flex-direction: column;
  }

  &__item {
    border-top: border.$border-1-solid-gray20;
    text-align: left;
  }

  &__item-header {
    color: colors.$gray-80;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: variables.$gutter-24;
    padding: variables.$gutter-24 0;
    cursor: pointer;

    &:hover {
      @include gradient.titleGradient;
    }

    @include breakpoints.screen(only-mobile) {
      gap: variables.$gutter-16;
    }
  }

  &__item-content {
    @include animations.animate-height-closed;

    &--open {
      @include animations.animate-height-open;
    }
  }

  &__item-text {
    padding-right: variables.$gutter-48;
    padding-bottom: variables.$gutter-40;
    @include animations.animate-height-hidden;
    @include breakpoints.screen(only-mobile) {
      padding-right: variables.$gutter-40;
    }
  }

  &__icon {
    transition: all 300ms linear;
    margin-left: auto;

    .icon--chevron-down {
      &::before {
        background-image: url($spriteImageIcons);
        background-position: -182px -58px;
      }
    }

    &--rotate {
      transform: rotate(180deg);
    }
  }

  &__inside-image {
    padding-bottom: variables.$gutter-40;
    @include animations.animate-height-hidden;
    width: 264px;
    min-width: 264px;
    height: 264px;
    margin: 0 auto;
    @include breakpoints.screen(tablet-and-up) {
      margin: 0 variables.$gutter-24 0 auto;
    }
  }
}
