@use '../../../../sass/abstract/border-radius';
@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/border';
@use '../../../../sass/abstract/gradient';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/z-index';
@use '../../../../sass/base/typography';

$spriteImageIcons: '../../../../assets/icons/spriteIcon.svg';

.custom-facet {
  border-radius: border-radius.$border-radius-20;
  margin-bottom: variables.$gutter-24;
  padding: variables.$gutter-8 variables.$gutter-16;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: auto;
  background: colors.$white;
  border: border.$border-1-solid-gray30;

  &--empty {
    cursor: default;
    background: colors.$gray-light;
    color: colors.$gray-30;
  }

  &--selected {
    @include border.aurora-border;
  }

  &__icon {
    transition: all 300ms linear;
    margin-left: auto;

    &--rotate {
      transform: rotate(180deg);
    }

    .icon--chevron-down {
      &::before {
        background-image: url($spriteImageIcons);
        background-position: -265px -58px;
        background-size: 1900%;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__list {
    position: absolute;
    max-height: 272px;
    overflow-y: auto;
    overflow-x: hidden;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: variables.$gutter-4;
    background-color: colors.$white;
    border-radius: border-radius.$border-radius-20;
    padding: variables.$gutter-16 0;
    border: border.$border-1-solid-gray30;
    z-index: z-index.$z-index-10;

    &::-webkit-scrollbar {
      width: 30px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 26px 26px transparent;
      border: solid 26px transparent;
    }

    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 26px 26px colors.$gray-20;
      border: solid 12px transparent;
      border-radius: 26px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: variables.$gutter-8 variables.$gutter-16;

    &:hover {
      background-color: colors.$gray-light;
    }
  }
}
