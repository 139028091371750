@use '../../sass/abstract/z-index';
@use '../../sass/abstract/blur';
@use '../../sass/abstract/variables';
@use '../../sass/abstract/gradient';
@use '../../sass/abstract/animations';
@use '../../sass/abstract/breakpoints';
@use '../../sass/abstract/colors';
@use '../../sass/base/typography';
@use '../../sass/abstract/border';

.client-story-card,
.insight-story-card,
.events-webinar-card {
  padding: 0 variables.$gutter-12 variables.$gutter-40 variables.$gutter-12;
  height: 100%;

  &:hover {
    .client-story-card__title,
    .insight-story-card__title,
    .events-webinar-card__title {
      @include gradient.titleGradient;
    }

    .client-story-card__link,
    .insight-story-card__link,
    .events-webinar-card__link {
      cursor: pointer;
    }

    .client-story-card__main-image,
    .insight-story-card__main-image,
    .events-webinar-card__main-image {
      @include animations.image-zoom-in;
    }
  }

  &__link {
    text-decoration: auto;
    display: block;
    height: 100%;
  }

  &__main-image {
    @include breakpoints.screen(desktop-and-up) {
      height: 264px;
    }

    height: 288px;
    max-height: 288px;
    @include animations.image-zoom-out;
  }

  &__image-container {
    overflow: hidden;
    position: relative;
    margin-bottom: variables.$gutter-16;
  }

  &__logo-container {
    position: absolute;
    padding: variables.$gutter-8;
    width: 100%;
    height: 88px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: z-index.$z-index-10;
    @include blur.blur-back-10;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__industry-container {
    margin-bottom: variables.$gutter-8;
  }

  &__tag-container {
    margin-bottom: variables.$gutter-8;
  }

  &__title-container {
    margin-bottom: variables.$gutter-8;

    .link__button-tertiary-text {
      font-size: typography.$font-size-18;
    }
  }

  &__content-type-container {
    margin-top: variables.$gutter-8;
    color: colors.$gray-60;
  }

  &__date-container {
    margin-bottom: variables.$gutter-8;
  }

  &__event-type-container {
    margin-top: variables.$gutter-8;
    color: colors.$gray-60;
  }
}

.news-room-card {
  display: flex;
  flex-direction: column;
  width: 264px;
  max-width: 264px;
  min-height: 464px;
  height: 100%;
  padding: variables.$gutter-24;
  align-items: flex-start;
  gap: variables.$gutter-24;
  box-sizing: border-box;
  border: border.$border-3-solid-transparent;
  border-radius: variables.$gutter-24;
  background-color: colors.$gray-light;
  position: relative;
  background-clip: padding-box;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    border: border.$border-3-solid-transparent;
    @include gradient.border-gradient;
    text-decoration: none;

    .news-room-card__title {
      @include gradient.titleGradient;
    }

    .link {
      @include gradient.titleGradient;

      .link__button-tertiary-arrow {
        display: none;
      }

      .link__button-tertiary-arrow-active {
        display: flex;
      }
    }
  }

  &__posted-date {
    @include typography.body2;
    margin-bottom: variables.$gutter-24;
    text-transform: capitalize;

    .posted-date__title {
      display: none;
    }

    .posted-date__date {
      font-weight: 400;
    }

    :lang(es-es) & {
      text-transform: none !important;
    }
  }
}
