@use '../../../../sass/abstract/colors';
@use '../../../../sass/abstract/breakpoints';
@use '../../../../sass/abstract/variables';
@use '../../../../sass/abstract/z-index';

$background-wave: '../../../../assets/images/fat-wave-opacity.svg';

.statement {
  max-width: 1728px;
  height: 740px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  background-color: colors.$gray-50;

  &__container {
    z-index: z-index.$z-index-10;
    width: variables.$L-width;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 0 variables.$gutter-24;
    justify-content: center;

    @include breakpoints.screen(desktop-and-up) {
      justify-content: flex-start;
    }
  }

  &__text-container {
    color: colors.$white;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;

    @include breakpoints.screen(desktop-and-up) {
      max-width: 552px;
    }
  }

  &__title {
    margin-bottom: variables.$gutter-24;
  }

  &__background-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }
}
