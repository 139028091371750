.single-video {
  &.single-video--no-rounded-corners {
    .video-js {
      border-radius: 0;
    }

    .before-video-js {
      &__thumbnail {
        border-radius: 0;
      }
    }
  }
}
