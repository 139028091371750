@use '../../sass/abstract/breakpoints';

.Job-detail {
  .iconStyles__container {
    gap: 32px;
    display: flex;
    align-items: center;
  }

  .social-share-container {
    margin: 40px 0;
    float: left;
    width: 100%;

    @include breakpoints.screen(tablet-and-up) {
      margin: 40px 0;
    }

    @include breakpoints.screen(desktop-and-up) {
      margin: 40px 0;
    }

    @include breakpoints.screen(only-mobile) {
      margin: 40px 0;
    }

    .social-share {
      display: flex;
      align-items: center;
      gap: 40px;
      float: left;
      @include breakpoints.screen(only-mobile) {

        margin-bottom: 40px;
      }

      @include breakpoints.screen(tablet-and-up) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: 16px;
        margin-bottom: 80px;
      }

      @include breakpoints.screen(desktop-and-up) {
        flex-direction: row;
        justify-content: center;
        width: auto;
        gap: 40px;
        margin-bottom: 0;
      }

      @include breakpoints.screen(only-mobile) {
        flex-direction: column;
        justify-content: center;
        width: 100%;
        gap: 16px;
      }

      h5 {
        width: 100%;
        text-align: center;
        font-size: 24px;
      }
    }

    .profile-quick-links {
      display: flex;
      flex-direction: column;
      gap: 8px;
      float: right;

      @include breakpoints.screen(tablet-and-up) {
        width: 100%;
        justify-content: center;
        text-align: center;
      }

      @include breakpoints.screen(desktop-and-up) {
        width: auto;
        justify-content: start;
        text-align: start;
      }

      @include breakpoints.screen(only-mobile) {
        width: 100%;
        justify-content: center;
        text-align: center;
      }

      h5 {
        font-size: 24px;
      }

      a {
        font-size: 16px;
        width: 278px;
        font-weight: 400;
        line-height: 24px;
        white-space: pre-wrap;

        @include breakpoints.screen(tablet-and-up) {
          width: 100%;
          justify-content: center;
          text-align: center;
        }

        @include breakpoints.screen(desktop-and-up) {
          width: 278px;
          justify-content: start;
          text-align: start;
        }

        @include breakpoints.screen(only-mobile) {
          width: 100%;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  .insights-container {
    display: flex;
    width: 1128px;
    height:auto;
    padding: 40px 0 20px;
    flex-direction: column;
    align-items: center;
    gap: 80px;
    background: var(--primary-colours-grey-avanade-grey-light, #f5f7fa);

    @include breakpoints.screen(tablet-and-up) {
      display: flex;
      width: 100%;
      height: auto;
      padding: 40px 24px;
      gap: 40px;
    }

    @include breakpoints.screen(desktop-and-up) {
      display: flex;
      width: 1128px;
      height:auto;
      padding: 40px 0 20px;
      gap: 80px;
    }

    @include breakpoints.screen(only-mobile) {
      display: flex;
      // width: 327px;
      width: 100%;
      height: auto;
      padding: 40px 24px;
      align-items: flex-start;
      gap: 40px;
    }

    .insight-heading {
      width: 467px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      font-weight: 700;

      h3 {
        font-size: 40px;
        font-style: normal;
        line-height: normal;
      }

      @include breakpoints.screen(tablet-and-up) {
        width: 720px;
        display: flex;
        gap: 40px;
        align-self: center;
        margin-bottom: 40px;

        h3 {
          font-size: 40px;
          font-style: normal;
          line-height: 50px;
        }
      }

      @include breakpoints.screen(desktop-and-up) {
        width: 467px;
        display: flex;
        gap: 40px;
        align-self: auto;
        margin-bottom: 0;

        h3 {
          font-size: 40px;
          font-style: normal;
          line-height: normal;
        }
      }

      @include breakpoints.screen(only-mobile) {
        display: flex;
        width: 327px;
        gap: 40px;
        text-align: center;
        align-self: center;
        margin-bottom: 40px;

        h3 {
          font-size: 30px;
          font-style: normal;
          line-height: 40px;
        }
      }
    }
    
    #icms-cards{
      width: 100%;
    }

    .altru-basic-wrapper{
      width: 312px;

      &__child-1{
        margin-left: 24px;
      }

      &__child-2{
        margin-left: 24px;
      }
    }
  }

  .job-details-tab-content-Additionaldetails {


    display: none;

    @include breakpoints.screen(tablet-and-up) {

      margin-bottom: 8px;
      line-height: 24px;
      display: block;
      padding: 0 24px;
    }
    @include breakpoints.screen(desktop-and-up) {
      display: none;
   }


    @include breakpoints.screen(only-mobile) {
      margin-top: 64px;
      margin-bottom: 8px;
      line-height: 24px;
      display: block;
    }
  }

  .job-details-tab-content-dropdown {
    display: none;

    @include breakpoints.screen(tablet-and-up) {
      display: block;
      padding: 0 24px;
    }

    @include breakpoints.screen(desktop-and-up) {
      display: none;
    }

    @include breakpoints.screen(only-mobile) {
      display: block;
    }

    .dropdown .Dropdown-placeholder{
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 90%;
    }
  }

  .tabs {
    margin: 40px 0;
    float: left;
    @include breakpoints.screen(tablet-and-up) {
      margin-top: 20px;
    }
    @include breakpoints.screen(desktop-and-up) {
      margin-top: 40px;
    }


    &__container {
      margin: 0 0 40px;
      display: flex;
      flex-direction: row;

      @include breakpoints.screen(only-mobile) {
        margin: 0;
        display: none;
      }

      @include breakpoints.screen(tablet-and-up) {
        display: none;
      }
      @include breakpoints.screen(desktop-and-up) {
        display: flex;
      }
    }

    &__single-tab {
      padding: 0 20px 9px;
      // width: 196px;
      align-self: stretch;
    }

    .job-details-tab-content {
      margin-bottom: 24px;
      min-height: 264px;
      padding-top: 40px;

      @include breakpoints.screen(tablet-and-up) {
        min-height: 0;
        display: flex;
        padding: var(--Spacing-vg--sys-spacing-l, 40px) 124px;
        flex-direction: column;
        align-items: center;
        gap: var(--Spacing-vg--sys-spacing-l, 40px);
        color: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
        font-family: inherit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0;
      }

      @include breakpoints.screen(desktop-and-up) {
        margin-bottom: 24px;
        min-height: 264px;
        padding-top: 40px !important;
        padding: 0;
        display: block;
        align-items: center;
        gap: var(--Spacing-vg--sys-spacing-l, 40px);
        color: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
        font-family: inherit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      @include breakpoints.screen(only-mobile) {
        padding-top: 0;
        min-height: auto;
      }
    }

    .job-details-tab-content ul {
      list-style: disc;
    }

    .job-details-tab-content ol {
      list-style: decimal;
    }
  }
}

.areaofexpertise {
  color: var(--primary-colours-grey-avanade-gray-80, var(--color-gray-800, #333));
  font-size: 18px;
  font-weight: 400;
  line-height: normal;

  @include breakpoints.screen(tablet-and-up) {
    text-align: center;
  }


 
  @include breakpoints.screen(desktop-and-up) {
    text-align: start;
  }
  @include breakpoints.screen(only-mobile) {
    text-align: center;
  }
}

.container.Job-detail {
  @include breakpoints.screen(tablet-and-up) {
    padding: 0;
  }
  @include breakpoints.screen(desktop-and-up) {
    padding: 0;
  }
}

.job-details-container {
  display: flex;
  width: 1128px;
  padding: 40px 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @include breakpoints.screen(tablet-and-up) {
    padding: 40px 0;
    width: 100%;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  @include breakpoints.screen(desktop-and-up) {
    display: flex;
    width: 1128px;
    align-items: flex-start;
    gap: 24px;
  }

  @include breakpoints.screen(only-mobile) {
    width: 100%;
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.icms-hidden-container{
  display: none;
}

.tabs .JobDetailButton {
  @include breakpoints.screen(tablet-and-up) {
    align-items: center;
    display: flex !important;
    gap: 16px;
    flex-direction: column;
  }


  @include breakpoints.screen(desktop-and-up) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    display: inline-flex;
    height: var(--8-rule-5, 40px);
    gap:0;
  }

  @include breakpoints.screen(only-mobile) {
    flex-direction: column;
    align-items: center;
    display: flex !important;
    gap: 16px;
    height: auto;
  }
}

.tabs .JobDetailButton .JobDetailApplyNow {
  @include breakpoints.screen(tablet-and-up) {
    display: flex;
    height: 40px;
    padding: 12px var(--Spacing-vg--sys-spacing-m, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--Spacing-vg--sys-spacing-0, 0);
    flex-shrink: 0;
    border-radius: 28px;
    background: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
    min-width: 180px;
  }


  @include breakpoints.screen(desktop-and-up) {
    padding: 12px var(--spacing-vg-sys-spacing-m, 24px);
    flex-direction: column;
    gap: var(--spacing-vg-sys-spacing-0, 0);
    background: var(--primary-colours-grey-avanade-gray-80, #333);
    color: #fff !important;
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    height: 40px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 28px;
    text-decoration: none;
    min-width: 0;
  }


  @include breakpoints.screen(only-mobile) {
    display: flex;
    width: 327px;
    height: 40px;
    padding: 12px var(--Spacing-vg--sys-spacing-m, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--Spacing-vg--sys-spacing-0, 0);
    flex-shrink: 0;
    border-radius: 28px;
    background: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
  }
}

.tabs .JobDetailButton .JobDetailSaveJob {
  @include breakpoints.screen(tablet-and-up) {
    display: flex;
    height: 40px;
    padding: 9px var(--Spacing-vg--sys-spacing-s, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
    margin-left: 24px;
    margin: 0;
    min-width: 180px;
  }

  @include breakpoints.screen(desktop-and-up) {
    padding: 9px var(--spacing-vg-sys-spacing-s, 16px);
    justify-content: center;
    gap: 8px;
    border: 1px solid var(--primary-colours-grey-avanade-gray-80, #333);
    background: var(--primary-colours-white-avanade-white, #fff);
    margin-left: 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    height: 40px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 28px;
    text-decoration: none;
    min-width: 0;
  }

  @include breakpoints.screen(only-mobile) {
    display: flex;
    width: 327px;
    height: 40px;
    padding: 9px var(--Spacing-vg--sys-spacing-s, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
    margin-left: 24px;
    margin: 0;
    text-align: center;
  }
}

.job-description-video-container .JobDetailButton {
  @include breakpoints.screen(only-mobile) {
    flex-direction: column;
    align-items: center;
    display: flex !important;
    gap: 16px;
    height: auto;
  }
}

.job-description-video-container .JobDetailButton a {
  @include breakpoints.screen(tablet-and-up) {
    display: flex;
    height: 40px;
    padding: 12px var(--Spacing-vg--sys-spacing-m, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--Spacing-vg--sys-spacing-0, 0);
    flex-shrink: 0;
    border-radius: 28px;
    background: var(--Buttons-Primary---GLOBAL--button-color-background-primary-default, #4c4c4c);
  }

  @include breakpoints.screen(desktop-and-up) {
   display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    height: 40px;
    padding: 12px var(--spacing-vg-sys-spacing-m, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-vg-sys-spacing-0, 0);
    flex-shrink: 0;
    border-radius: 28px;
    background: var(--primary-colours-grey-avanade-gray-80, #333);
    color: #fff !important;
    text-decoration: none;
  }

  @include breakpoints.screen(only-mobile) {
    display: flex;
    width: 292px;
    height: 40px;
    padding: 12px var(--Spacing-vg--sys-spacing-m, 24px);
    flex-direction: column;
    align-items: center;
    gap: var(--Spacing-vg--sys-spacing-0, 0);
    flex-shrink: 0;
    border-radius: 28px;
    background: var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
  }
}

.job-description-video-container .JobDetailButton .JobDetailSaveJob {
  @include breakpoints.screen(tablet-and-up) {
    display: flex;
    height: 40px;
    padding: 9px var(--Spacing-vg--sys-spacing-s, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
    margin-left: 24px;
  }

  @include breakpoints.screen(desktop-and-up) {
    padding: 9px var(--spacing-vg-sys-spacing-s, 16px);
    justify-content: center;
    gap: 8px;
    border: 1px solid var(--primary-colours-grey-avanade-gray-80, #333);
    background: var(--primary-colours-white-avanade-white, #fff);
    margin-left: 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    display: inline-flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    height: 40px;
    align-items: center;
    flex-shrink: 0;
    border-radius: 28px;
    text-decoration: none;
  }
  @include breakpoints.screen(only-mobile) {
    display: flex;
    width: 292px;
    height: 40px;
    padding: 9px var(--Spacing-vg--sys-spacing-s, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 28px;
    border: 1px solid var(--Primary-Colours-Grey-Avanade-Gray-80, #333);
    margin-left: 24px;
    margin: 0;
    text-align: center;
  }
}

.job-title {
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  background: var(
    --gradients-horizontal-aurora,
    linear-gradient(90deg, #ce0569 12.66%, #ff5800 94.55%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @include breakpoints.screen(tablet-and-up) {
    text-align: center;
    font-size: 40px;
    line-height: 50px;
  }

  
  @include breakpoints.screen(desktop-and-up) {
    text-align: start;
    font-size: 60px;
    line-height: 70px;
  }
  @include breakpoints.screen(only-mobile) {
    text-align: center;
    font-size: 30px;
    line-height: 40px;
  }
}

.job-details-container .job-title::after {
  content: ' ';
  display: block;
  height: 6px;
}

.country,
.city,
.areaofexp {
  color: var(--primary-colours-grey-avanade-gray-80, var(--color-gray-800, #333));
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  @include breakpoints.screen(only-mobile) {
    text-align: center;
  }
}

.job-description-video-container {
  display: flex;
  max-width: 1128px;
  padding: 0;
  justify-content: center;
  align-items: flex-start;
  gap: 23px;
  margin: 40px 0;
  width: 100%;

  @include breakpoints.screen(tablet-and-up) {
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  @include breakpoints.screen(desktop-and-up) {
    gap: 23px;
    flex-direction: row;
    align-items: flex-start;
  }

  @include breakpoints.screen(only-mobile) {
    width: 100%;
    padding: 40px 16px;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin: 0;
  }
}

#job-descvideo {
  width: 649px;
  height: 364px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  border-radius: 30px;
  align-items: flex-start;

  @include breakpoints.screen(tablet-and-up) {
    height: 364px;
    display: flex;
    width: 720px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }

  @include breakpoints.screen(desktop-and-up) {
    width: 649px;
    flex-direction: column;
    align-items: flex-start;
  }
  @include breakpoints.screen(only-mobile) {
    width: 327px;
    height: 184px;
  }

  .altru-widget-container{
    width: 100%;
    height: 100%;

    @include breakpoints.screen(tablet-and-up) {
      max-width: 720px;
      height: 100%;
      width: 100%;
    } 
    @include breakpoints.screen(desktop-and-up) {
      max-width: none;
      height: 100%;
      width: 100%;
    }

    .altru-quote-container{
      @include breakpoints.screen(tablet-and-up) {
        flex-wrap: wrap;
        gap: 24px;
      }
      @include breakpoints.screen(desktop-and-up) {
        flex-wrap: nowrap;
        gap: normal;
      }
      @include breakpoints.screen(only-mobile) {
        gap: 24px;
      }

      .altru-quote-wrapper{
        &__child-0, &__child-1, &__child-2{
          @include breakpoints.screen(tablet-and-up) {
            width: 348px;
            margin: 0;
          }
          @include breakpoints.screen(only-mobile) {
            margin: 0;
          }
        }
      }
    }
  }

  .altru-no-frills-wrapper{
    max-width: 649px;
    min-height: 100%;
    height: 100%;

    @include breakpoints.screen(tablet-and-up) {
      max-width: 720px;
      min-height: 100%;
    }
    @include breakpoints.screen(desktop-and-up) {
      max-width: 649px;
      min-height:100%;
    }
    @include breakpoints.screen(only-mobile) {
      max-width: 327px;
      min-height: 100%;
      height: 100%;
    }
  }
}

.job-description-video-container:has(#job-descvideo div:empty) .job-description-container {
  max-width: 100%;
}

.country-city-areaofexp,
.job-description-container .jobDescription {
  @include breakpoints.screen(tablet-and-up) {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    min-height: 0;
  }
  @include breakpoints.screen(desktop-and-up) {
    text-align: start;
  }
}

.job-description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  max-width: 456px;

  @include breakpoints.screen(tablet-and-up) {
    place-items: center;
  }

  @include breakpoints.screen(desktop-and-up) {
    place-items:flex-start;
  }
  @include breakpoints.screen(only-mobile) {
    align-items: center;
  }
}

.job-description-heading {
  color: var(--primary-colours-grey-avanade-gray-80, var(--color-gray-800, #333));
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  width: 100%;

  @include breakpoints.screen(tablet-and-up) {
    font-size: 30px;
    line-height: 38px;
    width: inherit;
  }

  @include breakpoints.screen(desktop-and-up) {
    font-size: 24px;
    line-height: normal;
    width: 100%;
  }

  @include breakpoints.screen(only-mobile) {
    text-align: center;
  }
}

.jobDescription {
  color: var(--primary-colours-grey-avanade-gray-80, var(--color-gray-800, #333));
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  align-self: stretch;
  min-height: 168px;

  @include breakpoints.screen(only-mobile) {
    text-align: center;
  }
}

.insights-container:has(#icms-cards:empty) {
  display: none;
}
