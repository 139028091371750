@use './../../../sass/abstract/border-radius';
@use './../../../sass/abstract/border';
@use './../../../sass/abstract/variables';

.sxa-bordered {
  box-sizing: border-box;

  > .component-content {
    padding: 0;
    border: border.$border-1-solid-gray20;
    border-radius: border-radius.$border-radius-5;
  }
}

.column-splitter,
.row-splitter {
  .sxa-bordered {
    padding: variables.$gutter-24 !important;

    > .component,
    .scEmptyPlaceholder {
      border: border.$border-1-solid-gray20;
      border-radius: border-radius.$border-radius-5;
    }
  }
}
